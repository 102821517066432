import { AccountContext } from 'provider/accountContext'
import { useContext } from 'react'

export const useAccount = () => {
  const context = useContext(AccountContext)
  if (context.loading === true) {
    throw new Error('useAccount must be used within an AccountProvider')
  }
  if (context.account === undefined || context.shop === undefined) {
    throw new Error('useAccount must be used within an AccountProvider')
  }

  return context
}
