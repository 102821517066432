import { OP_DATE_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySetOr } from '../useRecipe'

export const useEventSegments = (): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_event_christmas'),
      querySet: generateQuerySetOr([
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2023-12-16', max_date: '2023-12-31' },
          filters: [],
        },
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2022-12-16', max_date: '2022-12-31' },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_event_bfcm'),
      querySet: generateQuerySetOr([
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2023-11-15', max_date: '2023-11-30' },
          filters: [],
        },
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2022-11-15', max_date: '2022-11-30' },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_event_valentines'),
      querySet: generateQuerySetOr([
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2024-01-20', max_date: '2024-02-14' },
          filters: [],
        },
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2023-01-20', max_date: '2023-02-14' },
          filters: [],
        },
      ]),
    },
  ]
}
