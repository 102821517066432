// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/auth/auth.proto (package auth, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {BoolValue, Message, proto3} from "@bufbuild/protobuf";

/**
 * GetShopByToken
 *
 * @generated from message auth.GetShopByTokenRequest
 */
export class GetShopByTokenRequest extends Message<GetShopByTokenRequest> {
  /**
   * @generated from field: string sessionToken = 1;
   */
  sessionToken = "";

  constructor(data?: PartialMessage<GetShopByTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.GetShopByTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sessionToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetShopByTokenRequest {
    return new GetShopByTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetShopByTokenRequest {
    return new GetShopByTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetShopByTokenRequest {
    return new GetShopByTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetShopByTokenRequest | PlainMessage<GetShopByTokenRequest> | undefined, b: GetShopByTokenRequest | PlainMessage<GetShopByTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetShopByTokenRequest, a, b);
  }
}

/**
 * @generated from message auth.GetShopByTokenResponse
 */
export class GetShopByTokenResponse extends Message<GetShopByTokenResponse> {
  /**
   * @generated from field: string shopName = 1;
   */
  shopName = "";

  /**
   * @generated from field: bool tokenVerified = 2;
   */
  tokenVerified = false;

  constructor(data?: PartialMessage<GetShopByTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.GetShopByTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shopName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tokenVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetShopByTokenResponse {
    return new GetShopByTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetShopByTokenResponse {
    return new GetShopByTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetShopByTokenResponse {
    return new GetShopByTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetShopByTokenResponse | PlainMessage<GetShopByTokenResponse> | undefined, b: GetShopByTokenResponse | PlainMessage<GetShopByTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetShopByTokenResponse, a, b);
  }
}

/**
 * Signup
 *
 * @generated from message auth.SignupRequest
 */
export class SignupRequest extends Message<SignupRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: string sessionToken = 3;
   */
  sessionToken = "";

  /**
   * @generated from field: string language = 4;
   */
  language = "";

  constructor(data?: PartialMessage<SignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.SignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sessionToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupRequest {
    return new SignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignupRequest | PlainMessage<SignupRequest> | undefined, b: SignupRequest | PlainMessage<SignupRequest> | undefined): boolean {
    return proto3.util.equals(SignupRequest, a, b);
  }
}

/**
 * @generated from message auth.SignupResponse
 */
export class SignupResponse extends Message<SignupResponse> {
  constructor(data?: PartialMessage<SignupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.SignupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupResponse {
    return new SignupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupResponse {
    return new SignupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupResponse {
    return new SignupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignupResponse | PlainMessage<SignupResponse> | undefined, b: SignupResponse | PlainMessage<SignupResponse> | undefined): boolean {
    return proto3.util.equals(SignupResponse, a, b);
  }
}

/**
 * MemberSignup
 *
 * @generated from message auth.MemberSignupRequest
 */
export class MemberSignupRequest extends Message<MemberSignupRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: string shopName = 3;
   */
  shopName = "";

  /**
   * @generated from field: string token = 4;
   */
  token = "";

  /**
   * @generated from field: string language = 5;
   */
  language = "";

  constructor(data?: PartialMessage<MemberSignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.MemberSignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "shopName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberSignupRequest {
    return new MemberSignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberSignupRequest {
    return new MemberSignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberSignupRequest {
    return new MemberSignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MemberSignupRequest | PlainMessage<MemberSignupRequest> | undefined, b: MemberSignupRequest | PlainMessage<MemberSignupRequest> | undefined): boolean {
    return proto3.util.equals(MemberSignupRequest, a, b);
  }
}

/**
 * @generated from message auth.MemberSignupResponse
 */
export class MemberSignupResponse extends Message<MemberSignupResponse> {
  constructor(data?: PartialMessage<MemberSignupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.MemberSignupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberSignupResponse {
    return new MemberSignupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberSignupResponse {
    return new MemberSignupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberSignupResponse {
    return new MemberSignupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MemberSignupResponse | PlainMessage<MemberSignupResponse> | undefined, b: MemberSignupResponse | PlainMessage<MemberSignupResponse> | undefined): boolean {
    return proto3.util.equals(MemberSignupResponse, a, b);
  }
}

/**
 * VerifyAppInstallation
 *
 * @generated from message auth.VerifyAppInstallationRequest
 */
export class VerifyAppInstallationRequest extends Message<VerifyAppInstallationRequest> {
  constructor(data?: PartialMessage<VerifyAppInstallationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.VerifyAppInstallationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyAppInstallationRequest {
    return new VerifyAppInstallationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyAppInstallationRequest {
    return new VerifyAppInstallationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyAppInstallationRequest {
    return new VerifyAppInstallationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyAppInstallationRequest | PlainMessage<VerifyAppInstallationRequest> | undefined, b: VerifyAppInstallationRequest | PlainMessage<VerifyAppInstallationRequest> | undefined): boolean {
    return proto3.util.equals(VerifyAppInstallationRequest, a, b);
  }
}

/**
 * @generated from message auth.VerifyAppInstallationResponse
 */
export class VerifyAppInstallationResponse extends Message<VerifyAppInstallationResponse> {
  /**
   * @generated from field: google.protobuf.BoolValue installed = 1;
   */
  installed?: boolean;

  constructor(data?: PartialMessage<VerifyAppInstallationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "auth.VerifyAppInstallationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "installed", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyAppInstallationResponse {
    return new VerifyAppInstallationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyAppInstallationResponse {
    return new VerifyAppInstallationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyAppInstallationResponse {
    return new VerifyAppInstallationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyAppInstallationResponse | PlainMessage<VerifyAppInstallationResponse> | undefined, b: VerifyAppInstallationResponse | PlainMessage<VerifyAppInstallationResponse> | undefined): boolean {
    return proto3.util.equals(VerifyAppInstallationResponse, a, b);
  }
}

