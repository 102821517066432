import { getIdToken } from '@firebase/auth'
import { CustomerSegmentState, CustomerState } from 'features/customerSegments/types/types'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useCustomers = (customerSegment: CustomerSegmentState | undefined) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const [customers, setCustomers] = useState<CustomerState[]>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!customerSegment) return
    if (customerSegment.metrics.count === 0) {
      setCustomers([])
      return
    }

    const handleFetch = async () => {
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.getCustomers(
          { customerSegmentId: customerSegment.id },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const customers = resp.customers.map((c) => {
          return {
            id: c.id,
            email: c.email,
            name: t('common.customer.fullName', { firstName: c.firstName, lastName: c.lastName }),
            recency: c.recency > 0 ? Number(c.recency) : 0,
            frequency: Number(c.frequency),
            monetary: Number(c.monetary),
          }
        })
        setCustomers(customers)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegment?.id])

  return { customers, loading }
}
