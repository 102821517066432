import { FC, createContext, ReactNode } from 'react'
import { UseDimensionOptionsReturn, initialOptionsByDimension, useDimensionOptions } from './useDimensionOptions'

export const DimensionOptionsContext = createContext<UseDimensionOptionsReturn>({
  loading: true,
  data: initialOptionsByDimension,
})

type ProviderProps = {
  children: ReactNode
}

export const DimensionOptionsProvider: FC<ProviderProps> = ({ children }) => {
  const result = useDimensionOptions()

  return <DimensionOptionsContext.Provider value={result}>{children}</DimensionOptionsContext.Provider>
}
