export const extractIconFromTextWithIcon = (text: string): string => {
  let icon: string = ''
  const foundIcon = text.match(/(?:[\p{Emoji}]|\u{FE0F}|\u{200D})+/gu)?.[0]
  if (foundIcon && !foundIcon.match(/^\d+$/)) {
    icon = foundIcon
  }
  return icon
}

export const extractTextFromTextWithIcon = (text: string): string => {
  const icon = extractIconFromTextWithIcon(text)
  return text.replace(icon, '').trimStart()
}
