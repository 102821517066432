import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Checkbox, Collapse, Dialog, FormControl, FormControlLabel, InputAdornment, Switch, TextField, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { getImagePath } from 'utils/imageUtil'
import { z } from 'zod'

type Props = {
  open: boolean
  customerSegmentId: string
  customerSegmentName: string
  isActive: boolean
  tagName: string
  handleClose: () => void
}

const TAG_NAME_PREFIX = 'ecp-'
const addPrefix = (tagName: string) => (tagName.startsWith(TAG_NAME_PREFIX) ? tagName : `${TAG_NAME_PREFIX}${tagName}`)
const removePrefix = (tagName: string) => (tagName.startsWith(TAG_NAME_PREFIX) ? tagName.substring(TAG_NAME_PREFIX.length) : tagName)

export const UpdateTagOperationSettingDialog: FC<Props> = ({ open, customerSegmentId, customerSegmentName, isActive, tagName, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { shop } = useAccount()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const addTagInput = z.object({
    isActive: z.boolean(),
    tagName: z
      .string()
      .min(1, { message: t('features.customerSegments.detail.updateTagOperationSettingDialog.messageValidationTagIsRequired') })
      .max(32, { message: t('features.customerSegments.detail.updateTagOperationSettingDialog.messageValidationMax') }),
    enableShopifySegmentCreation: z.boolean(),
  })

  type InputSchema = z.infer<typeof addTagInput>

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<InputSchema>({
    resolver: zodResolver(addTagInput),
    defaultValues: {
      isActive: isActive,
      tagName: tagName ? removePrefix(tagName) : extractTextFromTextWithIcon(customerSegmentName),
      enableShopifySegmentCreation: false,
    },
    mode: 'onChange',
  })

  const isEnabled = watch('isActive')
  const watchedValues = watch()

  const hasFormChanged = useMemo(() => {
    return watchedValues.isActive !== isActive && isValid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues, isValid])

  const updateTagOperationSetting = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      const tagName = input.isActive ? addPrefix(input.tagName) : ''
      const enableShopifySegmentCreation = input.isActive ? input.enableShopifySegmentCreation : false
      await customerSegmentService.updateTagOperationSetting(
        {
          customerSegmentId: customerSegmentId,
          isActive: input.isActive,
          tagName: tagName,
          enableShopifySegmentCreation: enableShopifySegmentCreation,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.detail.updateTagOperationSettingDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.detail.updateTagOperationSettingDialog.messageFailed'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box padding='20px'>
        <Box display='flex' alignItems='center' marginBottom='16px'>
          <img src={getImagePath('shopify_logo.svg')} alt='Shopify Logo' width='20px' />
          <Typography marginLeft='6px'>{t('features.customerSegments.detail.updateTagOperationSettingDialog.title')}</Typography>
        </Box>

        <Box marginBottom='20px' display='flex' flexDirection='column'>
          <Controller
            control={control}
            name='isActive'
            render={({ field }) => (
              <Box display='flex' justifyContent='space-between' alignItems='center' width='340px'>
                <Typography fontSize='14px' sx={{ paddingLeft: '4px' }}>
                  {t('features.customerSegments.detail.updateTagOperationSettingDialog.fieldStatus')}
                </Typography>
                <Switch {...field} checked={field.value} />
              </Box>
            )}
          />

          <Collapse in={isEnabled}>
            <Box display='flex' flexDirection='column' marginTop='12px'>
              <Controller
                control={control}
                name='tagName'
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl sx={{ width: '340px' }}>
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={error?.message}
                        disabled={isActive} // Editable only when changing enabled to ON
                        size='small'
                        label={t('features.customerSegments.detail.updateTagOperationSettingDialog.fieldTagName')}
                        InputProps={{
                          startAdornment: <InputAdornment position='start'>ecp-</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  )
                }}
              />

              {!isActive && (
                <Controller
                  control={control}
                  name='enableShopifySegmentCreation'
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} size='small' />}
                      label={t('features.customerSegments.detail.updateTagOperationSettingDialog.fieldEnableShopifySegmentCreation')}
                      sx={{ marginTop: '4px', '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                    />
                  )}
                />
              )}
            </Box>
          </Collapse>
        </Box>

        <Box display='flex' justifyContent='flex-end'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='text' sx={{ marginRight: '4px', color: 'text.secondary' }}>
            {t('features.customerSegments.detail.updateTagOperationSettingDialog.cancel')}
          </Button>
          <LoadingButton
            type='submit'
            disabled={!hasFormChanged || shop.isDemo || isSubmitting} // Note: Demo accounts cannot assign tags
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(updateTagOperationSetting)}
            variant='contained'
          >
            {t('features.customerSegments.detail.updateTagOperationSettingDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
