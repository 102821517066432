import { Code, ConnectError } from '@bufbuild/connect-web'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Container, Link, MenuItem, TextField, Typography } from '@mui/material'
import { auth } from 'config/firebaseClient'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { AuthService } from 'gen/proto/auth/auth_connectweb'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'
import { z } from 'zod'

export const MemberSignup = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const authService = useGrpcClient(AuthService)

  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])
  const store = useMemo(() => queryParams.get('store'), [queryParams])
  const email = useMemo(() => decodeURIComponent(queryParams.get('email') || ''), [queryParams])
  const token = useMemo(() => queryParams.get('token'), [queryParams])

  useEffect(() => {
    if (!store || !email || !token) {
      alert(t('features.memberSignup.messageUrlIsInvalid'))
      window.location.replace(PUBLIC_ROUTE.LOGIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, email, token])

  const signupInputSchema = z.object({
    password: z
      .string()
      .min(6, { message: t('features.memberSignup.messageValidationPasswordMin') })
      .max(30, { message: t('features.memberSignup.messageValidationPasswordMax') }),
    language: z.string(),
  })
  type InputSchema = z.infer<typeof signupInputSchema>

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(signupInputSchema),
    defaultValues: {
      password: '',
      language: i18n.language,
    },
    mode: 'onChange',
  })

  const enableSubmit = useMemo(() => {
    return store && email && token && isValid
  }, [store, email, token, isValid])

  const handleSignup: SubmitHandler<InputSchema> = async (input) => {
    try {
      await authService.memberSignup({
        email: email!,
        password: input.password,
        shopName: store!,
        token: token!,
        language: input.language,
      })
      await signInWithEmailAndPassword(auth, email!, input.password)
    } catch (err) {
      if (err instanceof ConnectError) {
        switch (err.code) {
          case Code.AlreadyExists:
            enqueueSnackbar(t('features.memberSignup.messageErrorAlreadyExists'), { severity: 'error' })
            return
          case Code.InvalidArgument:
            enqueueSnackbar(t('features.memberSignup.messageErrorInvalidArgument'), { severity: 'error' })
            return
          case Code.NotFound:
            enqueueSnackbar(t('features.memberSignup.messageErrorNotFound'), { severity: 'error' })
            return
          default:
            enqueueSnackbar(t('features.memberSignup.messageErrorUnknown'), { severity: 'error' })
            notifySentry(err, { inputEmail: email, inputPassword: input.password })
            return
        }
      }
      enqueueSnackbar(t('features.memberSignup.messageErrorUnknown'), { severity: 'error' })
      notifySentry(err, { inputEmail: email, inputPassword: input.password })
    }
  }

  return (
    <Container maxWidth='xl' fixed sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ width: { xs: '100%', sm: '100%', md: '45%' } }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 600, marginBottom: '8px' }}>{t('features.memberSignup.title')}</Typography>
        <Typography sx={{ marginBottom: '32px' }}>{t('features.memberSignup.description')}</Typography>
        <Box sx={{ marginBottom: '32px' }}>
          <TextField
            autoComplete='email'
            label={t('features.memberSignup.store')}
            size='small'
            fullWidth
            disabled
            value={store || ''}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
          />
          <TextField
            autoComplete='email'
            label={t('features.memberSignup.email')}
            size='small'
            fullWidth
            disabled
            value={email || ''}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
          />
          <TextField
            type='password'
            label={t('features.memberSignup.password')}
            size='small'
            fullWidth
            autoComplete='current-password'
            error={!!formErrors.password?.message}
            helperText={formErrors.password?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            {...register('password')}
          />
          <TextField
            {...register('language')}
            select
            label={t('features.memberSignup.language')}
            size='small'
            fullWidth
            defaultValue={i18n.language || 'en'}
            error={!!formErrors.language?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            onChange={(e) => {
              const lang = e.target.value
              i18n.changeLanguage(lang)
              setValue('language', lang)
            }}
          >
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='ja'>日本語</MenuItem>
          </TextField>
          <LoadingButton
            type='submit'
            size='medium'
            fullWidth
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(handleSignup)}
            disabled={!enableSubmit || isSubmitting}
            variant='contained'
          >
            {t('features.memberSignup.submit')}
          </LoadingButton>
        </Box>
        <Typography sx={{ fontSize: '14px' }}>
          {t('features.memberSignup.login')}
          <Link
            onClick={() => {
              navigate(PUBLIC_ROUTE.LOGIN)
            }}
            sx={{ fontWeight: 600, textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('features.memberSignup.loginLink')}
          </Link>
        </Typography>
      </Box>

      <Box sx={{ width: '45%', display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <img src={getImagePath('signup/member_signup.svg')} width='75%' alt='signup' />
      </Box>
    </Container>
  )
}
