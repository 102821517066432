// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/segment_transition/segment_transition.proto (package segment_transition, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, protoInt64} from "@bufbuild/protobuf";

/**
 * AggregateCustomerCounts
 *
 * @generated from message segment_transition.AggregateCustomerCountsRequest
 */
export class AggregateCustomerCountsRequest extends Message<AggregateCustomerCountsRequest> {
  /**
   * @generated from field: repeated segment_transition.AggregateCustomerCountsRequest.Transition transitions = 1;
   */
  transitions: AggregateCustomerCountsRequest_Transition[] = [];

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 2;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 3;
   */
  endDate = "";

  constructor(data?: PartialMessage<AggregateCustomerCountsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerCountsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "transitions", kind: "message", T: AggregateCustomerCountsRequest_Transition, repeated: true },
    { no: 2, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerCountsRequest {
    return new AggregateCustomerCountsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerCountsRequest {
    return new AggregateCustomerCountsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerCountsRequest {
    return new AggregateCustomerCountsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerCountsRequest | PlainMessage<AggregateCustomerCountsRequest> | undefined, b: AggregateCustomerCountsRequest | PlainMessage<AggregateCustomerCountsRequest> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerCountsRequest, a, b);
  }
}

/**
 * @generated from message segment_transition.AggregateCustomerCountsRequest.Transition
 */
export class AggregateCustomerCountsRequest_Transition extends Message<AggregateCustomerCountsRequest_Transition> {
  /**
   * @generated from field: string sourceCustomerSegmentId = 1;
   */
  sourceCustomerSegmentId = "";

  /**
   * @generated from field: string targetCustomerSegmentId = 2;
   */
  targetCustomerSegmentId = "";

  constructor(data?: PartialMessage<AggregateCustomerCountsRequest_Transition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerCountsRequest.Transition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sourceCustomerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "targetCustomerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerCountsRequest_Transition {
    return new AggregateCustomerCountsRequest_Transition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerCountsRequest_Transition {
    return new AggregateCustomerCountsRequest_Transition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerCountsRequest_Transition {
    return new AggregateCustomerCountsRequest_Transition().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerCountsRequest_Transition | PlainMessage<AggregateCustomerCountsRequest_Transition> | undefined, b: AggregateCustomerCountsRequest_Transition | PlainMessage<AggregateCustomerCountsRequest_Transition> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerCountsRequest_Transition, a, b);
  }
}

/**
 * @generated from message segment_transition.AggregateCustomerCountsResponse
 */
export class AggregateCustomerCountsResponse extends Message<AggregateCustomerCountsResponse> {
  /**
   * @generated from field: repeated segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount results = 1;
   */
  results: AggregateCustomerCountsResponse_TransitionCustomerCount[] = [];

  constructor(data?: PartialMessage<AggregateCustomerCountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerCountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: AggregateCustomerCountsResponse_TransitionCustomerCount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerCountsResponse {
    return new AggregateCustomerCountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerCountsResponse {
    return new AggregateCustomerCountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerCountsResponse {
    return new AggregateCustomerCountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerCountsResponse | PlainMessage<AggregateCustomerCountsResponse> | undefined, b: AggregateCustomerCountsResponse | PlainMessage<AggregateCustomerCountsResponse> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerCountsResponse, a, b);
  }
}

/**
 * @generated from message segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount
 */
export class AggregateCustomerCountsResponse_TransitionCustomerCount extends Message<AggregateCustomerCountsResponse_TransitionCustomerCount> {
  /**
   * @generated from field: string sourceCustomerSegmentId = 1;
   */
  sourceCustomerSegmentId = "";

  /**
   * @generated from field: string targetCustomerSegmentId = 2;
   */
  targetCustomerSegmentId = "";

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount = protoInt64.zero;

  /**
   * @generated from field: bool isDataIncomplete = 4;
   */
  isDataIncomplete = false;

  constructor(data?: PartialMessage<AggregateCustomerCountsResponse_TransitionCustomerCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerCountsResponse.TransitionCustomerCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sourceCustomerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "targetCustomerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "isDataIncomplete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerCountsResponse_TransitionCustomerCount {
    return new AggregateCustomerCountsResponse_TransitionCustomerCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerCountsResponse_TransitionCustomerCount {
    return new AggregateCustomerCountsResponse_TransitionCustomerCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerCountsResponse_TransitionCustomerCount {
    return new AggregateCustomerCountsResponse_TransitionCustomerCount().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerCountsResponse_TransitionCustomerCount | PlainMessage<AggregateCustomerCountsResponse_TransitionCustomerCount> | undefined, b: AggregateCustomerCountsResponse_TransitionCustomerCount | PlainMessage<AggregateCustomerCountsResponse_TransitionCustomerCount> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerCountsResponse_TransitionCustomerCount, a, b);
  }
}

/**
 * AggregateCustomerMetrics
 *
 * @generated from message segment_transition.AggregateCustomerMetricsRequest
 */
export class AggregateCustomerMetricsRequest extends Message<AggregateCustomerMetricsRequest> {
  /**
   * @generated from field: string sourceCustomerSegmentId = 1;
   */
  sourceCustomerSegmentId = "";

  /**
   * @generated from field: string targetCustomerSegmentId = 2;
   */
  targetCustomerSegmentId = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate = "";

  constructor(data?: PartialMessage<AggregateCustomerMetricsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerMetricsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sourceCustomerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "targetCustomerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerMetricsRequest {
    return new AggregateCustomerMetricsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerMetricsRequest {
    return new AggregateCustomerMetricsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerMetricsRequest {
    return new AggregateCustomerMetricsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerMetricsRequest | PlainMessage<AggregateCustomerMetricsRequest> | undefined, b: AggregateCustomerMetricsRequest | PlainMessage<AggregateCustomerMetricsRequest> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerMetricsRequest, a, b);
  }
}

/**
 * @generated from message segment_transition.AggregateCustomerMetricsResponse
 */
export class AggregateCustomerMetricsResponse extends Message<AggregateCustomerMetricsResponse> {
  /**
   * @generated from field: int64 customerCount = 1;
   */
  customerCount = protoInt64.zero;

  /**
   * @generated from field: double repeaterRate = 2;
   */
  repeaterRate = 0;

  /**
   * @generated from field: double ltv = 3;
   */
  ltv = 0;

  /**
   * @generated from field: double avgOrderValue = 4;
   */
  avgOrderValue = 0;

  /**
   * @generated from field: double avgOrderCount = 5;
   */
  avgOrderCount = 0;

  /**
   * @generated from field: double avgDaysBetweenOrders = 6;
   */
  avgDaysBetweenOrders = 0;

  /**
   * @generated from field: repeated segment_transition.AggregateCustomerMetricsResponse.Customer customers = 7;
   */
  customers: AggregateCustomerMetricsResponse_Customer[] = [];

  constructor(data?: PartialMessage<AggregateCustomerMetricsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerMetricsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "repeaterRate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "ltv", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "avgOrderValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "avgOrderCount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "avgDaysBetweenOrders", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "customers", kind: "message", T: AggregateCustomerMetricsResponse_Customer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerMetricsResponse {
    return new AggregateCustomerMetricsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerMetricsResponse {
    return new AggregateCustomerMetricsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerMetricsResponse {
    return new AggregateCustomerMetricsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerMetricsResponse | PlainMessage<AggregateCustomerMetricsResponse> | undefined, b: AggregateCustomerMetricsResponse | PlainMessage<AggregateCustomerMetricsResponse> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerMetricsResponse, a, b);
  }
}

/**
 * @generated from message segment_transition.AggregateCustomerMetricsResponse.Customer
 */
export class AggregateCustomerMetricsResponse_Customer extends Message<AggregateCustomerMetricsResponse_Customer> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string firstName = 3;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 4;
   */
  lastName = "";

  constructor(data?: PartialMessage<AggregateCustomerMetricsResponse_Customer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_transition.AggregateCustomerMetricsResponse.Customer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AggregateCustomerMetricsResponse_Customer {
    return new AggregateCustomerMetricsResponse_Customer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AggregateCustomerMetricsResponse_Customer {
    return new AggregateCustomerMetricsResponse_Customer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AggregateCustomerMetricsResponse_Customer {
    return new AggregateCustomerMetricsResponse_Customer().fromJsonString(jsonString, options);
  }

  static equals(a: AggregateCustomerMetricsResponse_Customer | PlainMessage<AggregateCustomerMetricsResponse_Customer> | undefined, b: AggregateCustomerMetricsResponse_Customer | PlainMessage<AggregateCustomerMetricsResponse_Customer> | undefined): boolean {
    return proto3.util.equals(AggregateCustomerMetricsResponse_Customer, a, b);
  }
}

