import { OP_CONTAIN_ANY, OP_INT_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useProductTypeSegments = (productTypes: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_productType', { value: productTypes[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [productTypes[0]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productType', { value: productTypes[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [productTypes[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productType', { value: productTypes[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [productTypes[2]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productType_twicePlus', { value: productTypes[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [productTypes[0]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productType_twicePlus', { value: productTypes[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [productTypes[1]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productType_twicePlus', { value: productTypes[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_type',
          operator: OP_CONTAIN_ANY,
          value: [productTypes[2]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
  ]
}
