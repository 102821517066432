// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/account/account.proto (package account, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * UpdateEmail
 *
 * @generated from message account.UpdateEmailRequest
 */
export class UpdateEmailRequest extends Message<UpdateEmailRequest> {
  /**
   * @generated from field: string currentEmail = 1;
   */
  currentEmail = "";

  /**
   * @generated from field: string newEmail = 2;
   */
  newEmail = "";

  constructor(data?: PartialMessage<UpdateEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "account.UpdateEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "currentEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "newEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEmailRequest {
    return new UpdateEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEmailRequest {
    return new UpdateEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEmailRequest {
    return new UpdateEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEmailRequest | PlainMessage<UpdateEmailRequest> | undefined, b: UpdateEmailRequest | PlainMessage<UpdateEmailRequest> | undefined): boolean {
    return proto3.util.equals(UpdateEmailRequest, a, b);
  }
}

/**
 * @generated from message account.UpdateEmailResponse
 */
export class UpdateEmailResponse extends Message<UpdateEmailResponse> {
  constructor(data?: PartialMessage<UpdateEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "account.UpdateEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEmailResponse {
    return new UpdateEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEmailResponse {
    return new UpdateEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEmailResponse {
    return new UpdateEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEmailResponse | PlainMessage<UpdateEmailResponse> | undefined, b: UpdateEmailResponse | PlainMessage<UpdateEmailResponse> | undefined): boolean {
    return proto3.util.equals(UpdateEmailResponse, a, b);
  }
}

/**
 * UpdateLanguage
 *
 * @generated from message account.UpdateLanguageRequest
 */
export class UpdateLanguageRequest extends Message<UpdateLanguageRequest> {
  /**
   * @generated from field: string language = 1;
   */
  language = "";

  constructor(data?: PartialMessage<UpdateLanguageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "account.UpdateLanguageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLanguageRequest {
    return new UpdateLanguageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLanguageRequest {
    return new UpdateLanguageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLanguageRequest {
    return new UpdateLanguageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLanguageRequest | PlainMessage<UpdateLanguageRequest> | undefined, b: UpdateLanguageRequest | PlainMessage<UpdateLanguageRequest> | undefined): boolean {
    return proto3.util.equals(UpdateLanguageRequest, a, b);
  }
}

/**
 * @generated from message account.UpdateLanguageResponse
 */
export class UpdateLanguageResponse extends Message<UpdateLanguageResponse> {
  constructor(data?: PartialMessage<UpdateLanguageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "account.UpdateLanguageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLanguageResponse {
    return new UpdateLanguageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLanguageResponse {
    return new UpdateLanguageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLanguageResponse {
    return new UpdateLanguageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLanguageResponse | PlainMessage<UpdateLanguageResponse> | undefined, b: UpdateLanguageResponse | PlainMessage<UpdateLanguageResponse> | undefined): boolean {
    return proto3.util.equals(UpdateLanguageResponse, a, b);
  }
}

/**
 * UpdateDisplayMode
 *
 * @generated from message account.UpdateDisplayModeRequest
 */
export class UpdateDisplayModeRequest extends Message<UpdateDisplayModeRequest> {
  /**
   * @generated from field: bool displayDarkMode = 1;
   */
  displayDarkMode = false;

  constructor(data?: PartialMessage<UpdateDisplayModeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "account.UpdateDisplayModeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "displayDarkMode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDisplayModeRequest {
    return new UpdateDisplayModeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDisplayModeRequest {
    return new UpdateDisplayModeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDisplayModeRequest {
    return new UpdateDisplayModeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDisplayModeRequest | PlainMessage<UpdateDisplayModeRequest> | undefined, b: UpdateDisplayModeRequest | PlainMessage<UpdateDisplayModeRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDisplayModeRequest, a, b);
  }
}

/**
 * @generated from message account.UpdateDisplayModeResponse
 */
export class UpdateDisplayModeResponse extends Message<UpdateDisplayModeResponse> {
  constructor(data?: PartialMessage<UpdateDisplayModeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "account.UpdateDisplayModeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDisplayModeResponse {
    return new UpdateDisplayModeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDisplayModeResponse {
    return new UpdateDisplayModeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDisplayModeResponse {
    return new UpdateDisplayModeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDisplayModeResponse | PlainMessage<UpdateDisplayModeResponse> | undefined, b: UpdateDisplayModeResponse | PlainMessage<UpdateDisplayModeResponse> | undefined): boolean {
    return proto3.util.equals(UpdateDisplayModeResponse, a, b);
  }
}

