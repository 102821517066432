import { Box, Card, CardActionArea, CardContent, Chip, Grid, Typography, useTheme } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { useRecipes } from './hooks/useRecipes'
import { TagKey, tagKeys } from './types/type'

export const Recipes: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()

  const [selectedTag, setSelectedTag] = useState<TagKey>(tagKeys[0])

  const { data: recipes } = useRecipes()

  const sortedRecipes = useMemo(() => {
    if (selectedTag === 'all') return recipes
    return recipes.filter((recipe) => recipe.tags.includes(selectedTag))
  }, [recipes, selectedTag])

  return (
    <>
      <PageHeader title={t('features.recipes.title')} guideType='Recipes' marginBottom='24px' />

      <Box sx={{ marginBottom: '12px' }}>
        {tagKeys.map((tag) => (
          <Chip
            key={tag}
            size='small'
            variant={selectedTag === tag ? 'filled' : 'outlined'}
            color={selectedTag === tag ? 'primary' : 'default'}
            label={t(`features.recipes.tags.${tag}`)}
            onClick={() => setSelectedTag(tag)}
            sx={{ marginRight: '8px', marginBottom: '8px', padding: '14px 2px', borderRadius: '6px' }}
          />
        ))}
      </Box>

      <Grid container spacing={3} alignItems='stretch' sx={{ padding: '8px 4px 24px' }}>
        {sortedRecipes.map((recipe) => (
          <Grid key={recipe.key} item xs={12} md={6} lg={4}>
            <Card
              variant='outlined'
              sx={{
                borderRadius: '12px',
                height: '100%',
                position: 'relative',
                '&:hover': {
                  boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
                  '& .showOnHover': {
                    visibility: 'visible',
                  },
                },
              }}
            >
              <CardActionArea
                onClick={() => navigate(generatePath(AUTHORIZED_ROUTE.RECIPE_DETAIL, { id: recipe.key }))}
                sx={{ height: '100%', padding: '8px' }}
              >
                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <Typography fontSize='24px' marginBottom='8px'>
                    {extractIconFromTextWithIcon(recipe.title)}
                  </Typography>
                  <Typography
                    fontSize='14px'
                    sx={{ marginBottom: '12px', cursor: 'pointer', '&:hover': { color: (theme) => theme.palette.text.secondary } }}
                  >
                    {extractTextFromTextWithIcon(recipe.title)}
                  </Typography>
                  <Typography
                    fontSize='13px'
                    color='text.secondary'
                    marginBottom='14px'
                    sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden' }}
                  >
                    {recipe.description}...
                  </Typography>
                  <Box display='flex' flexWrap='wrap'>
                    {recipe.tags.map((tag) => (
                      <Typography
                        key={tag}
                        fontSize='11px'
                        marginRight='8px'
                        sx={{
                          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100],
                          padding: '2px 6px',
                          borderRadius: '2px',
                        }}
                      >
                        {t(`features.recipes.tags.${tag}`)}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
