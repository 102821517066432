import { zodResolver } from '@hookform/resolvers/zod'
import EventNoteIcon from '@mui/icons-material/EventNote'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import { Autocomplete, Box, Button, FormControl, Grid, Menu, Skeleton, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'
import i18n from 'i18n'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDate } from 'utils/timeUtil'
import { z } from 'zod'
import { InsightDatePeriod, InsightFilterState } from '../../types/insight'

const MIN_DATE = dayjs().subtract(5, 'year').format('YYYY-MM-DD')
const MAX_DATE = dayjs().format('YYYY-MM-DD')

type Props = {
  insightFilter: InsightFilterState
  setInsightFilter: (value: InsightFilterState) => void
  insightDimension: InsightDimension
}

export const InsightFilter: FC<Props> = ({ insightFilter, setInsightFilter, insightDimension }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const [datePeriod, setDatePeriod] = useState<InsightDatePeriod>('all')
  const [autocompleteValue, setAutocompleteValue] = useState<string | null>(null)
  const [segmentFilterMenuOpen, setSegmentFilterMenuOpen] = useState(false)
  const [dateRangeFilterMenuOpen, setDateRangeFilterMenuOpen] = useState(false)
  const segmentFilterMenuAnchorRef = useRef<HTMLButtonElement | null>(null)
  const dateRangeFilterMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const validateDate = (value: string) =>
    dayjs(value).isAfter(dayjs(MIN_DATE).subtract(1, 'day')) && dayjs(value).isBefore(dayjs(MAX_DATE).add(1, 'day'))

  const filterInput = z.object({
    customerSegmentId: z.string().optional(),
    startDate: z.string().min(1).refine(validateDate),
    endDate: z.string().min(1).refine(validateDate),
  })

  type InputSchema = z.infer<typeof filterInput>

  const { control, handleSubmit, setValue } = useForm<InputSchema>({
    resolver: zodResolver(filterInput),
    defaultValues: {
      customerSegmentId: insightFilter.customerSegmentId,
      startDate: insightFilter.startDate,
      endDate: insightFilter.endDate,
    },
  })

  const handleFilter = async (input: InputSchema) => {
    setSegmentFilterMenuOpen(false)
    setDateRangeFilterMenuOpen(false)
    const updatedFilter = {
      customerSegmentId: input.customerSegmentId,
      startDate: input.startDate,
      endDate: input.endDate,
    }
    setInsightFilter(updatedFilter)
  }

  useEffect(() => {
    let startDate = insightFilter.startDate
    if (datePeriod === '1m') {
      startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    } else if (datePeriod === '3m') {
      startDate = dayjs().subtract(3, 'month').format('YYYY-MM-DD')
    } else if (datePeriod === '6m') {
      startDate = dayjs().subtract(6, 'month').format('YYYY-MM-DD')
    } else if (datePeriod === '12m') {
      startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
    } else if (datePeriod === 'all') {
      startDate = MIN_DATE
    }

    setInsightFilter({
      ...insightFilter,
      startDate: startDate,
      endDate: dayjs().format('YYYY-MM-DD'),
    })

    setValue('startDate', startDate || MIN_DATE)
    setValue('endDate', dayjs().format('YYYY-MM-DD'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePeriod])

  useEffect(() => {
    if (customerSegments && insightFilter.customerSegmentId) {
      const name = customerSegments.find((segment) => segment.ref.id === insightFilter.customerSegmentId)?.name
      if (!name) {
        resetFilterAndRedirect()
        return
      }
      setAutocompleteValue(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegments, insightFilter.customerSegmentId])

  const getCustomerSegmentName = useMemo(
    () => (id: string) => {
      const customerSegment = customerSegments?.find((cs) => cs.ref.id === id)
      if (!customerSegment) return
      return customerSegment.name.length > 32 ? `${customerSegment.name.substring(0, 10)}...` : customerSegment.name
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerSegments, insightFilter.customerSegmentId]
  )

  const resetFilterAndRedirect = () => {
    setInsightFilter({
      customerSegmentId: undefined,
      startDate: undefined,
      endDate: undefined,
    })
    setValue('customerSegmentId', '')
    setValue('startDate', MIN_DATE)
    setValue('endDate', MAX_DATE)
    const to = generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, { id: insightDimension })
    navigate(to)
  }

  return (
    <>
      <Box display='flex'>
        <Button
          variant='outlined'
          size='small'
          ref={segmentFilterMenuAnchorRef}
          onClick={() => setSegmentFilterMenuOpen(true)}
          sx={{ marginRight: '12px', maxWidth: '100px' }}
          startIcon={!insightFilter.customerSegmentId ? <PeopleOutlinedIcon fontSize='small' /> : null}
        >
          {insightFilter.customerSegmentId
            ? getCustomerSegmentName(insightFilter.customerSegmentId) || <Skeleton width='80px' />
            : t('features.insights.detail.all')}
        </Button>

        <Menu anchorEl={segmentFilterMenuAnchorRef.current} open={segmentFilterMenuOpen} onClose={() => setSegmentFilterMenuOpen(false)}>
          <Grid container spacing={3} justifyContent='center' sx={{ padding: '12px 16px', width: '360px' }}>
            <Grid item xs={12}>
              <Typography>{t('features.insights.detail.filterSegment')}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='customerSegmentId'
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth>
                      <Autocomplete
                        size='small'
                        disabled={!customerSegments}
                        options={customerSegments ? customerSegments.map((s) => s.name) : []}
                        value={autocompleteValue}
                        onChange={(_, v) => {
                          setAutocompleteValue(v)
                          const selected = customerSegments && customerSegments.find((cs) => cs.name === v)
                          setValue('customerSegmentId', selected?.ref.id)
                        }}
                        renderOption={(props, option) => (
                          <li {...props} style={{ display: 'block', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={field.value || null}
                            error={Boolean(error)}
                            helperText={error?.message}
                            label={t('features.insights.detail.customerSegment')}
                          />
                        )}
                      />
                    </FormControl>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Button size='small' fullWidth onClick={() => setSegmentFilterMenuOpen(false)}>
                    {t('features.insights.detail.cancel')}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button type='submit' size='small' fullWidth onClick={handleSubmit(handleFilter)} variant='contained'>
                    {t('features.insights.detail.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Menu>

        <ToggleButtonGroup
          value={datePeriod}
          size='small'
          color='primary'
          exclusive
          onChange={(_, v) => {
            if (v === null) {
              if (datePeriod === 'custom') setDateRangeFilterMenuOpen(true)
              return
            }
            if (v === 'custom') setDateRangeFilterMenuOpen(true)
            setDatePeriod(v as InsightDatePeriod)
          }}
          sx={{
            marginRight: '12px',
            '& .MuiToggleButtonGroup-grouped': {
              padding: '4px 12px',
            },
          }}
        >
          <ToggleButton value='all'>{t('features.insights.detail.datePeriod', { context: 'all' })}</ToggleButton>
          <ToggleButton value='1m'>{t('features.insights.detail.datePeriod', { context: '1m' })}</ToggleButton>
          <ToggleButton value='3m'>{t('features.insights.detail.datePeriod', { context: '3m' })}</ToggleButton>
          <ToggleButton value='6m'>{t('features.insights.detail.datePeriod', { context: '6m' })}</ToggleButton>
          <ToggleButton value='12m'>{t('features.insights.detail.datePeriod', { context: '12m' })}</ToggleButton>
          <ToggleButton ref={dateRangeFilterMenuAnchorRef} value='custom'>
            <EventNoteIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
            {datePeriod === 'custom'
              ? `${formatDate(dayjs(insightFilter.startDate), i18n.language)} - ${formatDate(dayjs(insightFilter.endDate), i18n.language)}`
              : t('features.insights.detail.datePeriod', { context: 'custom' })}
          </ToggleButton>
        </ToggleButtonGroup>

        <Menu anchorEl={dateRangeFilterMenuAnchorRef.current} open={dateRangeFilterMenuOpen} onClose={() => setDateRangeFilterMenuOpen(false)}>
          <Grid container spacing={3} justifyContent='center' sx={{ padding: '12px 16px', width: '300px' }}>
            <Grid item xs={12}>
              <Typography>{t('features.insights.detail.filterPeriod')}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='startDate'
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth sx={{ marginBottom: '12px' }}>
                      <TextField {...field} type='date' size='small' error={Boolean(error)} label={t('features.insights.detail.startDate')} />
                    </FormControl>
                  )
                }}
              />

              <Controller
                control={control}
                name='endDate'
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField {...field} type='date' size='small' error={Boolean(error)} label={t('features.insights.detail.endDate')} />
                    </FormControl>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Button size='small' fullWidth onClick={() => setDateRangeFilterMenuOpen(false)}>
                    {t('features.insights.detail.cancel')}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button type='submit' size='small' fullWidth onClick={handleSubmit(handleFilter)} variant='contained'>
                    {t('features.insights.detail.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Menu>
      </Box>
    </>
  )
}
