// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/order/order.proto (package order, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetRequest, GetResponse} from "./order_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service order.OrderService
 */
export const OrderService = {
  typeName: "order.OrderService",
  methods: {
    /**
     * @generated from rpc order.OrderService.Get
     */
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

