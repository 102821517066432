import { Box, LinearProgress } from '@mui/material'
import { FC } from 'react'
import { getImagePath } from 'utils/imageUtil'

export const AppLoadingScreen: FC = () => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box
          component='img'
          src={getImagePath('ecpower_logo.svg')}
          alt='ecpower-logo'
          sx={{
            width: '48px',
            marginBottom: '16px',
          }}
        />
        <LinearProgress sx={{ width: '80px', borderRadius: '4px' }} />
      </Box>
    </Box>
  )
}
