import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_FIRST } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useRegionSegments = (countries: string[], products: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_region_country', { country: countries[0], product: products[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: [countries[0]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_region_country', { country: countries[0], product: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: [countries[0]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_region_country', { country: countries[0], product: products[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: [countries[0]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[2]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_region_country', { country: countries[1], product: products[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: [countries[1]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_region_country', { country: countries[1], product: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: [countries[1]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_region_country', { country: countries[1], product: products[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: [countries[1]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[2]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
  ]
}
