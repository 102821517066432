import AddIcon from '@mui/icons-material/Add'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CloseIcon from '@mui/icons-material/Close'
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded'
import RestaurantMenuRoundedIcon from '@mui/icons-material/RestaurantMenuRounded'
import SearchIcon from '@mui/icons-material/Search'
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded'
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { InteractiveGuideDialog } from 'components/interactiveGuideDialog'
import { SearchDialog } from 'components/searchDialog'
import { isFree } from 'config/plan'
import { CreateDialog as CreateGroupDialog } from 'features/customerSegments/pages/group/components/createDialog'
import { SegmentGroup } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import useLocalStorage from 'hooks/useLocalStrage'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC, MouseEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'
import { FavoritedCustomerSegment } from '../dashboardLayout'
import { SidebarDraggableItems } from './sidebarDraggableItems'

type Props = {
  appBarHeight: string
  segmentGroups: SegmentGroup[] | undefined
  favoritedSegments: FavoritedCustomerSegment[] | undefined
  displayUpgrade: boolean | undefined
  customerSegmentCount: number | undefined
}
export type DraggableItem = {
  id: string
  title: string
  selected: boolean
  onClick: MouseEventHandler<HTMLDivElement> | undefined
}

const logoLightImgPath = process.env.PUBLIC_URL + '/logo.png'
const logoDarkImgPath = process.env.PUBLIC_URL + '/logo_dark.png'

export const SidebarItems: FC<Props> = ({ appBarHeight, segmentGroups, favoritedSegments, displayUpgrade, customerSegmentCount }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { dispatch } = useActionTracker()
  const { account, shop } = useAccount()

  const [openGroups, setOpenGroups] = useLocalStorage<boolean>('SidebarOpenGroups', true)
  const [openFavoritedSegments, setOpenFavoritedSegments] = useLocalStorage<boolean>('SidebarOpenFavoritedSegments', true)
  const [showTutorial, setShowTutorial] = useLocalStorage<boolean>('SidebarShowTutorial', true)
  const [openInteractiveGuideDialog, setOpenInteractiveGuideDialog] = useState(false)
  const [openSearchDialog, setOpenSearchDialog] = useState(false)
  const [createSegmentGroupDialogOpen, setCreateSegmentGroupDialogOpen] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        setOpenSearchDialog(true)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [setOpenSearchDialog])

  useEffect(() => {
    if (location.pathname.includes('groups')) {
      setOpenGroups(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const convertFromSegmentGroups = (segmentGroups: SegmentGroup[] | undefined): DraggableItem[] => {
    if (!segmentGroups) return []
    return segmentGroups.map((segmentGroup) => {
      return {
        id: segmentGroup.ref.id,
        title: segmentGroup.name,
        selected: location.pathname === generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: segmentGroup.ref.id }),
        onClick: () => {
          const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP, { id: segmentGroup.ref.id })
          navigate(to)
        },
      }
    })
  }

  const convertFromFavoriteSegments = (favoritedSegments: FavoritedCustomerSegment[] | undefined): DraggableItem[] => {
    if (!favoritedSegments) return []
    return favoritedSegments.map((favoritedSegment) => {
      return {
        id: favoritedSegment.id,
        title: favoritedSegment.name,
        selected: location.pathname === generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: favoritedSegment.id }),
        onClick: () => {
          const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: favoritedSegment.id })
          navigate(to)
        },
      }
    })
  }

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='space-between' height='100vh'>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 24px', minHeight: appBarHeight }}>
            <img
              src={theme.palette.mode === 'dark' ? logoDarkImgPath : logoLightImgPath}
              alt='ecpower-logo'
              onClick={() => {
                navigate(AUTHORIZED_ROUTE.HOME)
              }}
              style={{
                cursor: 'pointer',
                height: 'auto',
                maxWidth: '128px',
              }}
            />
          </Box>

          <Box sx={{ maxHeight: `calc(100vh - ${appBarHeight} - 80px)`, overflowY: 'auto' }}>
            <List
              component='nav'
              sx={{
                paddingTop: '8px',
                paddingX: '16px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <ButtonGroup variant='outlined' size='small' sx={{ marginBottom: '8px', width: '100%' }}>
                <Button
                  startIcon={<AddCircleOutlineIcon fontSize='small' />}
                  fullWidth
                  onClick={() => {
                    navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE)
                  }}
                >
                  {t('layouts.dashboardLayout.createSegment')}
                </Button>
                <Tooltip title={t('layouts.dashboardLayout.search')}>
                  <Button
                    onClick={() => {
                      setOpenSearchDialog(true)
                    }}
                  >
                    <SearchIcon fontSize='small' />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </List>

            <List
              component='nav'
              sx={{
                paddingTop: '4px',
                paddingX: '8px',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <ListItemButton
                selected={location.pathname.includes(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS) && !location.pathname.includes('group')}
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)
                }}
                sx={{
                  padding: '2px 12px',
                  marginBottom: '2px',
                  borderRadius: '2px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '32px',
                  }}
                >
                  <SupervisorAccountRoundedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={t('layouts.dashboardLayout.customerSegments')}
                  primaryTypographyProps={{
                    fontSize: '14px',
                  }}
                />
              </ListItemButton>

              <ListItemButton
                selected={
                  location.pathname.includes(AUTHORIZED_ROUTE.INSIGHTS) ||
                  location.pathname === AUTHORIZED_ROUTE.COHORTS_MONTHLY ||
                  location.pathname === AUTHORIZED_ROUTE.COHORTS_NTH_ORDER
                }
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.INSIGHTS)
                }}
                sx={{
                  padding: '2px 12px',
                  marginBottom: '2px',
                  borderRadius: '2px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '32px',
                  }}
                >
                  <EmojiObjectsRoundedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={t('layouts.dashboardLayout.customerInsights')}
                  primaryTypographyProps={{
                    fontSize: '14px',
                  }}
                />
              </ListItemButton>

              <ListItemButton
                selected={location.pathname.includes(AUTHORIZED_ROUTE.RECIPES)}
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.RECIPES)
                }}
                sx={{
                  padding: '2px 12px',
                  marginBottom: '2px',
                  borderRadius: '2px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '32px',
                  }}
                >
                  <RestaurantMenuRoundedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText
                  primary={t('layouts.dashboardLayout.recipes')}
                  primaryTypographyProps={{
                    fontSize: '14px',
                  }}
                />
                <Typography
                  fontSize='11px'
                  sx={{
                    color: 'white',
                    backgroundColor: 'primary.main',
                    padding: '1px 6px',
                    borderRadius: '2px',
                  }}
                >
                  ✨ NEW
                </Typography>
              </ListItemButton>
            </List>

            <Divider variant='middle' sx={{ marginBottom: '8px' }} />

            <List
              component='nav'
              sx={{ paddingX: '8px' }}
              subheader={
                <ListSubheader
                  disableSticky
                  component='div'
                  sx={{
                    fontSize: '12px',
                    lineHeight: '3.0',
                    paddingLeft: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '&:hover .showOnHover': {
                      visibility: 'visible',
                    },
                  }}
                >
                  <Box display='flex' alignItems='center'>
                    <IconButton
                      size='small'
                      sx={{ padding: '0px', borderRadius: '4px', marginRight: '2px' }}
                      onClick={() => setOpenGroups(!openGroups)}
                    >
                      {openGroups ? <ArrowDropDownIcon fontSize='small' /> : <ArrowRightIcon fontSize='small' />}
                    </IconButton>
                    {t('layouts.dashboardLayout.segmentGroups')}
                  </Box>
                  <Box>
                    <Tooltip title={t('layouts.dashboardLayout.addNewGroup')} placement='right'>
                      <IconButton
                        className='showOnHover'
                        size='small'
                        sx={{ padding: '2px', borderRadius: '4px', visibility: 'hidden' }}
                        onClick={() => setCreateSegmentGroupDialogOpen(true)}
                      >
                        <AddIcon fontSize='small' sx={{ fontSize: '16px' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ListSubheader>
              }
            >
              {segmentGroups && (
                <Collapse in={openGroups} timeout='auto' unmountOnExit>
                  <SidebarDraggableItems draggableItems={convertFromSegmentGroups(segmentGroups)} localStorageKey='SegmentGroupIDSequence' />
                </Collapse>
              )}
            </List>

            <List
              component='nav'
              sx={{ paddingX: '8px' }}
              subheader={
                <ListSubheader
                  disableSticky
                  component='div'
                  sx={{
                    fontSize: '12px',
                    lineHeight: '3.0',
                    paddingLeft: '10px',
                  }}
                >
                  <Box display='flex' alignItems='center'>
                    <IconButton
                      size='small'
                      sx={{ padding: '0px', borderRadius: '4px', marginRight: '2px' }}
                      onClick={() => setOpenFavoritedSegments(!openFavoritedSegments)}
                    >
                      {openFavoritedSegments ? <ArrowDropDownIcon fontSize='small' /> : <ArrowRightIcon fontSize='small' />}
                    </IconButton>
                    {t('layouts.dashboardLayout.favoritedSegments')}
                  </Box>
                </ListSubheader>
              }
            >
              <Collapse in={openFavoritedSegments} timeout='auto' unmountOnExit>
                <SidebarDraggableItems draggableItems={convertFromFavoriteSegments(favoritedSegments)} localStorageKey='FavoriteSegmentIDSequence' />
              </Collapse>
            </List>

            {displayUpgrade && customerSegmentCount && (
              <Box paddingX='16px' marginTop='20px'>
                <Paper variant='outlined' sx={{ padding: '16px' }}>
                  <Grid spacing={1.5} container>
                    <Grid item xs={12}>
                      <Typography fontSize='14px' fontWeight='bold'>
                        {t('layouts.dashboardLayout.upgradeTitle')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography fontSize='12px' color='text.secondary' marginBottom='8px'>
                        {t('layouts.dashboardLayout.upgradeDescription')}
                      </Typography>
                      <Box>
                        <LinearProgress
                          variant='determinate'
                          value={(customerSegmentCount / 5) * 100}
                          sx={{ marginBottom: '4px', borderRadius: '4px', height: '6px' }}
                        />
                        <Typography fontSize='12px' color='text.secondary'>
                          {t('layouts.dashboardLayout.upgradeProgress', { num: customerSegmentCount, max: 5 })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        size='small'
                        sx={{ width: '100%' }}
                        onClick={() => {
                          dispatch('ClickCheckPlan', { referrer: 'sidebar' })
                          navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
                        }}
                      >
                        {t('layouts.dashboardLayout.upgradeCTA')}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            )}

            {isFree(shop) && showTutorial && (
              <Box paddingX='16px' marginY='20px' position='relative'>
                <Paper
                  variant='outlined'
                  sx={{
                    padding: '16px',
                    cursor: 'pointer',
                    '&:hover': {
                      boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  onClick={() => setOpenInteractiveGuideDialog(true)}
                >
                  <Typography fontSize='24px' marginBottom='4px'>
                    🌱
                  </Typography>
                  <Typography fontSize='12px' color='text.secondary'>
                    {t('layouts.dashboardLayout.tutorialTitle')}
                  </Typography>
                </Paper>
                <IconButton onClick={() => setShowTutorial(false)} sx={{ position: 'absolute', top: '12px', right: '28px', padding: '4px' }}>
                  <CloseIcon fontSize='small' sx={{ fontSize: '14px' }} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          <Box
            display='flex'
            alignItems='center'
            padding='16px 16px 20px'
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(AUTHORIZED_ROUTE.SETTINGS_ACCOUNT)}
          >
            <Avatar
              src={theme.palette.mode === 'dark' ? getImagePath('avatar_dark.svg') : getImagePath('avatar.svg')}
              sx={{ width: '28px', height: '28px', marginRight: '8px' }}
            />
            <Box sx={{ width: '180px' }}>
              <Typography fontSize='14px' fontWeight='bold' marginBottom='-2px' noWrap overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                {shop.displayName}
              </Typography>
              <Typography fontSize='12px' color='text.secondary' noWrap overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                {account.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {openSearchDialog && <SearchDialog open={openSearchDialog} handleClose={() => setOpenSearchDialog(false)} />}
      {openInteractiveGuideDialog && (
        <InteractiveGuideDialog open={openInteractiveGuideDialog} handleClose={() => setOpenInteractiveGuideDialog(false)} referrer='sidebar' />
      )}
      {createSegmentGroupDialogOpen && (
        <CreateGroupDialog open={createSegmentGroupDialogOpen} handleClose={() => setCreateSegmentGroupDialogOpen(false)} />
      )}
    </>
  )
}
