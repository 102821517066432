import { zodResolver } from '@hookform/resolvers/zod'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Button, FormControl, Grid, Menu, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { z } from 'zod'

export type DateRange = '7d' | '30d' | '60d' | '90d' | '6m' | '1y' | 'custom'

type Props = {
  dateRange: DateRange
  startDate: Dayjs
  endDate: Dayjs
  onDateRangeChange: (newTimeRange: DateRange) => void
  onFilter: (startDate: Dayjs, endDate: Dayjs) => void
  minDate: Dayjs
  dateRangeOptions: DateRange[]
}

export const DateRangeSelector: FC<Props> = ({ dateRange, startDate, endDate, onDateRangeChange, onFilter, minDate, dateRangeOptions }) => {
  const { t, i18n } = useTranslation()

  const dateRangeCustomMenuAnchorRef = useRef<HTMLButtonElement | null>(null)
  const [dateRangeCustomMenuOpen, setDateRangeCustomMenuOpen] = useState(false)

  const filterInput = z
    .object({
      startDate: z
        .string()
        .min(1)
        .refine((value) => dayjs(value).isAfter(minDate.subtract(1, 'day')), {
          message: t('common.dateRangeSelector.messageValidationMinDate', { minDate: formatDate(minDate, i18n.language) }),
        }),
      endDate: z
        .string()
        .min(1)
        .refine((value) => dayjs(value).isAfter(minDate.subtract(1, 'day')), {
          message: t('common.dateRangeSelector.messageValidationMinDate', { minDate: formatDate(minDate, i18n.language) }),
        }),
    })
    .refine((data) => dayjs(data.endDate).isAfter(dayjs(data.startDate)), {
      message: t('common.dateRangeSelector.messageValidateMinDateToMaxDate'),
      path: ['endDate'],
    })

  type InputSchema = z.infer<typeof filterInput>

  const { control, handleSubmit } = useForm<InputSchema>({
    resolver: zodResolver(filterInput),
    defaultValues: {
      startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    },
  })

  const handleFilterInput = async (data: InputSchema) => {
    const { startDate, endDate } = data
    onFilter(dayjs(startDate), dayjs(endDate))
    setDateRangeCustomMenuOpen(false)
  }

  return (
    <>
      <ToggleButtonGroup
        value={dateRange}
        size='small'
        color='primary'
        exclusive
        onChange={(_, newDateRange) => {
          if (newDateRange === 'custom' || (newDateRange === null && dateRange === 'custom')) {
            setDateRangeCustomMenuOpen(true)
            console.log('dateRange', newDateRange)
            return
          }
          onDateRangeChange(newDateRange)
        }}
        sx={{
          marginRight: '12px',
          '& .MuiToggleButtonGroup-grouped': {
            padding: '4px 12px',
          },
        }}
      >
        {['7d', '30d', '60d', '90d', '6m', '1y'].map(
          (range) =>
            dateRangeOptions.includes(range as DateRange) && (
              <ToggleButton key={range} value={range}>
                {t(`common.dateRangeSelector.dateRange_${range}`)}
              </ToggleButton>
            )
        )}
        {dateRangeOptions.includes('custom') && (
          <ToggleButton ref={dateRangeCustomMenuAnchorRef} value='custom'>
            <EventNoteIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
            {dateRange === 'custom'
              ? `${formatDate(startDate, i18n.language)} - ${formatDate(endDate, i18n.language)}`
              : t('common.dateRangeSelector.dateRange_custom')}
          </ToggleButton>
        )}
      </ToggleButtonGroup>

      <Menu anchorEl={dateRangeCustomMenuAnchorRef.current} open={dateRangeCustomMenuOpen} onClose={() => setDateRangeCustomMenuOpen(false)}>
        <Grid container spacing={3} justifyContent='center' sx={{ padding: '12px 16px', width: '300px' }}>
          <Grid item xs={12}>
            <Typography>{t('common.dateRangeSelector.filterPeriod')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name='startDate'
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl fullWidth sx={{ marginBottom: '12px' }}>
                    <TextField
                      {...field}
                      type='date'
                      size='small'
                      error={Boolean(error)}
                      helperText={error ? error.message : ''}
                      label={t('common.dateRangeSelector.startDate')}
                    />
                  </FormControl>
                )
              }}
            />

            <Controller
              control={control}
              name='endDate'
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      {...field}
                      type='date'
                      size='small'
                      error={Boolean(error)}
                      helperText={error ? error.message : ''}
                      label={t('common.dateRangeSelector.endDate')}
                    />
                  </FormControl>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button size='small' fullWidth onClick={() => setDateRangeCustomMenuOpen(false)}>
                  {t('common.dateRangeSelector.cancel')}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button type='submit' size='small' fullWidth onClick={handleSubmit(handleFilterInput)} variant='contained'>
                  {t('common.dateRangeSelector.apply')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  )
}
