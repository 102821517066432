import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'

export type InsightFilterState = {
  customerSegmentId: string | undefined
  startDate: string | undefined
  endDate: string | undefined
}

export type InsightDatePeriod = 'all' | '1m' | '3m' | '6m' | '12m' | 'custom'

export type InsightDrawerState = {
  open: boolean
  querySet?: QuerySetFormState
}

export const addIndexToName = (name: string, rank: number) => {
  if (rank === 1) return `🥇 ${name}`
  if (rank === 2) return `🥈 ${name}`
  if (rank === 3) return `🥉 ${name}`
  return `◽️ ${name}`
}

export const chartColors = ['#8F783F', '#3B445C', '#64593D', '#3F548F', '#3A362D', '#2187A6', '#73502E', '#2D697B', '#A66321', '#2B4851']
export const chartColorsDark = ['#AD794F', '#51747A', '#836852', '#50A0AD', '#584E46', '#00A8D9', '#A66321', '#1D8CAE', '#D96C00', '#2C6F84']
