// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/segment_group/segment_group.proto (package segment_group, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * Create
 *
 * @generated from message segment_group.CreateRequest
 */
export class CreateRequest extends Message<CreateRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<CreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.CreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRequest {
    return new CreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRequest | PlainMessage<CreateRequest> | undefined, b: CreateRequest | PlainMessage<CreateRequest> | undefined): boolean {
    return proto3.util.equals(CreateRequest, a, b);
  }
}

/**
 * @generated from message segment_group.CreateResponse
 */
export class CreateResponse extends Message<CreateResponse> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  constructor(data?: PartialMessage<CreateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.CreateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponse {
    return new CreateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateResponse | PlainMessage<CreateResponse> | undefined, b: CreateResponse | PlainMessage<CreateResponse> | undefined): boolean {
    return proto3.util.equals(CreateResponse, a, b);
  }
}

/**
 * Update
 *
 * @generated from message segment_group.UpdateRequest
 */
export class UpdateRequest extends Message<UpdateRequest> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<UpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRequest {
    return new UpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRequest {
    return new UpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRequest {
    return new UpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRequest | PlainMessage<UpdateRequest> | undefined, b: UpdateRequest | PlainMessage<UpdateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRequest, a, b);
  }
}

/**
 * @generated from message segment_group.UpdateResponse
 */
export class UpdateResponse extends Message<UpdateResponse> {
  constructor(data?: PartialMessage<UpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateResponse {
    return new UpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateResponse {
    return new UpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateResponse {
    return new UpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateResponse | PlainMessage<UpdateResponse> | undefined, b: UpdateResponse | PlainMessage<UpdateResponse> | undefined): boolean {
    return proto3.util.equals(UpdateResponse, a, b);
  }
}

/**
 * Delete
 *
 * @generated from message segment_group.DeleteRequest
 */
export class DeleteRequest extends Message<DeleteRequest> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  constructor(data?: PartialMessage<DeleteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.DeleteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRequest {
    return new DeleteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRequest {
    return new DeleteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRequest {
    return new DeleteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRequest | PlainMessage<DeleteRequest> | undefined, b: DeleteRequest | PlainMessage<DeleteRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRequest, a, b);
  }
}

/**
 * @generated from message segment_group.DeleteResponse
 */
export class DeleteResponse extends Message<DeleteResponse> {
  constructor(data?: PartialMessage<DeleteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.DeleteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteResponse {
    return new DeleteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteResponse {
    return new DeleteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteResponse {
    return new DeleteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteResponse | PlainMessage<DeleteResponse> | undefined, b: DeleteResponse | PlainMessage<DeleteResponse> | undefined): boolean {
    return proto3.util.equals(DeleteResponse, a, b);
  }
}

/**
 * AddSegments
 *
 * @generated from message segment_group.AddSegmentsRequest
 */
export class AddSegmentsRequest extends Message<AddSegmentsRequest> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  /**
   * @generated from field: repeated string customerSegmentIds = 2;
   */
  customerSegmentIds: string[] = [];

  constructor(data?: PartialMessage<AddSegmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.AddSegmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddSegmentsRequest {
    return new AddSegmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddSegmentsRequest {
    return new AddSegmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddSegmentsRequest {
    return new AddSegmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddSegmentsRequest | PlainMessage<AddSegmentsRequest> | undefined, b: AddSegmentsRequest | PlainMessage<AddSegmentsRequest> | undefined): boolean {
    return proto3.util.equals(AddSegmentsRequest, a, b);
  }
}

/**
 * @generated from message segment_group.AddSegmentsResponse
 */
export class AddSegmentsResponse extends Message<AddSegmentsResponse> {
  constructor(data?: PartialMessage<AddSegmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.AddSegmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddSegmentsResponse {
    return new AddSegmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddSegmentsResponse {
    return new AddSegmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddSegmentsResponse {
    return new AddSegmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddSegmentsResponse | PlainMessage<AddSegmentsResponse> | undefined, b: AddSegmentsResponse | PlainMessage<AddSegmentsResponse> | undefined): boolean {
    return proto3.util.equals(AddSegmentsResponse, a, b);
  }
}

/**
 * RemoveSegments
 *
 * @generated from message segment_group.RemoveSegmentsRequest
 */
export class RemoveSegmentsRequest extends Message<RemoveSegmentsRequest> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  /**
   * @generated from field: repeated string customerSegmentIds = 2;
   */
  customerSegmentIds: string[] = [];

  constructor(data?: PartialMessage<RemoveSegmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.RemoveSegmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveSegmentsRequest {
    return new RemoveSegmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveSegmentsRequest {
    return new RemoveSegmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveSegmentsRequest {
    return new RemoveSegmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveSegmentsRequest | PlainMessage<RemoveSegmentsRequest> | undefined, b: RemoveSegmentsRequest | PlainMessage<RemoveSegmentsRequest> | undefined): boolean {
    return proto3.util.equals(RemoveSegmentsRequest, a, b);
  }
}

/**
 * @generated from message segment_group.RemoveSegmentsResponse
 */
export class RemoveSegmentsResponse extends Message<RemoveSegmentsResponse> {
  constructor(data?: PartialMessage<RemoveSegmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.RemoveSegmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveSegmentsResponse {
    return new RemoveSegmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveSegmentsResponse {
    return new RemoveSegmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveSegmentsResponse {
    return new RemoveSegmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveSegmentsResponse | PlainMessage<RemoveSegmentsResponse> | undefined, b: RemoveSegmentsResponse | PlainMessage<RemoveSegmentsResponse> | undefined): boolean {
    return proto3.util.equals(RemoveSegmentsResponse, a, b);
  }
}

/**
 * UpdateSegmentsSequence
 *
 * @generated from message segment_group.UpdateSegmentsSequenceRequest
 */
export class UpdateSegmentsSequenceRequest extends Message<UpdateSegmentsSequenceRequest> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  /**
   * @generated from field: repeated string segmentIdsInSequence = 2;
   */
  segmentIdsInSequence: string[] = [];

  constructor(data?: PartialMessage<UpdateSegmentsSequenceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateSegmentsSequenceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "segmentIdsInSequence", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSegmentsSequenceRequest {
    return new UpdateSegmentsSequenceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSegmentsSequenceRequest {
    return new UpdateSegmentsSequenceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSegmentsSequenceRequest {
    return new UpdateSegmentsSequenceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSegmentsSequenceRequest | PlainMessage<UpdateSegmentsSequenceRequest> | undefined, b: UpdateSegmentsSequenceRequest | PlainMessage<UpdateSegmentsSequenceRequest> | undefined): boolean {
    return proto3.util.equals(UpdateSegmentsSequenceRequest, a, b);
  }
}

/**
 * @generated from message segment_group.UpdateSegmentsSequenceResponse
 */
export class UpdateSegmentsSequenceResponse extends Message<UpdateSegmentsSequenceResponse> {
  constructor(data?: PartialMessage<UpdateSegmentsSequenceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateSegmentsSequenceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSegmentsSequenceResponse {
    return new UpdateSegmentsSequenceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSegmentsSequenceResponse {
    return new UpdateSegmentsSequenceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSegmentsSequenceResponse {
    return new UpdateSegmentsSequenceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSegmentsSequenceResponse | PlainMessage<UpdateSegmentsSequenceResponse> | undefined, b: UpdateSegmentsSequenceResponse | PlainMessage<UpdateSegmentsSequenceResponse> | undefined): boolean {
    return proto3.util.equals(UpdateSegmentsSequenceResponse, a, b);
  }
}

/**
 * UpdateCanvasState
 *
 * @generated from message segment_group.UpdateCanvasStateRequest
 */
export class UpdateCanvasStateRequest extends Message<UpdateCanvasStateRequest> {
  /**
   * @generated from field: string segmentGroupId = 1;
   */
  segmentGroupId = "";

  /**
   * @generated from field: repeated segment_group.UpdateCanvasStateRequest.Node nodes = 2;
   */
  nodes: UpdateCanvasStateRequest_Node[] = [];

  /**
   * @generated from field: repeated segment_group.UpdateCanvasStateRequest.Edge edges = 3;
   */
  edges: UpdateCanvasStateRequest_Edge[] = [];

  constructor(data?: PartialMessage<UpdateCanvasStateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateCanvasStateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "segmentGroupId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "nodes", kind: "message", T: UpdateCanvasStateRequest_Node, repeated: true },
    { no: 3, name: "edges", kind: "message", T: UpdateCanvasStateRequest_Edge, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCanvasStateRequest {
    return new UpdateCanvasStateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCanvasStateRequest {
    return new UpdateCanvasStateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCanvasStateRequest {
    return new UpdateCanvasStateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCanvasStateRequest | PlainMessage<UpdateCanvasStateRequest> | undefined, b: UpdateCanvasStateRequest | PlainMessage<UpdateCanvasStateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCanvasStateRequest, a, b);
  }
}

/**
 * @generated from message segment_group.UpdateCanvasStateRequest.Node
 */
export class UpdateCanvasStateRequest_Node extends Message<UpdateCanvasStateRequest_Node> {
  /**
   * Customer Segment ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: double x = 2;
   */
  x = 0;

  /**
   * @generated from field: double y = 3;
   */
  y = 0;

  constructor(data?: PartialMessage<UpdateCanvasStateRequest_Node>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateCanvasStateRequest.Node";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCanvasStateRequest_Node {
    return new UpdateCanvasStateRequest_Node().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCanvasStateRequest_Node {
    return new UpdateCanvasStateRequest_Node().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCanvasStateRequest_Node {
    return new UpdateCanvasStateRequest_Node().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCanvasStateRequest_Node | PlainMessage<UpdateCanvasStateRequest_Node> | undefined, b: UpdateCanvasStateRequest_Node | PlainMessage<UpdateCanvasStateRequest_Node> | undefined): boolean {
    return proto3.util.equals(UpdateCanvasStateRequest_Node, a, b);
  }
}

/**
 * @generated from message segment_group.UpdateCanvasStateRequest.Edge
 */
export class UpdateCanvasStateRequest_Edge extends Message<UpdateCanvasStateRequest_Edge> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Node ID
   *
   * @generated from field: string source = 2;
   */
  source = "";

  /**
   * Node ID
   *
   * @generated from field: string target = 3;
   */
  target = "";

  constructor(data?: PartialMessage<UpdateCanvasStateRequest_Edge>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateCanvasStateRequest.Edge";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCanvasStateRequest_Edge {
    return new UpdateCanvasStateRequest_Edge().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCanvasStateRequest_Edge {
    return new UpdateCanvasStateRequest_Edge().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCanvasStateRequest_Edge {
    return new UpdateCanvasStateRequest_Edge().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCanvasStateRequest_Edge | PlainMessage<UpdateCanvasStateRequest_Edge> | undefined, b: UpdateCanvasStateRequest_Edge | PlainMessage<UpdateCanvasStateRequest_Edge> | undefined): boolean {
    return proto3.util.equals(UpdateCanvasStateRequest_Edge, a, b);
  }
}

/**
 * @generated from message segment_group.UpdateCanvasStateResponse
 */
export class UpdateCanvasStateResponse extends Message<UpdateCanvasStateResponse> {
  constructor(data?: PartialMessage<UpdateCanvasStateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "segment_group.UpdateCanvasStateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCanvasStateResponse {
    return new UpdateCanvasStateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCanvasStateResponse {
    return new UpdateCanvasStateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCanvasStateResponse {
    return new UpdateCanvasStateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCanvasStateResponse | PlainMessage<UpdateCanvasStateResponse> | undefined, b: UpdateCanvasStateResponse | PlainMessage<UpdateCanvasStateResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCanvasStateResponse, a, b);
  }
}

