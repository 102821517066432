import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'

export type Recipe = {
  key: RecipeKey
  title: string
  description: string
  tags: TagKey[]
  segments?: RecipeSegment[]
}

export type RecipeSegment = {
  name: string
  querySet: QuerySetFormState
  existed?: boolean
  metrics?: RecipeSegmentMetrics
  loading?: boolean
}

export type RecipeSegmentMetrics = {
  count: number
  repeaterRate: number
  ltv: number
}

// Recipe Keys
const recipeKeysArray = [
  'product',
  'productType',
  'loyalty',
  'acqFirst',
  'acqRepeat',
  'couponFirst',
  'couponSecond',
  'productCross',
  'productVendor',
  'preference',
  'buyingCycle',
  'event',
  'gift',
  'highValue',
  'entry',
  'omnichannel',
  'subscription',
  'trial',
  'region',
  'holiday',
  'rfm',
] as const

export type RecipeKey = typeof recipeKeysArray[number]
export const recipeKeys: RecipeKey[] = [...recipeKeysArray]

// Tag Keys
const tagKeysArray = [
  'all',
  'first',
  'repeat',
  'apparel',
  'household',
  'gift',
  'subscription',
  'crossBorder',
  'omnichannel',
  'startup',
  'sku',
  'crm',
  'analysis',
  'product',
  'acquisition',
  'order',
  'customer',
] as const

export type TagKey = typeof tagKeysArray[number]
export const tagKeys: TagKey[] = [...tagKeysArray]
