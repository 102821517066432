import { OP_EQUAL, OP_INT_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useRFMSegments = (): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_rfmActive1sts'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { max: 30 }, filters: [] },
        { dimension: 'frequency', operator: OP_EQUAL, value: 1, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmActiveLoyals'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { max: 30 }, filters: [] },
        { dimension: 'frequency', operator: OP_INT_RANGE, value: { min: 5 }, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmActiveRepeats'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { max: 30 }, filters: [] },
        { dimension: 'frequency', operator: OP_INT_RANGE, value: { min: 2, max: 4 }, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmAtRisk1sts'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { min: 31, max: 90 }, filters: [] },
        { dimension: 'frequency', operator: OP_EQUAL, value: 1, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmAtRiskLoyals'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { min: 31, max: 90 }, filters: [] },
        { dimension: 'frequency', operator: OP_INT_RANGE, value: { min: 5 }, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmAtRiskRepeats'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { min: 31, max: 90 }, filters: [] },
        { dimension: 'frequency', operator: OP_INT_RANGE, value: { min: 2, max: 4 }, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmChurned1sts'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { min: 91 }, filters: [] },
        { dimension: 'frequency', operator: OP_EQUAL, value: 1, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmChurnedLoyals'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { min: 91 }, filters: [] },
        { dimension: 'frequency', operator: OP_INT_RANGE, value: { min: 5 }, filters: [] },
      ]),
    },
    {
      name: t('features.recipes.segments.name_rfmChurnedRepeats'),
      querySet: generateQuerySet([
        { dimension: 'recency', operator: OP_INT_RANGE, value: { min: 91 }, filters: [] },
        { dimension: 'frequency', operator: OP_INT_RANGE, value: { min: 2, max: 4 }, filters: [] },
      ]),
    },
  ]
}
