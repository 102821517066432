import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { FC } from 'react'

type Props = {
  columnCount: number
  rowCount: number
  small?: boolean
}

// SkeletonTable: Component for displaying when table is loading
export const SkeletonTable: FC<Props> = ({ columnCount, rowCount, small }) => {
  return (
    <TableContainer>
      <Table stickyHeader size={small ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {Array(columnCount)
              .fill(0)
              .map((_, i) => (
                <TableCell key={i}>
                  <Skeleton width='90%' height='16px' />
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(rowCount)
            .fill(0)
            .map((_, i) => (
              <TableRow key={i}>
                {Array(columnCount)
                  .fill(0)
                  .map((_, j) => (
                    <TableCell key={j}>
                      <Skeleton width='90%' height='16px' />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
