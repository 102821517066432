// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/auth/auth.proto (package auth, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetShopByTokenRequest, GetShopByTokenResponse, MemberSignupRequest, MemberSignupResponse, SignupRequest, SignupResponse, VerifyAppInstallationRequest, VerifyAppInstallationResponse} from "./auth_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service auth.AuthService
 */
export const AuthService = {
  typeName: "auth.AuthService",
  methods: {
    /**
     * @generated from rpc auth.AuthService.GetShopByToken
     */
    getShopByToken: {
      name: "GetShopByToken",
      I: GetShopByTokenRequest,
      O: GetShopByTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc auth.AuthService.Signup
     */
    signup: {
      name: "Signup",
      I: SignupRequest,
      O: SignupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc auth.AuthService.MemberSignup
     */
    memberSignup: {
      name: "MemberSignup",
      I: MemberSignupRequest,
      O: MemberSignupResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc auth.AuthService.VerifyAppInstallation
     */
    verifyAppInstallation: {
      name: "VerifyAppInstallation",
      I: VerifyAppInstallationRequest,
      O: VerifyAppInstallationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

