// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/customer_segment/customer_segment.proto (package customer_segment, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, protoInt64} from "@bufbuild/protobuf";

/**
 * Create
 *
 * @generated from message customer_segment.CreateRequest
 */
export class CreateRequest extends Message<CreateRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string querySet = 3;
   */
  querySet = "";

  constructor(data?: PartialMessage<CreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.CreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "querySet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRequest {
    return new CreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRequest | PlainMessage<CreateRequest> | undefined, b: CreateRequest | PlainMessage<CreateRequest> | undefined): boolean {
    return proto3.util.equals(CreateRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.CreateResponse
 */
export class CreateResponse extends Message<CreateResponse> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  constructor(data?: PartialMessage<CreateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.CreateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponse {
    return new CreateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateResponse | PlainMessage<CreateResponse> | undefined, b: CreateResponse | PlainMessage<CreateResponse> | undefined): boolean {
    return proto3.util.equals(CreateResponse, a, b);
  }
}

/**
 * Update
 *
 * @generated from message customer_segment.UpdateRequest
 */
export class UpdateRequest extends Message<UpdateRequest> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: bool favorited = 4;
   */
  favorited = false;

  constructor(data?: PartialMessage<UpdateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.UpdateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "favorited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRequest {
    return new UpdateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRequest {
    return new UpdateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRequest {
    return new UpdateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRequest | PlainMessage<UpdateRequest> | undefined, b: UpdateRequest | PlainMessage<UpdateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.UpdateResponse
 */
export class UpdateResponse extends Message<UpdateResponse> {
  constructor(data?: PartialMessage<UpdateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.UpdateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateResponse {
    return new UpdateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateResponse {
    return new UpdateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateResponse {
    return new UpdateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateResponse | PlainMessage<UpdateResponse> | undefined, b: UpdateResponse | PlainMessage<UpdateResponse> | undefined): boolean {
    return proto3.util.equals(UpdateResponse, a, b);
  }
}

/**
 * Delete
 *
 * @generated from message customer_segment.DeleteRequest
 */
export class DeleteRequest extends Message<DeleteRequest> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  constructor(data?: PartialMessage<DeleteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.DeleteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteRequest {
    return new DeleteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteRequest {
    return new DeleteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteRequest {
    return new DeleteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteRequest | PlainMessage<DeleteRequest> | undefined, b: DeleteRequest | PlainMessage<DeleteRequest> | undefined): boolean {
    return proto3.util.equals(DeleteRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.DeleteResponse
 */
export class DeleteResponse extends Message<DeleteResponse> {
  constructor(data?: PartialMessage<DeleteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.DeleteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteResponse {
    return new DeleteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteResponse {
    return new DeleteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteResponse {
    return new DeleteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteResponse | PlainMessage<DeleteResponse> | undefined, b: DeleteResponse | PlainMessage<DeleteResponse> | undefined): boolean {
    return proto3.util.equals(DeleteResponse, a, b);
  }
}

/**
 * GetCustomers
 *
 * @generated from message customer_segment.GetCustomersRequest
 */
export class GetCustomersRequest extends Message<GetCustomersRequest> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  constructor(data?: PartialMessage<GetCustomersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.GetCustomersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomersRequest {
    return new GetCustomersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomersRequest {
    return new GetCustomersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomersRequest {
    return new GetCustomersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomersRequest | PlainMessage<GetCustomersRequest> | undefined, b: GetCustomersRequest | PlainMessage<GetCustomersRequest> | undefined): boolean {
    return proto3.util.equals(GetCustomersRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.GetCustomersResponse
 */
export class GetCustomersResponse extends Message<GetCustomersResponse> {
  /**
   * @generated from field: repeated customer_segment.GetCustomersResponse.Customer customers = 1;
   */
  customers: GetCustomersResponse_Customer[] = [];

  constructor(data?: PartialMessage<GetCustomersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.GetCustomersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customers", kind: "message", T: GetCustomersResponse_Customer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomersResponse {
    return new GetCustomersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomersResponse {
    return new GetCustomersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomersResponse {
    return new GetCustomersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomersResponse | PlainMessage<GetCustomersResponse> | undefined, b: GetCustomersResponse | PlainMessage<GetCustomersResponse> | undefined): boolean {
    return proto3.util.equals(GetCustomersResponse, a, b);
  }
}

/**
 * @generated from message customer_segment.GetCustomersResponse.Customer
 */
export class GetCustomersResponse_Customer extends Message<GetCustomersResponse_Customer> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string firstName = 3;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 4;
   */
  lastName = "";

  /**
   * @generated from field: int64 recency = 5;
   */
  recency = protoInt64.zero;

  /**
   * @generated from field: int64 frequency = 6;
   */
  frequency = protoInt64.zero;

  /**
   * @generated from field: double monetary = 7;
   */
  monetary = 0;

  constructor(data?: PartialMessage<GetCustomersResponse_Customer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.GetCustomersResponse.Customer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "recency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "frequency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "monetary", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomersResponse_Customer {
    return new GetCustomersResponse_Customer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomersResponse_Customer {
    return new GetCustomersResponse_Customer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomersResponse_Customer {
    return new GetCustomersResponse_Customer().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomersResponse_Customer | PlainMessage<GetCustomersResponse_Customer> | undefined, b: GetCustomersResponse_Customer | PlainMessage<GetCustomersResponse_Customer> | undefined): boolean {
    return proto3.util.equals(GetCustomersResponse_Customer, a, b);
  }
}

/**
 * GetOrders
 *
 * @generated from message customer_segment.GetOrdersRequest
 */
export class GetOrdersRequest extends Message<GetOrdersRequest> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  constructor(data?: PartialMessage<GetOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.GetOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined, b: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined): boolean {
    return proto3.util.equals(GetOrdersRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.GetOrdersResponse
 */
export class GetOrdersResponse extends Message<GetOrdersResponse> {
  /**
   * @generated from field: repeated customer_segment.GetOrdersResponse.Order orders = 1;
   */
  orders: GetOrdersResponse_Order[] = [];

  constructor(data?: PartialMessage<GetOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.GetOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "orders", kind: "message", T: GetOrdersResponse_Order, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined, b: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponse, a, b);
  }
}

/**
 * @generated from message customer_segment.GetOrdersResponse.Order
 */
export class GetOrdersResponse_Order extends Message<GetOrdersResponse_Order> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: double amount = 3;
   */
  amount = 0;

  /**
   * format: "2006-01-02 15:04:05"
   *
   * @generated from field: string orderedAt = 4;
   */
  orderedAt = "";

  /**
   * @generated from field: string customerId = 5;
   */
  customerId = "";

  /**
   * @generated from field: string firstName = 6;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 7;
   */
  lastName = "";

  /**
   * @generated from field: int64 nthOrder = 8;
   */
  nthOrder = protoInt64.zero;

  /**
   * @generated from field: string referrer = 9;
   */
  referrer = "";

  /**
   * @generated from field: string landingPage = 10;
   */
  landingPage = "";

  /**
   * @generated from field: string channel = 11;
   */
  channel = "";

  /**
   * @generated from field: string coupon = 12;
   */
  coupon = "";

  /**
   * @generated from field: string utmSource = 13;
   */
  utmSource = "";

  /**
   * @generated from field: string utmMedium = 14;
   */
  utmMedium = "";

  /**
   * @generated from field: string utmCampaign = 15;
   */
  utmCampaign = "";

  /**
   * @generated from field: repeated string tags = 16;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<GetOrdersResponse_Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.GetOrdersResponse.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "orderedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "customerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "nthOrder", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "referrer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "landingPage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "channel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "coupon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "utmSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "utmMedium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "utmCampaign", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponse_Order {
    return new GetOrdersResponse_Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponse_Order {
    return new GetOrdersResponse_Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponse_Order {
    return new GetOrdersResponse_Order().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponse_Order | PlainMessage<GetOrdersResponse_Order> | undefined, b: GetOrdersResponse_Order | PlainMessage<GetOrdersResponse_Order> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponse_Order, a, b);
  }
}

/**
 * EstimateCustomerMetrics
 *
 * @generated from message customer_segment.EstimateCustomerMetricsRequest
 */
export class EstimateCustomerMetricsRequest extends Message<EstimateCustomerMetricsRequest> {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet = "";

  constructor(data?: PartialMessage<EstimateCustomerMetricsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateCustomerMetricsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "querySet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateCustomerMetricsRequest {
    return new EstimateCustomerMetricsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateCustomerMetricsRequest {
    return new EstimateCustomerMetricsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateCustomerMetricsRequest {
    return new EstimateCustomerMetricsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateCustomerMetricsRequest | PlainMessage<EstimateCustomerMetricsRequest> | undefined, b: EstimateCustomerMetricsRequest | PlainMessage<EstimateCustomerMetricsRequest> | undefined): boolean {
    return proto3.util.equals(EstimateCustomerMetricsRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateCustomerMetricsResponse
 */
export class EstimateCustomerMetricsResponse extends Message<EstimateCustomerMetricsResponse> {
  /**
   * @generated from field: customer_segment.EstimateCustomerMetricsResponse.CustomerMetrics result = 1;
   */
  result?: EstimateCustomerMetricsResponse_CustomerMetrics;

  constructor(data?: PartialMessage<EstimateCustomerMetricsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateCustomerMetricsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "result", kind: "message", T: EstimateCustomerMetricsResponse_CustomerMetrics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateCustomerMetricsResponse {
    return new EstimateCustomerMetricsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateCustomerMetricsResponse {
    return new EstimateCustomerMetricsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateCustomerMetricsResponse {
    return new EstimateCustomerMetricsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateCustomerMetricsResponse | PlainMessage<EstimateCustomerMetricsResponse> | undefined, b: EstimateCustomerMetricsResponse | PlainMessage<EstimateCustomerMetricsResponse> | undefined): boolean {
    return proto3.util.equals(EstimateCustomerMetricsResponse, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateCustomerMetricsResponse.CustomerMetrics
 */
export class EstimateCustomerMetricsResponse_CustomerMetrics extends Message<EstimateCustomerMetricsResponse_CustomerMetrics> {
  /**
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * @generated from field: double repeaterRate = 2;
   */
  repeaterRate = 0;

  /**
   * @generated from field: double ltv = 3;
   */
  ltv = 0;

  /**
   * @generated from field: double avgOrderValue = 4;
   */
  avgOrderValue = 0;

  /**
   * @generated from field: double avgOrderCount = 5;
   */
  avgOrderCount = 0;

  /**
   * @generated from field: double avgDaysBetweenOrders = 6;
   */
  avgDaysBetweenOrders = 0;

  constructor(data?: PartialMessage<EstimateCustomerMetricsResponse_CustomerMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateCustomerMetricsResponse.CustomerMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "repeaterRate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "ltv", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "avgOrderValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "avgOrderCount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "avgDaysBetweenOrders", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateCustomerMetricsResponse_CustomerMetrics {
    return new EstimateCustomerMetricsResponse_CustomerMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateCustomerMetricsResponse_CustomerMetrics {
    return new EstimateCustomerMetricsResponse_CustomerMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateCustomerMetricsResponse_CustomerMetrics {
    return new EstimateCustomerMetricsResponse_CustomerMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateCustomerMetricsResponse_CustomerMetrics | PlainMessage<EstimateCustomerMetricsResponse_CustomerMetrics> | undefined, b: EstimateCustomerMetricsResponse_CustomerMetrics | PlainMessage<EstimateCustomerMetricsResponse_CustomerMetrics> | undefined): boolean {
    return proto3.util.equals(EstimateCustomerMetricsResponse_CustomerMetrics, a, b);
  }
}

/**
 * EstimateCustomers
 *
 * @generated from message customer_segment.EstimateCustomersRequest
 */
export class EstimateCustomersRequest extends Message<EstimateCustomersRequest> {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet = "";

  constructor(data?: PartialMessage<EstimateCustomersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateCustomersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "querySet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateCustomersRequest {
    return new EstimateCustomersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateCustomersRequest {
    return new EstimateCustomersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateCustomersRequest {
    return new EstimateCustomersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateCustomersRequest | PlainMessage<EstimateCustomersRequest> | undefined, b: EstimateCustomersRequest | PlainMessage<EstimateCustomersRequest> | undefined): boolean {
    return proto3.util.equals(EstimateCustomersRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateCustomersResponse
 */
export class EstimateCustomersResponse extends Message<EstimateCustomersResponse> {
  /**
   * @generated from field: repeated customer_segment.EstimateCustomersResponse.Customer customers = 1;
   */
  customers: EstimateCustomersResponse_Customer[] = [];

  constructor(data?: PartialMessage<EstimateCustomersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateCustomersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customers", kind: "message", T: EstimateCustomersResponse_Customer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateCustomersResponse {
    return new EstimateCustomersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateCustomersResponse {
    return new EstimateCustomersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateCustomersResponse {
    return new EstimateCustomersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateCustomersResponse | PlainMessage<EstimateCustomersResponse> | undefined, b: EstimateCustomersResponse | PlainMessage<EstimateCustomersResponse> | undefined): boolean {
    return proto3.util.equals(EstimateCustomersResponse, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateCustomersResponse.Customer
 */
export class EstimateCustomersResponse_Customer extends Message<EstimateCustomersResponse_Customer> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string firstName = 3;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 4;
   */
  lastName = "";

  /**
   * @generated from field: int64 recency = 5;
   */
  recency = protoInt64.zero;

  /**
   * @generated from field: int64 frequency = 6;
   */
  frequency = protoInt64.zero;

  /**
   * @generated from field: double monetary = 7;
   */
  monetary = 0;

  constructor(data?: PartialMessage<EstimateCustomersResponse_Customer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateCustomersResponse.Customer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "recency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "frequency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "monetary", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateCustomersResponse_Customer {
    return new EstimateCustomersResponse_Customer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateCustomersResponse_Customer {
    return new EstimateCustomersResponse_Customer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateCustomersResponse_Customer {
    return new EstimateCustomersResponse_Customer().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateCustomersResponse_Customer | PlainMessage<EstimateCustomersResponse_Customer> | undefined, b: EstimateCustomersResponse_Customer | PlainMessage<EstimateCustomersResponse_Customer> | undefined): boolean {
    return proto3.util.equals(EstimateCustomersResponse_Customer, a, b);
  }
}

/**
 * EstimateMonthlyCohort
 *
 * @generated from message customer_segment.EstimateMonthlyCohortRequest
 */
export class EstimateMonthlyCohortRequest extends Message<EstimateMonthlyCohortRequest> {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet = "";

  /**
   * YYYY-MM
   *
   * @generated from field: string startYearMonth = 2;
   */
  startYearMonth = "";

  /**
   * YYYY-MM
   *
   * @generated from field: string endYearMonth = 3;
   */
  endYearMonth = "";

  constructor(data?: PartialMessage<EstimateMonthlyCohortRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateMonthlyCohortRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "querySet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startYearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "endYearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateMonthlyCohortRequest {
    return new EstimateMonthlyCohortRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortRequest {
    return new EstimateMonthlyCohortRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortRequest {
    return new EstimateMonthlyCohortRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateMonthlyCohortRequest | PlainMessage<EstimateMonthlyCohortRequest> | undefined, b: EstimateMonthlyCohortRequest | PlainMessage<EstimateMonthlyCohortRequest> | undefined): boolean {
    return proto3.util.equals(EstimateMonthlyCohortRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateMonthlyCohortResponse
 */
export class EstimateMonthlyCohortResponse extends Message<EstimateMonthlyCohortResponse> {
  /**
   * @generated from field: repeated customer_segment.EstimateMonthlyCohortResponse.MonthlyResult monthlyResult = 1;
   */
  monthlyResult: EstimateMonthlyCohortResponse_MonthlyResult[] = [];

  /**
   * @generated from field: repeated customer_segment.EstimateMonthlyCohortResponse.StepAndMetrics avgResult = 2;
   */
  avgResult: EstimateMonthlyCohortResponse_StepAndMetrics[] = [];

  constructor(data?: PartialMessage<EstimateMonthlyCohortResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateMonthlyCohortResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monthlyResult", kind: "message", T: EstimateMonthlyCohortResponse_MonthlyResult, repeated: true },
    { no: 2, name: "avgResult", kind: "message", T: EstimateMonthlyCohortResponse_StepAndMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateMonthlyCohortResponse {
    return new EstimateMonthlyCohortResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortResponse {
    return new EstimateMonthlyCohortResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortResponse {
    return new EstimateMonthlyCohortResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateMonthlyCohortResponse | PlainMessage<EstimateMonthlyCohortResponse> | undefined, b: EstimateMonthlyCohortResponse | PlainMessage<EstimateMonthlyCohortResponse> | undefined): boolean {
    return proto3.util.equals(EstimateMonthlyCohortResponse, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateMonthlyCohortResponse.MonthlyResult
 */
export class EstimateMonthlyCohortResponse_MonthlyResult extends Message<EstimateMonthlyCohortResponse_MonthlyResult> {
  /**
   * YYYY-MM
   *
   * @generated from field: string firstOrderYearMonth = 1;
   */
  firstOrderYearMonth = "";

  /**
   * @generated from field: repeated customer_segment.EstimateMonthlyCohortResponse.StepAndMetrics stepAndMetricsList = 2;
   */
  stepAndMetricsList: EstimateMonthlyCohortResponse_StepAndMetrics[] = [];

  constructor(data?: PartialMessage<EstimateMonthlyCohortResponse_MonthlyResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateMonthlyCohortResponse.MonthlyResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstOrderYearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stepAndMetricsList", kind: "message", T: EstimateMonthlyCohortResponse_StepAndMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateMonthlyCohortResponse_MonthlyResult {
    return new EstimateMonthlyCohortResponse_MonthlyResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortResponse_MonthlyResult {
    return new EstimateMonthlyCohortResponse_MonthlyResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortResponse_MonthlyResult {
    return new EstimateMonthlyCohortResponse_MonthlyResult().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateMonthlyCohortResponse_MonthlyResult | PlainMessage<EstimateMonthlyCohortResponse_MonthlyResult> | undefined, b: EstimateMonthlyCohortResponse_MonthlyResult | PlainMessage<EstimateMonthlyCohortResponse_MonthlyResult> | undefined): boolean {
    return proto3.util.equals(EstimateMonthlyCohortResponse_MonthlyResult, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateMonthlyCohortResponse.StepAndMetrics
 */
export class EstimateMonthlyCohortResponse_StepAndMetrics extends Message<EstimateMonthlyCohortResponse_StepAndMetrics> {
  /**
   * @generated from field: int64 step = 1;
   */
  step = protoInt64.zero;

  /**
   * @generated from field: double ltv = 2;
   */
  ltv = 0;

  /**
   * @generated from field: double orderValue = 3;
   */
  orderValue = 0;

  /**
   * @generated from field: int64 customerCount = 4;
   */
  customerCount = protoInt64.zero;

  /**
   * @generated from field: int64 f2CustomerCount = 5;
   */
  f2CustomerCount = protoInt64.zero;

  /**
   * @generated from field: int64 f3CustomerCount = 6;
   */
  f3CustomerCount = protoInt64.zero;

  /**
   * @generated from field: int64 f4CustomerCount = 7;
   */
  f4CustomerCount = protoInt64.zero;

  /**
   * @generated from field: int64 f5CustomerCount = 8;
   */
  f5CustomerCount = protoInt64.zero;

  constructor(data?: PartialMessage<EstimateMonthlyCohortResponse_StepAndMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateMonthlyCohortResponse.StepAndMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "step", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "ltv", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "orderValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "f2CustomerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "f3CustomerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "f4CustomerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "f5CustomerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateMonthlyCohortResponse_StepAndMetrics {
    return new EstimateMonthlyCohortResponse_StepAndMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortResponse_StepAndMetrics {
    return new EstimateMonthlyCohortResponse_StepAndMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateMonthlyCohortResponse_StepAndMetrics {
    return new EstimateMonthlyCohortResponse_StepAndMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateMonthlyCohortResponse_StepAndMetrics | PlainMessage<EstimateMonthlyCohortResponse_StepAndMetrics> | undefined, b: EstimateMonthlyCohortResponse_StepAndMetrics | PlainMessage<EstimateMonthlyCohortResponse_StepAndMetrics> | undefined): boolean {
    return proto3.util.equals(EstimateMonthlyCohortResponse_StepAndMetrics, a, b);
  }
}

/**
 * EstimateNthOrderCohort
 *
 * @generated from message customer_segment.EstimateNthOrderCohortRequest
 */
export class EstimateNthOrderCohortRequest extends Message<EstimateNthOrderCohortRequest> {
  /**
   * @generated from field: string querySet = 1;
   */
  querySet = "";

  /**
   * YYYY-MM
   *
   * @generated from field: string startYearMonth = 2;
   */
  startYearMonth = "";

  /**
   * YYYY-MM
   *
   * @generated from field: string endYearMonth = 3;
   */
  endYearMonth = "";

  constructor(data?: PartialMessage<EstimateNthOrderCohortRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateNthOrderCohortRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "querySet", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "startYearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "endYearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateNthOrderCohortRequest {
    return new EstimateNthOrderCohortRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortRequest {
    return new EstimateNthOrderCohortRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortRequest {
    return new EstimateNthOrderCohortRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateNthOrderCohortRequest | PlainMessage<EstimateNthOrderCohortRequest> | undefined, b: EstimateNthOrderCohortRequest | PlainMessage<EstimateNthOrderCohortRequest> | undefined): boolean {
    return proto3.util.equals(EstimateNthOrderCohortRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateNthOrderCohortResponse
 */
export class EstimateNthOrderCohortResponse extends Message<EstimateNthOrderCohortResponse> {
  /**
   * @generated from field: repeated customer_segment.EstimateNthOrderCohortResponse.NthResult nthResult = 1;
   */
  nthResult: EstimateNthOrderCohortResponse_NthResult[] = [];

  /**
   * @generated from field: repeated customer_segment.EstimateNthOrderCohortResponse.StepAndMetrics avgResult = 2;
   */
  avgResult: EstimateNthOrderCohortResponse_StepAndMetrics[] = [];

  constructor(data?: PartialMessage<EstimateNthOrderCohortResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateNthOrderCohortResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nthResult", kind: "message", T: EstimateNthOrderCohortResponse_NthResult, repeated: true },
    { no: 2, name: "avgResult", kind: "message", T: EstimateNthOrderCohortResponse_StepAndMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateNthOrderCohortResponse {
    return new EstimateNthOrderCohortResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortResponse {
    return new EstimateNthOrderCohortResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortResponse {
    return new EstimateNthOrderCohortResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateNthOrderCohortResponse | PlainMessage<EstimateNthOrderCohortResponse> | undefined, b: EstimateNthOrderCohortResponse | PlainMessage<EstimateNthOrderCohortResponse> | undefined): boolean {
    return proto3.util.equals(EstimateNthOrderCohortResponse, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateNthOrderCohortResponse.NthResult
 */
export class EstimateNthOrderCohortResponse_NthResult extends Message<EstimateNthOrderCohortResponse_NthResult> {
  /**
   * YYYY-MM
   *
   * @generated from field: string firstOrderYearMonth = 1;
   */
  firstOrderYearMonth = "";

  /**
   * @generated from field: repeated customer_segment.EstimateNthOrderCohortResponse.StepAndMetrics stepAndMetricsList = 2;
   */
  stepAndMetricsList: EstimateNthOrderCohortResponse_StepAndMetrics[] = [];

  constructor(data?: PartialMessage<EstimateNthOrderCohortResponse_NthResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateNthOrderCohortResponse.NthResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "firstOrderYearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stepAndMetricsList", kind: "message", T: EstimateNthOrderCohortResponse_StepAndMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateNthOrderCohortResponse_NthResult {
    return new EstimateNthOrderCohortResponse_NthResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortResponse_NthResult {
    return new EstimateNthOrderCohortResponse_NthResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortResponse_NthResult {
    return new EstimateNthOrderCohortResponse_NthResult().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateNthOrderCohortResponse_NthResult | PlainMessage<EstimateNthOrderCohortResponse_NthResult> | undefined, b: EstimateNthOrderCohortResponse_NthResult | PlainMessage<EstimateNthOrderCohortResponse_NthResult> | undefined): boolean {
    return proto3.util.equals(EstimateNthOrderCohortResponse_NthResult, a, b);
  }
}

/**
 * @generated from message customer_segment.EstimateNthOrderCohortResponse.StepAndMetrics
 */
export class EstimateNthOrderCohortResponse_StepAndMetrics extends Message<EstimateNthOrderCohortResponse_StepAndMetrics> {
  /**
   * @generated from field: int64 step = 1;
   */
  step = protoInt64.zero;

  /**
   * @generated from field: int64 customerCount = 2;
   */
  customerCount = protoInt64.zero;

  /**
   * @generated from field: double avgOrderValue = 3;
   */
  avgOrderValue = 0;

  /**
   * @generated from field: double repeatRate = 4;
   */
  repeatRate = 0;

  /**
   * When the step is 1, use -1 as a value to indicate that there is no data.
   *
   * @generated from field: double avgDaysBetweenOrders = 5;
   */
  avgDaysBetweenOrders = 0;

  constructor(data?: PartialMessage<EstimateNthOrderCohortResponse_StepAndMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.EstimateNthOrderCohortResponse.StepAndMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "step", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "avgOrderValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "repeatRate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "avgDaysBetweenOrders", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateNthOrderCohortResponse_StepAndMetrics {
    return new EstimateNthOrderCohortResponse_StepAndMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortResponse_StepAndMetrics {
    return new EstimateNthOrderCohortResponse_StepAndMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateNthOrderCohortResponse_StepAndMetrics {
    return new EstimateNthOrderCohortResponse_StepAndMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateNthOrderCohortResponse_StepAndMetrics | PlainMessage<EstimateNthOrderCohortResponse_StepAndMetrics> | undefined, b: EstimateNthOrderCohortResponse_StepAndMetrics | PlainMessage<EstimateNthOrderCohortResponse_StepAndMetrics> | undefined): boolean {
    return proto3.util.equals(EstimateNthOrderCohortResponse_StepAndMetrics, a, b);
  }
}

/**
 * UpdateTagOperationSetting
 *
 * @generated from message customer_segment.UpdateTagOperationSettingRequest
 */
export class UpdateTagOperationSettingRequest extends Message<UpdateTagOperationSettingRequest> {
  /**
   * @generated from field: string customerSegmentId = 1;
   */
  customerSegmentId = "";

  /**
   * @generated from field: bool isActive = 2;
   */
  isActive = false;

  /**
   * @generated from field: string tagName = 3;
   */
  tagName = "";

  /**
   * @generated from field: bool enableShopifySegmentCreation = 4;
   */
  enableShopifySegmentCreation = false;

  constructor(data?: PartialMessage<UpdateTagOperationSettingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.UpdateTagOperationSettingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "isActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "tagName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "enableShopifySegmentCreation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTagOperationSettingRequest {
    return new UpdateTagOperationSettingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTagOperationSettingRequest {
    return new UpdateTagOperationSettingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTagOperationSettingRequest {
    return new UpdateTagOperationSettingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTagOperationSettingRequest | PlainMessage<UpdateTagOperationSettingRequest> | undefined, b: UpdateTagOperationSettingRequest | PlainMessage<UpdateTagOperationSettingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTagOperationSettingRequest, a, b);
  }
}

/**
 * @generated from message customer_segment.UpdateTagOperationSettingResponse
 */
export class UpdateTagOperationSettingResponse extends Message<UpdateTagOperationSettingResponse> {
  constructor(data?: PartialMessage<UpdateTagOperationSettingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer_segment.UpdateTagOperationSettingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTagOperationSettingResponse {
    return new UpdateTagOperationSettingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTagOperationSettingResponse {
    return new UpdateTagOperationSettingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTagOperationSettingResponse {
    return new UpdateTagOperationSettingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTagOperationSettingResponse | PlainMessage<UpdateTagOperationSettingResponse> | undefined, b: UpdateTagOperationSettingResponse | PlainMessage<UpdateTagOperationSettingResponse> | undefined): boolean {
    return proto3.util.equals(UpdateTagOperationSettingResponse, a, b);
  }
}

