// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/customer/customer.proto (package customer, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, protoInt64} from "@bufbuild/protobuf";

/**
 * Get
 *
 * @generated from message customer.GetRequest
 */
export class GetRequest extends Message<GetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer.GetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRequest {
    return new GetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRequest | PlainMessage<GetRequest> | undefined, b: GetRequest | PlainMessage<GetRequest> | undefined): boolean {
    return proto3.util.equals(GetRequest, a, b);
  }
}

/**
 * @generated from message customer.GetResponse
 */
export class GetResponse extends Message<GetResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string firstName = 3;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 4;
   */
  lastName = "";

  /**
   * @generated from field: string country = 5;
   */
  country = "";

  /**
   * @generated from field: string province = 6;
   */
  province = "";

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = [];

  /**
   * @generated from field: int64 recency = 8;
   */
  recency = protoInt64.zero;

  /**
   * @generated from field: int64 frequency = 9;
   */
  frequency = protoInt64.zero;

  /**
   * @generated from field: double monetary = 10;
   */
  monetary = 0;

  constructor(data?: PartialMessage<GetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer.GetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "recency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "frequency", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "monetary", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponse {
    return new GetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponse | PlainMessage<GetResponse> | undefined, b: GetResponse | PlainMessage<GetResponse> | undefined): boolean {
    return proto3.util.equals(GetResponse, a, b);
  }
}

/**
 * GetOrders
 *
 * @generated from message customer.GetOrdersRequest
 */
export class GetOrdersRequest extends Message<GetOrdersRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetOrdersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer.GetOrdersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersRequest {
    return new GetOrdersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined, b: GetOrdersRequest | PlainMessage<GetOrdersRequest> | undefined): boolean {
    return proto3.util.equals(GetOrdersRequest, a, b);
  }
}

/**
 * @generated from message customer.GetOrdersResponse
 */
export class GetOrdersResponse extends Message<GetOrdersResponse> {
  /**
   * @generated from field: repeated customer.GetOrdersResponse.Order orders = 1;
   */
  orders: GetOrdersResponse_Order[] = [];

  constructor(data?: PartialMessage<GetOrdersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer.GetOrdersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "orders", kind: "message", T: GetOrdersResponse_Order, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponse {
    return new GetOrdersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined, b: GetOrdersResponse | PlainMessage<GetOrdersResponse> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponse, a, b);
  }
}

/**
 * @generated from message customer.GetOrdersResponse.Order
 */
export class GetOrdersResponse_Order extends Message<GetOrdersResponse_Order> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: double orderValue = 3;
   */
  orderValue = 0;

  /**
   * format: "2006-01-02 15:04:05"
   *
   * @generated from field: string orderedAt = 4;
   */
  orderedAt = "";

  constructor(data?: PartialMessage<GetOrdersResponse_Order>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "customer.GetOrdersResponse.Order";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "orderValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "orderedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrdersResponse_Order {
    return new GetOrdersResponse_Order().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrdersResponse_Order {
    return new GetOrdersResponse_Order().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrdersResponse_Order {
    return new GetOrdersResponse_Order().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrdersResponse_Order | PlainMessage<GetOrdersResponse_Order> | undefined, b: GetOrdersResponse_Order | PlainMessage<GetOrdersResponse_Order> | undefined): boolean {
    return proto3.util.equals(GetOrdersResponse_Order, a, b);
  }
}

