import { Box, Switch, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { defaultFiltersByDimension, filterTypesBoolMapByDimension } from '../defalutValues'
import { OP_NONE } from '../schema/const'
import { FilterItemFormState, QuerySetFormState } from '../schema/querySetFormSchema'
import { OrderCountFilter } from './orderCountFilter'
import { OrderDateFilter } from './orderDateFilter'
import { OrderSequenceFilter } from './orderSequenceFilter'
import { useTranslation } from 'react-i18next'

type Props = {
  queryItemPath: `query_subsets.${number}.query_items.${number}`
  open: boolean
}

export const FilterItems: FC<Props> = ({ queryItemPath, open: _open }) => {
  const { t } = useTranslation()
  const { getValues, watch, setValue } = useFormContext<QuerySetFormState>()
  const dimension = watch(`${queryItemPath}.dimension`)
  const filters = getValues(`${queryItemPath}.filters`)
  const filterTypesBoolMap = filterTypesBoolMapByDimension[dimension]

  const filterOrderDate = filterTypesBoolMap['order_date']
  const filterOrderSequence = filterTypesBoolMap['order_sequence']
  const filterOrderCount = filterTypesBoolMap['order_count']

  const isFilterDefaultOpen = filters.some((f) => f.operator !== OP_NONE)
  const [open, setOpen] = useState(isFilterDefaultOpen)

  const pathIndexByFilterType = filters.reduce<{ [key in FilterItemFormState['filter_type']]: number }>((acc, _v, i) => {
    return { ...acc, [_v.filter_type]: i }
  }, {} as { [key in FilterItemFormState['filter_type']]: number })

  const generateFilterPath = (count: number): `query_subsets.${number}.query_items.${number}.filters.${number}` => {
    return `${queryItemPath}.filters.${count}`
  }

  const toggleFilters = () => {
    if (open === true) {
      // Reset form values to default when closing the filter
      setValue(`${queryItemPath}.filters`, defaultFiltersByDimension[dimension])
    }
    setOpen(!open)
  }

  return (
    <>
      {(filterOrderDate || filterOrderSequence || filterOrderCount) && (
        <Box display='flex' alignItems='center' sx={{ margin: '12px 0px' }}>
          <Typography sx={{ fontSize: 'small', marginRight: '6px' }}>{t('features.customerSegments.querySet.querySetForm.filters')}</Typography>
          <Switch size='small' checked={open} onClick={toggleFilters} />
        </Box>
      )}

      {open && (
        <Box sx={{ marginLeft: '32px', paddingLeft: '24px', borderLeft: '3px solid lightgray' }}>
          {filterOrderDate && (
            <Box mb='8px'>
              <OrderDateFilter filterPath={generateFilterPath(pathIndexByFilterType['order_date'])} />
            </Box>
          )}
          {filterOrderSequence && (
            <Box mb='8px'>
              <OrderSequenceFilter filterPath={generateFilterPath(pathIndexByFilterType['order_sequence'])} />
            </Box>
          )}
          {filterOrderCount && (
            <Box>
              <OrderCountFilter filterPath={generateFilterPath(pathIndexByFilterType['order_count'])} />
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
