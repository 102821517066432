import { getIdToken } from '@firebase/auth'
import dayjs from 'dayjs'
import { CustomerSegmentState, OrderState } from 'features/customerSegments/types/types'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'utils/timeUtil'

export const useOrders = (customerSegment: CustomerSegmentState | undefined) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const [orders, setOrders] = useState<OrderState[]>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!customerSegment) return
    if (customerSegment.metrics.count === 0) {
      setOrders([])
      return
    }

    const handleFetch = async () => {
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.getOrders(
          { customerSegmentId: customerSegment.id },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const orders = resp.orders.map((o) => {
          return {
            id: o.id,
            name: o.name,
            orderAmount: Number(o.amount),
            orderedAt: formatDateTime(dayjs(o.orderedAt), i18n.language),
            customerId: o.customerId,
            customerName: t('common.customer.fullName', { firstName: o.firstName, lastName: o.lastName }),
            nthOrder: Number(o.nthOrder),
            referrer: o.referrer,
            landingPage: o.landingPage,
            channel: o.channel,
            coupon: o.coupon,
            utmSource: o.utmSource,
            utmMedium: o.utmMedium,
            utmCampaign: o.utmCampaign,
            tags: o.tags,
          }
        })
        setOrders(orders)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegment?.id])

  return { orders, loading }
}
