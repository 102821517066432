// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/billing/billing.proto (package billing, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {StartChangePlanFlowRequest, StartChangePlanFlowResponse, SyncActivePlanFromShopifyRequest, SyncActivePlanFromShopifyResponse} from "./billing_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service billing.BillingService
 */
export const BillingService = {
  typeName: "billing.BillingService",
  methods: {
    /**
     * @generated from rpc billing.BillingService.StartChangePlanFlow
     */
    startChangePlanFlow: {
      name: "StartChangePlanFlow",
      I: StartChangePlanFlowRequest,
      O: StartChangePlanFlowResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc billing.BillingService.SyncActivePlanFromShopify
     */
    syncActivePlanFromShopify: {
      name: "SyncActivePlanFromShopify",
      I: SyncActivePlanFromShopifyRequest,
      O: SyncActivePlanFromShopifyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

