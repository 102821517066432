// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/billing/billing.proto (package billing, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * StartChangePlanFlow
 *
 * @generated from message billing.StartChangePlanFlowRequest
 */
export class StartChangePlanFlowRequest extends Message<StartChangePlanFlowRequest> {
  /**
   * free, starter, growth, scale
   *
   * @generated from field: string plan = 1;
   */
  plan = "";

  /**
   * where to redirect after the flow is done
   *
   * @generated from field: string returnURL = 2;
   */
  returnURL = "";

  constructor(data?: PartialMessage<StartChangePlanFlowRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "billing.StartChangePlanFlowRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "plan", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "returnURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartChangePlanFlowRequest {
    return new StartChangePlanFlowRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartChangePlanFlowRequest {
    return new StartChangePlanFlowRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartChangePlanFlowRequest {
    return new StartChangePlanFlowRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartChangePlanFlowRequest | PlainMessage<StartChangePlanFlowRequest> | undefined, b: StartChangePlanFlowRequest | PlainMessage<StartChangePlanFlowRequest> | undefined): boolean {
    return proto3.util.equals(StartChangePlanFlowRequest, a, b);
  }
}

/**
 * @generated from message billing.StartChangePlanFlowResponse
 */
export class StartChangePlanFlowResponse extends Message<StartChangePlanFlowResponse> {
  /**
   * confirmURL is the URL issued by Shopify, required to complete the payment.
   * If changing to the free plan, authentication is not needed, therefore an empty string is returned.
   *
   * @generated from field: string confirmURL = 1;
   */
  confirmURL = "";

  constructor(data?: PartialMessage<StartChangePlanFlowResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "billing.StartChangePlanFlowResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "confirmURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartChangePlanFlowResponse {
    return new StartChangePlanFlowResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartChangePlanFlowResponse {
    return new StartChangePlanFlowResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartChangePlanFlowResponse {
    return new StartChangePlanFlowResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartChangePlanFlowResponse | PlainMessage<StartChangePlanFlowResponse> | undefined, b: StartChangePlanFlowResponse | PlainMessage<StartChangePlanFlowResponse> | undefined): boolean {
    return proto3.util.equals(StartChangePlanFlowResponse, a, b);
  }
}

/**
 * SyncActivePlanFromShopify
 *
 * @generated from message billing.SyncActivePlanFromShopifyRequest
 */
export class SyncActivePlanFromShopifyRequest extends Message<SyncActivePlanFromShopifyRequest> {
  constructor(data?: PartialMessage<SyncActivePlanFromShopifyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "billing.SyncActivePlanFromShopifyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncActivePlanFromShopifyRequest {
    return new SyncActivePlanFromShopifyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncActivePlanFromShopifyRequest {
    return new SyncActivePlanFromShopifyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncActivePlanFromShopifyRequest {
    return new SyncActivePlanFromShopifyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SyncActivePlanFromShopifyRequest | PlainMessage<SyncActivePlanFromShopifyRequest> | undefined, b: SyncActivePlanFromShopifyRequest | PlainMessage<SyncActivePlanFromShopifyRequest> | undefined): boolean {
    return proto3.util.equals(SyncActivePlanFromShopifyRequest, a, b);
  }
}

/**
 * @generated from message billing.SyncActivePlanFromShopifyResponse
 */
export class SyncActivePlanFromShopifyResponse extends Message<SyncActivePlanFromShopifyResponse> {
  constructor(data?: PartialMessage<SyncActivePlanFromShopifyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "billing.SyncActivePlanFromShopifyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncActivePlanFromShopifyResponse {
    return new SyncActivePlanFromShopifyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncActivePlanFromShopifyResponse {
    return new SyncActivePlanFromShopifyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncActivePlanFromShopifyResponse {
    return new SyncActivePlanFromShopifyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SyncActivePlanFromShopifyResponse | PlainMessage<SyncActivePlanFromShopifyResponse> | undefined, b: SyncActivePlanFromShopifyResponse | PlainMessage<SyncActivePlanFromShopifyResponse> | undefined): boolean {
    return proto3.util.equals(SyncActivePlanFromShopifyResponse, a, b);
  }
}

