import { Chip } from '@mui/material'
import { FC } from 'react'

type Props = {
  label: string
  icon: JSX.Element
}

export const DimensionChip: FC<Props> = ({ label, icon }) => {
  return (
    <Chip label={label} icon={icon && icon} variant='outlined' size='small' sx={{ borderRadius: '6px', marginRight: '6px', lineHeight: '1.2' }} />
  )
}
