import { OP_CONTAIN_ANY, OP_NOT_CONTAIN } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useGiftSegments = (): RecipeSegment[] => {
  const { t } = useTranslation()

  const giftOrderTagValue = t('features.recipes.segments.value_giftTag')

  return [
    {
      name: t('features.recipes.segments.name_orderTag_gift'),
      querySet: generateQuerySet([
        {
          dimension: 'order_tag',
          operator: OP_CONTAIN_ANY,
          value: [giftOrderTagValue],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_orderTag_gift_none'),
      querySet: generateQuerySet([
        {
          dimension: 'order_tag',
          operator: OP_NOT_CONTAIN,
          value: [giftOrderTagValue],
          filters: [],
        },
      ]),
    },
  ]
}
