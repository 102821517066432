import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_NONE, OP_ORDER_SEQUENCE_FIRST, OP_ORDER_SEQUENCE_LAST, OP_ORDER_SEQUENCE_SECOND, OP_ORDER_SEQUENCE_SPECIFY } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { FilterItemProps } from './type'

export const OrderSequenceFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t, i18n } = useTranslation()
  const { register, control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  return (
    <Box display='flex'>
      <Box fontSize='14px' marginRight={i18n.language === 'en' ? '28px' : '12px'} paddingTop='10px'>
        {t('features.customerSegments.querySet.filterType', { context: 'order_sequence' })}
      </Box>
      <input type='hidden' value='order_sequence' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px', marginRight: '12px' }}>
              <Select defaultValue={OP_NONE} error={Boolean(error)} value={field.value} onChange={field.onChange} size='small'>
                <MenuItem key={OP_NONE} value={OP_NONE}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_NONE })}
                </MenuItem>
                <MenuItem key={OP_ORDER_SEQUENCE_FIRST} value={OP_ORDER_SEQUENCE_FIRST}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_FIRST })}
                </MenuItem>
                <MenuItem key={OP_ORDER_SEQUENCE_SECOND} value={OP_ORDER_SEQUENCE_SECOND}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_SECOND })}
                </MenuItem>
                <MenuItem key={OP_ORDER_SEQUENCE_LAST} value={OP_ORDER_SEQUENCE_LAST}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_LAST })}
                </MenuItem>
                <MenuItem key={OP_ORDER_SEQUENCE_SPECIFY} value={OP_ORDER_SEQUENCE_SPECIFY}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_SPECIFY })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />
      {operator === 'specify_sequence' && (
        <IntRangeValue
          minValuePath={`${filterPath}.value.min_sequence`}
          maxValuePath={`${filterPath}.value.max_sequence`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixSequence')}
          inputPropsMaxValue={99}
        />
      )}
    </Box>
  )
}
