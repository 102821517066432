import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_DATE_RANGE, OP_RELATIVE_DATE_RANGE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { DateRangeValue } from '../value/dateRangeValue'
import { RelativeDateRangeValue } from '../value/relativeDateRangeValue'
import { QueryItemProps } from './type'

export const OrderDateQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${queryItemPath}.operator`)

  return (
    <Box display='flex'>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px', marginRight: '12px' }}>
              <Select error={Boolean(error)} defaultValue={OP_DATE_RANGE} value={field.value} onChange={field.onChange} size='small'>
                <MenuItem key={OP_DATE_RANGE} value={OP_DATE_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_DATE_RANGE })}
                </MenuItem>
                <MenuItem key={OP_RELATIVE_DATE_RANGE} value={OP_RELATIVE_DATE_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_RELATIVE_DATE_RANGE })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />

      {operator === OP_DATE_RANGE && (
        <DateRangeValue minDateValuePath={`${queryItemPath}.value.min_date`} maxDateValuePath={`${queryItemPath}.value.max_date`} />
      )}

      {operator === OP_RELATIVE_DATE_RANGE && (
        <RelativeDateRangeValue
          minValuePath={`${queryItemPath}.value.min`}
          maxValuePath={`${queryItemPath}.value.max`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixDateRange')}
          inputPropsMaxValue={999}
        />
      )}
    </Box>
  )
}
