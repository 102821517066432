import { z } from 'zod'
import {
  OP_CONTAIN_ALL,
  OP_CONTAIN_ANY,
  OP_DATE_RANGE,
  OP_EQUAL,
  OP_INT_RANGE,
  OP_NONE,
  OP_NOT_CONTAIN,
  OP_NOT_EQUAL,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_LAST,
  OP_ORDER_SEQUENCE_SECOND,
  OP_ORDER_SEQUENCE_SPECIFY,
  OP_RELATIVE_DATE_RANGE,
} from './const'
import { dateRangeValue, intRangeValue, orderSequenceValue } from './querySetApiSchema'

/* filter form */
const orderDateFilter = z.union([
  z.object({
    filter_type: z.literal('order_date'),
    operator: z.literal(OP_NONE),
    value: z.undefined(),
  }),
  z.object({
    filter_type: z.literal('order_date'),
    operator: z.literal(OP_RELATIVE_DATE_RANGE),
    value: intRangeValue,
  }),
  z.object({
    filter_type: z.literal('order_date'),
    operator: z.literal(OP_DATE_RANGE),
    value: dateRangeValue,
  }),
])

const orderCountFilter = z.union([
  z.object({
    filter_type: z.literal('order_count'),
    operator: z.literal(OP_NONE),
    value: z.undefined(),
  }),
  z.object({
    filter_type: z.literal('order_count'),
    operator: z.literal(OP_INT_RANGE),
    value: intRangeValue,
  }),
])

const orderSequenceFilter = z.union([
  z.object({
    filter_type: z.literal('order_sequence'),
    operator: z.union([
      z.literal(OP_NONE),
      z.literal(OP_ORDER_SEQUENCE_FIRST),
      z.literal(OP_ORDER_SEQUENCE_SECOND),
      z.literal(OP_ORDER_SEQUENCE_LAST),
    ]),
    value: z.undefined(),
  }),
  z.object({
    filter_type: z.literal('order_sequence'),
    operator: z.literal(OP_ORDER_SEQUENCE_SPECIFY),
    value: orderSequenceValue,
  }),
])

const filterItem = z.union([orderDateFilter, orderCountFilter, orderSequenceFilter])

/* each query form */
const productQuery = z.object({
  dimension: z.literal('product'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const productVariantQuery = z.object({
  dimension: z.literal('product_variant'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const productTypeQuery = z.object({
  dimension: z.literal('product_type'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const productTagQuery = z.object({
  dimension: z.literal('product_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const productVendorQuery = z.object({
  dimension: z.literal('product_vendor'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const orderTagQuery = z.object({
  dimension: z.literal('order_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const couponQuery = z.object({
  dimension: z.literal('coupon'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const orderDateQuery = z.union([
  z.object({
    dimension: z.literal('order_date'),
    operator: z.literal(OP_DATE_RANGE),
    value: dateRangeValue,
    filters: filterItem.array(),
  }),
  z.object({
    dimension: z.literal('order_date'),
    operator: z.literal(OP_RELATIVE_DATE_RANGE),
    value: intRangeValue,
    filters: filterItem.array(),
  }),
])

const channelQuery = z.object({
  dimension: z.literal('channel'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const orderValueQuery = z.object({
  dimension: z.literal('order_value'),
  operator: z.literal(OP_INT_RANGE),
  value: intRangeValue,
  filters: filterItem.array(),
})

const referrerQuery = z.object({
  dimension: z.literal('referrer'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const landingPageQuery = z.object({
  dimension: z.literal('landing_page'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const utmSourceQuery = z.object({
  dimension: z.literal('utm_source'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const utmMediumQuery = z.object({
  dimension: z.literal('utm_medium'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const utmCampaignQuery = z.object({
  dimension: z.literal('utm_campaign'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const countryQuery = z.object({
  dimension: z.literal('country'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const prefectureQuery = z.object({
  dimension: z.literal('prefecture'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const customerTagQuery = z.object({
  dimension: z.literal('customer_tag'),
  operator: z.union([z.literal(OP_CONTAIN_ALL), z.literal(OP_CONTAIN_ANY), z.literal(OP_NOT_CONTAIN)]),
  value: z.string().array(),
  filters: filterItem.array(),
})

const recencyQuery = z.object({
  dimension: z.literal('recency'),
  operator: z.literal(OP_INT_RANGE),
  value: intRangeValue,
  filters: filterItem.array(),
})

const frequencyQuery = z.union([
  z.object({
    dimension: z.literal('frequency'),
    operator: z.literal(OP_INT_RANGE),
    value: intRangeValue,
    filters: filterItem.array(),
  }),
  z.object({
    dimension: z.literal('frequency'),
    operator: z.union([z.literal(OP_EQUAL), z.literal(OP_NOT_EQUAL)]),
    value: z.number(),
    filters: filterItem.array(),
  }),
])

const monetaryQuery = z.object({
  dimension: z.literal('monetary'),
  operator: z.literal(OP_INT_RANGE),
  value: intRangeValue,
  filters: filterItem.array(),
})

const aovQuery = z.object({
  dimension: z.literal('aov'),
  operator: z.literal(OP_INT_RANGE),
  value: intRangeValue,
  filters: filterItem.array(),
})

const purchaseIntervalQuery = z.object({
  dimension: z.literal('purchase_interval'),
  operator: z.literal(OP_INT_RANGE),
  value: intRangeValue,
  filters: filterItem.array(),
})

/* query item form */
const queryItem = z.union([
  productQuery,
  productVariantQuery,
  productTypeQuery,
  productTagQuery,
  productVendorQuery,
  orderTagQuery,
  couponQuery,
  orderDateQuery,
  channelQuery,
  orderValueQuery,
  referrerQuery,
  landingPageQuery,
  utmSourceQuery,
  utmMediumQuery,
  utmCampaignQuery,
  countryQuery,
  prefectureQuery,
  customerTagQuery,
  recencyQuery,
  frequencyQuery,
  monetaryQuery,
  aovQuery,
  purchaseIntervalQuery,
])

/* query subset form */
export const querySubset = z.object({
  join_operator: z.union([z.literal('and'), z.literal('or')]),
  query_items: queryItem.array(),
})

/* query set form */
export const querySet = z.object({
  join_operator: z.union([z.literal('and'), z.literal('or')]),
  query_subsets: querySubset.array(),
})

export type FilterItemFormState = z.infer<typeof filterItem>
export type QuerySetFormState = z.infer<typeof querySet>
export type QuerySubsetFormState = z.infer<typeof querySubset>
export type QueryItemFormState = z.infer<typeof queryItem>
