// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/customer/customer.proto (package customer, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetOrdersRequest, GetOrdersResponse, GetRequest, GetResponse} from "./customer_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service customer.CustomerService
 */
export const CustomerService = {
  typeName: "customer.CustomerService",
  methods: {
    /**
     * @generated from rpc customer.CustomerService.Get
     */
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc customer.CustomerService.GetOrders
     */
    getOrders: {
      name: "GetOrders",
      I: GetOrdersRequest,
      O: GetOrdersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

