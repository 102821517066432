import { env } from 'config/env'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    load: 'languageOnly',
    fallbackLng: 'en',
    supportedLngs: ['en', 'ja'],
    nonExplicitSupportedLngs: true,
    debug: env.DEBUG_LOG,
    interpolation: {
      escapeValue: false,
    },
  })

i18n.on('languageChanged', (lng) => {
  if (lng.startsWith('en-')) {
    i18n.changeLanguage('en')
  }
})

export default i18n
