import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Avatar, Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { useActionTracker } from 'hooks/useMixpanel'
import { useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'

const integrationsAll = ['shopifyemail', 'klaviyo', 'mailchimp', 'omnisend', 'uppromote', 'pushowl', 'privy', 'asklayer', 'channeltalk']
const integrationsJa = ['appify', 'vip', 'omnihub', 'crmplusonline']

export const Integrations = () => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()

  const integrations = i18n.language === 'ja' ? [...integrationsAll, ...integrationsJa] : integrationsAll

  return (
    <>
      <PageHeader title={t('features.integrations.title')} marginBottom='24px' />

      <TableContainer>
        <Table>
          <TableBody>
            {integrations.map((v) => (
              <TableRow key={v}>
                <TableCell width='60px'>
                  <Avatar variant='rounded' alt={v} src={getImagePath(`integrations/${v}.png`)} />
                </TableCell>
                <TableCell>
                  <Typography fontSize='14px' gutterBottom>
                    {t('features.integrations.name', { context: v })}
                  </Typography>
                  <Typography fontSize='13px' color='text.secondary'>
                    {t('features.integrations.description', { context: v })}
                  </Typography>
                </TableCell>
                <TableCell align='right' width='200px'>
                  <Button
                    size='small'
                    variant='outlined'
                    endIcon={<OpenInNewIcon />}
                    onClick={() => {
                      dispatch('ViewGuide', {
                        guideType: t('features.integrations.name', { context: v }),
                        url: t('features.integrations.url', { context: v }),
                      })
                      window.open(t('features.integrations.url', { context: v }), '_blank')
                    }}
                  >
                    {t('features.integrations.view')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
