import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
  handleClose: () => void
  id: string
  name: string
}

export const DeleteDialog: FC<Props> = ({ open, handleClose, id, name }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const segmentGroupService = useGrpcClient(SegmentGroupService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.delete({ segmentGroupId: id }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.group.deleteDialog.messageSaved'), { severity: 'success' })
      handleClose()
      navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.group.deleteDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box padding='20px'>
        <Box marginBottom='20px'>
          <Typography>{t('features.customerSegments.group.deleteDialog.title')}</Typography>
        </Box>
        <Box marginBottom='20px'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('features.customerSegments.group.deleteDialog.description', { name: name })}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Button disabled={isLoading} onClick={handleClose} variant='text' color='inherit' sx={{ marginRight: '4px' }}>
            {t('features.customerSegments.group.deleteDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={isLoading} loadingPosition='center' onClick={handleSubmit} variant='contained'>
            {t('features.customerSegments.group.deleteDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
