import { guideType } from 'config/guide'
import { InsightDimension, InsightReportType } from 'gen/firestore'

export type InsightDimensionProfile = {
  name: InsightDimension
  category: InsightCategory
  reportTypes: InsightReportType[]
  guideType: guideType
}

export enum InsightCategory {
  all = 'all',
  product = 'product',
  source = 'source',
  order = 'order',
  datetime = 'datetime',
  customer = 'customer',
}

export const insightDimensions: InsightDimensionProfile[] = [
  {
    name: InsightDimension.product,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightProduct',
  },
  {
    name: InsightDimension.productType,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightProductType',
  },
  {
    name: InsightDimension.productVendor,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightProductVendor',
  },
  {
    name: InsightDimension.productTag,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightProductTag',
  },
  {
    name: InsightDimension.productVariant,
    category: InsightCategory.product,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightProduct',
  },
  {
    name: InsightDimension.referrer,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightReferrer',
  },
  {
    name: InsightDimension.landingPage,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightLandingPage',
  },
  {
    name: InsightDimension.utmSource,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightUtm',
  },
  {
    name: InsightDimension.utmMedium,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightUtm',
  },
  {
    name: InsightDimension.utmCampaign,
    category: InsightCategory.source,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightUtm',
  },
  {
    name: InsightDimension.channel,
    category: InsightCategory.order,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightChannel',
  },
  {
    name: InsightDimension.orderTag,
    category: InsightCategory.order,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightOrderTag',
  },
  {
    name: InsightDimension.coupon,
    category: InsightCategory.order,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightCoupon',
  },
  {
    name: InsightDimension.yearMonth,
    category: InsightCategory.datetime,
    reportTypes: [InsightReportType.popularity, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightYearMonth',
  },
  {
    name: InsightDimension.weekday,
    category: InsightCategory.datetime,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightWeekday',
  },
  {
    name: InsightDimension.hour,
    category: InsightCategory.datetime,
    reportTypes: [InsightReportType.popularity, InsightReportType.monthly, InsightReportType.nthOrder, InsightReportType.orderCount],
    guideType: 'InsightHour',
  },
  {
    name: InsightDimension.intervalDays,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
    guideType: 'InsightIntervalDays',
  },
  {
    name: InsightDimension.lifetimeDays,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
    guideType: 'InsightLifetimeDays',
  },
  {
    name: InsightDimension.aov,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
    guideType: 'InsightAov',
  },
  {
    name: InsightDimension.frequency,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
    guideType: 'InsightRfm',
  },
  {
    name: InsightDimension.recency,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
    guideType: 'InsightRfm',
  },
  {
    name: InsightDimension.monetary,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.histogram],
    guideType: 'InsightRfm',
  },
  {
    name: InsightDimension.country,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.popularity],
    guideType: 'InsightLocation',
  },
  {
    name: InsightDimension.province,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.popularity],
    guideType: 'InsightLocation',
  },
  {
    name: InsightDimension.customerTag,
    category: InsightCategory.customer,
    reportTypes: [InsightReportType.popularity],
    guideType: 'InsightCustomerTag',
  },
]
