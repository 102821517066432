import csvDownload from 'json-to-csv-export'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomSnackbar } from './useCustomSnackbar'
import { useActionTracker } from './useMixpanel'
import { useSentryNotifier } from './useSentryNotifier'

type Props = {
  data: any // any
  headers: string[]
  fileName: string
  page: string
}

export const useCsvDownload = ({ data, headers, fileName, page }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    try {
      setLoading(true)
      csvDownload({
        data: data,
        filename: fileName,
        headers: headers,
        delimiter: ',',
      })
      dispatch('DownloadCSV', { page: page })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return {
    handleDownload,
    loading,
  }
}
