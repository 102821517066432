import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, Divider, IconButton, Tooltip } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  isEditMode: boolean
  onClickAdd: () => void
  onClickDelete: () => void
}

export const NodeContent: FC<Props> = ({ name, isEditMode, onClickAdd, onClickDelete }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        fontSize='14px'
        padding='16px 12px'
        sx={{ cursor: isEditMode ? 'grab' : 'pointer' }}
      >
        {name}
      </Box>

      {isEditMode && (
        <>
          <Divider sx={{ marginBottom: '10px' }} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title={t('features.customerSegments.group.canvasView.delete')}>
              <IconButton onClick={onClickDelete} size='small'>
                <DeleteOutlineIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('features.customerSegments.group.canvasView.addTarget')}>
              <IconButton onClick={onClickAdd} size='small'>
                <AddCircleOutlineIcon fontSize='small' color='primary' />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  )
}
