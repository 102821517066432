import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { t } from 'i18next'
import { FC } from 'react'

export enum ViewType {
  visual = 'visual',
  table = 'table',
}

type Props = {
  viewType: ViewType
  setViewType: (viewType: ViewType) => void
}

export const ViewTypeToggleButton: FC<Props> = ({ viewType, setViewType }) => {
  return (
    <ToggleButtonGroup
      value={viewType}
      size='small'
      exclusive
      onChange={(_, v) => {
        if (v === null) return
        setViewType(v as ViewType)
      }}
      sx={{
        marginRight: '16px',
        '& .MuiToggleButtonGroup-grouped': {
          padding: '4px 12px',
        },
      }}
    >
      {Object.entries(ViewType).map(([key, type]) => (
        <ToggleButton key={key} value={type}>
          {type === ViewType.visual ? (
            <ImageOutlinedIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
          ) : (
            <FormatListBulletedIcon sx={{ fontSize: '16px', marginRight: '4px' }} />
          )}
          {t('features.insights.detail.viewType', { context: type })}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
