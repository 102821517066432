import { NthOrderCohortSummaryType, convertNthOrderCohortSummary } from 'features/cohorts/types/nthOrderCohort'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CohortFilterState } from '../types/cohortFilter'

export const useNthOrderCohort = (cohortFilter: CohortFilterState | undefined) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const [nthOrderCohortSummary, setNthOrderCohortSummary] = useState<NthOrderCohortSummaryType>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!cohortFilter) return
    setLoading(true)
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const querySet = customerSegments?.find((cs) => cs.ref.id === cohortFilter.customerSegmentId)?.querySet || ''
        const resp = await customerSegmentService.estimateNthOrderCohort(
          {
            querySet: querySet,
            startYearMonth: cohortFilter.startYearMonth,
            endYearMonth: cohortFilter.endYearMonth,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setNthOrderCohortSummary(convertNthOrderCohortSummary(resp, i18n.language))
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortFilter])

  return { nthOrderCohortSummary, loading }
}
