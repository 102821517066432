// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/data_loader/data_loader.proto (package gdpr, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * RequestCustomerData
 *
 * @generated from message gdpr.StartLoadFromShopifyRequest
 */
export class StartLoadFromShopifyRequest extends Message<StartLoadFromShopifyRequest> {
  constructor(data?: PartialMessage<StartLoadFromShopifyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "gdpr.StartLoadFromShopifyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartLoadFromShopifyRequest {
    return new StartLoadFromShopifyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartLoadFromShopifyRequest {
    return new StartLoadFromShopifyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartLoadFromShopifyRequest {
    return new StartLoadFromShopifyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartLoadFromShopifyRequest | PlainMessage<StartLoadFromShopifyRequest> | undefined, b: StartLoadFromShopifyRequest | PlainMessage<StartLoadFromShopifyRequest> | undefined): boolean {
    return proto3.util.equals(StartLoadFromShopifyRequest, a, b);
  }
}

/**
 * @generated from message gdpr.StartLoadFromShopifyResponse
 */
export class StartLoadFromShopifyResponse extends Message<StartLoadFromShopifyResponse> {
  constructor(data?: PartialMessage<StartLoadFromShopifyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "gdpr.StartLoadFromShopifyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartLoadFromShopifyResponse {
    return new StartLoadFromShopifyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartLoadFromShopifyResponse {
    return new StartLoadFromShopifyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartLoadFromShopifyResponse {
    return new StartLoadFromShopifyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartLoadFromShopifyResponse | PlainMessage<StartLoadFromShopifyResponse> | undefined, b: StartLoadFromShopifyResponse | PlainMessage<StartLoadFromShopifyResponse> | undefined): boolean {
    return proto3.util.equals(StartLoadFromShopifyResponse, a, b);
  }
}

