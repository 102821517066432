import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  onSubmit: () => void
  email: string
}

export const RemoveMemberDialog: FC<Props> = ({ open, handleClose, onSubmit, email }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const shopService = useGrpcClient(ShopService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await shopService.removeAccount({ email: email }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.members.removeMemberDialog.messageSuccess'), { severity: 'success' })
      onSubmit()
    } catch (err) {
      enqueueSnackbar(t('features.settings.members.removeMemberDialog.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: email })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '18px' }}>{t('features.settings.members.removeMemberDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '14px' }}>
          {t('features.settings.members.removeMemberDialog.description', { email: email })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} variant='text' color='inherit'>
          {t('features.settings.members.removeMemberDialog.cancel')}
        </Button>
        <LoadingButton type='submit' loading={loading} loadingPosition='center' onClick={handleSubmit} variant='contained'>
          {t('features.settings.members.removeMemberDialog.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
