import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OP_DATE_RANGE, OP_RELATIVE_DATE_RANGE } from '../../querySetForm/schema/const'
import { FilterItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { OperatorChip } from '../operatorChip'
import { ValueChip } from '../valueChip'

type Props = {
  filterItem: FilterItemFormState
}

export const OrderDateFilter: FC<Props> = ({ filterItem }) => {
  const { t } = useTranslation()

  return (
    <>
      <OperatorChip label={t('features.customerSegments.querySet.filterType', { context: filterItem.filter_type })} />

      {filterItem.operator === OP_DATE_RANGE && (
        <>
          {filterItem.value.min_date && <ValueChip label={filterItem.value.min_date} />}
          {filterItem.value.min_date && filterItem.value.max_date && <OperatorChip label='~' />}
          {filterItem.value.min_date && !filterItem.value.max_date && (
            <OperatorChip label={t('features.customerSegments.querySet.querySetView.orAfter')} />
          )}
          {filterItem.value.max_date && <ValueChip label={filterItem.value.max_date} />}
          {!filterItem.value.min_date && filterItem.value.max_date && (
            <OperatorChip label={t('features.customerSegments.querySet.querySetView.orBefore')} />
          )}
        </>
      )}

      {filterItem.operator === OP_RELATIVE_DATE_RANGE && (
        <>
          {filterItem.value.max && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixDateRange', { count: filterItem.value.max })} />
          )}
          {!filterItem.value.min && filterItem.value.max && <OperatorChip label={t('features.customerSegments.querySet.querySetView.orAfter')} />}
          {filterItem.value.min && filterItem.value.max && <OperatorChip label='~' />}
          {filterItem.value.min && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixDateRange', { count: filterItem.value.min })} />
          )}
          {filterItem.value.min && !filterItem.value.max && <OperatorChip label={t('features.customerSegments.querySet.querySetView.orBefore')} />}
        </>
      )}
    </>
  )
}
