import { useTranslation } from 'react-i18next'
import { Recipe } from '../types/type'

export const useRecipes = (): { data: Recipe[] } => {
  const { t } = useTranslation()

  const recipes: Recipe[] = [
    {
      key: 'product',
      title: t('features.recipes.recipes.title_product'),
      description: t('features.recipes.recipes.desc_product'),
      tags: ['repeat', 'crm', 'product'],
    },
    {
      key: 'productType',
      title: t('features.recipes.recipes.title_productType'),
      description: t('features.recipes.recipes.desc_productType'),
      tags: ['repeat', 'analysis', 'product'],
    },
    {
      key: 'loyalty',
      title: t('features.recipes.recipes.title_loyalty'),
      description: t('features.recipes.recipes.desc_loyalty'),
      tags: ['repeat', 'crm', 'customer'],
    },
    {
      key: 'acqFirst',
      title: t('features.recipes.recipes.title_acqFirst'),
      description: t('features.recipes.recipes.desc_acqFirst'),
      tags: ['first', 'analysis', 'acquisition'],
    },
    {
      key: 'acqRepeat',
      title: t('features.recipes.recipes.title_acqRepeat'),
      description: t('features.recipes.recipes.desc_acqRepeat'),
      tags: ['repeat', 'analysis', 'acquisition'],
    },
    {
      key: 'couponFirst',
      title: t('features.recipes.recipes.title_couponFirst'),
      description: t('features.recipes.recipes.desc_couponFirst'),
      tags: ['first', 'analysis', 'order'],
    },
    {
      key: 'couponSecond',
      title: t('features.recipes.recipes.title_couponSecond'),
      description: t('features.recipes.recipes.desc_couponSecond'),
      tags: ['repeat', 'analysis', 'order'],
    },
    {
      key: 'productCross',
      title: t('features.recipes.recipes.title_productCross'),
      description: t('features.recipes.recipes.desc_productCross'),
      tags: ['apparel', 'analysis', 'product'],
    },
    {
      key: 'productVendor',
      title: t('features.recipes.recipes.title_productVendor'),
      description: t('features.recipes.recipes.desc_productVendor'),
      tags: ['apparel', 'sku', 'analysis', 'product'],
    },
    {
      key: 'preference',
      title: t('features.recipes.recipes.title_preference'),
      description: t('features.recipes.recipes.desc_preference'),
      tags: ['apparel', 'household', 'product'],
    },
    {
      key: 'buyingCycle',
      title: t('features.recipes.recipes.title_buyingCycle'),
      description: t('features.recipes.recipes.desc_buyingCycle'),
      tags: ['household', 'crm', 'product'],
    },
    {
      key: 'event',
      title: t('features.recipes.recipes.title_event'),
      description: t('features.recipes.recipes.desc_event'),
      tags: ['gift', 'crm', 'order'],
    },
    {
      key: 'gift',
      title: t('features.recipes.recipes.title_gift'),
      description: t('features.recipes.recipes.desc_gift'),
      tags: ['gift', 'analysis', 'order'],
    },
    {
      key: 'highValue',
      title: t('features.recipes.recipes.title_highValue'),
      description: t('features.recipes.recipes.desc_highValue'),
      tags: ['gift', 'crm', 'order'],
    },
    {
      key: 'entry',
      title: t('features.recipes.recipes.title_entry'),
      description: t('features.recipes.recipes.desc_entry'),
      tags: ['startup', 'analysis', 'product', 'acquisition'],
    },
    {
      key: 'omnichannel',
      title: t('features.recipes.recipes.title_omnichannel'),
      description: t('features.recipes.recipes.desc_omnichannel'),
      tags: ['omnichannel', 'crm', 'order'],
    },
    {
      key: 'subscription',
      title: t('features.recipes.recipes.title_subscription'),
      description: t('features.recipes.recipes.desc_subscription'),
      tags: ['subscription', 'analysis', 'order'],
    },
    {
      key: 'trial',
      title: t('features.recipes.recipes.title_trial'),
      description: t('features.recipes.recipes.desc_trial'),
      tags: ['subscription', 'analysis', 'product'],
    },
    {
      key: 'region',
      title: t('features.recipes.recipes.title_region'),
      description: t('features.recipes.recipes.desc_region'),
      tags: ['crossBorder', 'analysis', 'customer'],
    },
    {
      key: 'holiday',
      title: t('features.recipes.recipes.title_holiday'),
      description: t('features.recipes.recipes.desc_holiday'),
      tags: ['crossBorder', 'analysis', 'customer'],
    },
    {
      key: 'rfm',
      title: t('features.recipes.recipes.title_rfm'),
      description: t('features.recipes.recipes.desc_rfm'),
      tags: ['first', 'repeat', 'crm', 'customer'],
    },
  ]

  return { data: recipes }
}
