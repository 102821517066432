import { OP_CONTAIN_ANY, OP_INT_RANGE, OP_NOT_CONTAIN } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useProductSegments = (products: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  const product = products[0]

  return [
    {
      name: t('features.recipes.segments.name_product_frequent', { value: product }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [product],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 5 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_regular', { value: product }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [product],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2, max: 4 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_oneTime', { value: product }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [product],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 1, max: 1 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_none', { value: product }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_NOT_CONTAIN,
          value: [product],
          filters: [],
        },
      ]),
    },
  ]
}
