import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, FormControl, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { CohortFilterState } from 'features/cohorts/types/cohortFilter'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

type Props = {
  cohortFilter: CohortFilterState
  setCohortFilter: (value: CohortFilterState) => void
  pageURL: string
}

export const CohortFilter: FC<Props> = ({ cohortFilter, setCohortFilter, pageURL }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [autocompleteValue, setAutocompleteValue] = useState<string | null>(null)
  const [selectedCustomerSegmentId, setSelectedCustomerSegmentId] = useState<string | undefined>(cohortFilter.customerSegmentId)

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const filterCohortInput = z
    .object({
      startYearMonth: z.string().min(1, { message: t('features.cohorts.cohortFilter.messageValidationStartYearMonthMin') }),
      endYearMonth: z.string().min(1, { message: t('features.cohorts.cohortFilter.messageValidationEndYearMonthMin') }),
      customerSegmentId: z.string().optional(),
    })
    .refine(
      (data) => {
        return dayjs(data.startYearMonth, 'YYYY-MM').isBefore(dayjs(data.endYearMonth, 'YYYY-MM'))
      },
      { message: t('features.cohorts.cohortFilter.messageValidationStartMustBeforeEnd'), path: ['startYearMonth'] }
    )

  type InputSchema = z.infer<typeof filterCohortInput>

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(filterCohortInput),
    defaultValues: {
      startYearMonth: cohortFilter.startYearMonth,
      endYearMonth: cohortFilter.endYearMonth,
      customerSegmentId: cohortFilter.customerSegmentId,
    },
  })

  useEffect(() => {
    if (customerSegments && selectedCustomerSegmentId) {
      const name = customerSegments.find((segment) => segment.ref.id === selectedCustomerSegmentId)?.name || null
      setAutocompleteValue(name)
      if (name === null) {
        setSelectedCustomerSegmentId(undefined)
        setValue('customerSegmentId', '')
        navigate(pageURL)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegments, selectedCustomerSegmentId])

  const handleFilterCohort = async (input: InputSchema) => {
    setCohortFilter({
      startYearMonth: input.startYearMonth,
      endYearMonth: input.endYearMonth,
      customerSegmentId: input.customerSegmentId,
    })
    let to = `${pageURL}?start_year_month=${input.startYearMonth}&end_year_month=${input.endYearMonth}`
    to += input.customerSegmentId ? `&customer_segment_id=${input.customerSegmentId}` : ''
    navigate(to)
  }

  return (
    <Box display='flex'>
      <Controller
        control={control}
        name='startYearMonth'
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '200px', marginRight: '12px' }}>
              <TextField
                {...field}
                type='month'
                size='small'
                error={Boolean(error)}
                helperText={error?.message}
                label={t('features.cohorts.cohortFilter.startYearMonth')}
              />
            </FormControl>
          )
        }}
      />

      <Controller
        control={control}
        name='endYearMonth'
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '200px', marginRight: '12px' }}>
              <TextField
                {...field}
                type='month'
                size='small'
                error={Boolean(error)}
                helperText={error?.message}
                label={t('features.cohorts.cohortFilter.endYearMonth')}
              />
            </FormControl>
          )
        }}
      />

      <Controller
        control={control}
        name='customerSegmentId'
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '360px', marginRight: '12px' }}>
              <Autocomplete
                size='small'
                disabled={!customerSegments}
                options={customerSegments ? customerSegments.map((s) => s.name) : []}
                value={autocompleteValue}
                onChange={(_, v) => {
                  setAutocompleteValue(v)
                  const selected = customerSegments && customerSegments.find((cs) => cs.name === v)
                  setValue('customerSegmentId', selected?.ref.id)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={field.value || null}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.cohorts.cohortFilter.customerSegments')}
                  />
                )}
              />
            </FormControl>
          )
        }}
      />

      <LoadingButton
        type='submit'
        size='small'
        loading={isSubmitting}
        loadingPosition='center'
        onClick={handleSubmit(handleFilterCohort)}
        variant='contained'
      >
        {t('features.cohorts.cohortFilter.applyFilter')}
      </LoadingButton>
    </Box>
  )
}
