import { useCallback } from 'react'
import { useAccount } from './useAccount'

type useCurrencyReturn = {
  formatCurrency: (amount: number) => string | undefined
}

export const useCurrency = (): useCurrencyReturn => {
  const { shop } = useAccount()

  const formatCurrency = useCallback(
    (amount: number): string | undefined => {
      const currencyCode = currencyCodeLocaleMap[shop.currency]
      if (!currencyCode) {
        return `${shop.currency} ${amount}`
      }

      return amount.toLocaleString(currencyCode, { style: 'currency', currency: shop.currency })
    },
    [shop]
  )

  return { formatCurrency }
}

const currencyCodeLocaleMap: Record<string, string> = {
  USD: 'en-US',
  EUR: 'de-DE',
  JPY: 'ja-JP',
  GBP: 'en-GB',
  AUD: 'en-AU',
  CAD: 'en-CA',
  CHF: 'de-CH',
  CNY: 'zh-CN',
  SEK: 'sv-SE',
  NZD: 'en-NZ',
  MXN: 'es-MX',
  SGD: 'en-SG',
  HKD: 'zh-HK',
  NOK: 'nb-NO',
  KRW: 'ko-KR',
  TRY: 'tr-TR',
  RUB: 'ru-RU',
  INR: 'en-IN',
  BRL: 'pt-BR',
  ZAR: 'en-ZA',
  PHP: 'en-PH',
  CZK: 'cs-CZ',
  IDR: 'id-ID',
  MYR: 'ms-MY',
  THB: 'th-TH',
  HUF: 'hu-HU',
  TWD: 'zh-TW',
  PLN: 'pl-PL',
  DKK: 'da-DK',
  CLP: 'es-CL',
  VND: 'vi-VN',
  SAR: 'ar-SA',
  AED: 'ar-AE',
  EGP: 'ar-EG',
  ARS: 'es-AR',
}
