import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { FC, ReactNode, createContext, useContext, useState } from 'react'
import { getTheme } from 'theme'

type ThemeContextType = {
  themeMode: 'light' | 'dark'
  setThemeMode: (themeMode: 'light' | 'dark') => void
}

const ThemeContext = createContext<ThemeContextType>({
  themeMode: 'light',
  setThemeMode: () => {},
})

export const useThemeContext = () => useContext(ThemeContext)

type ProviderProps = {
  children: ReactNode
}

export const AppThemeProvider: FC<ProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState<'light' | 'dark'>('light')

  const theme = getTheme(themeMode)

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  )
}
