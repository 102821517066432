import { Box, Drawer } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import { hasProAccess } from 'config/plan'
import { FatalError } from 'features/error/fatalError'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderItems } from './components/headerItems'
import { SidebarItems } from './components/sidebarItems'

const drawerWidth = 240
const appBarHeight = '64px'

interface AppBarProps extends MuiAppBarProps {
  open: boolean
}

export type FavoritedCustomerSegment = {
  id: string
  name: string
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

export const DashboardLayout = () => {
  const location = useLocation()
  const { account, shop } = useAccount()
  const { notifySentry } = useSentryNotifier()

  const [drawerOpen, setDrawerOpen] = useState(true)

  const { customerSegmentsRef, segmentGroupsRef } = useSubCollectionRef(account!.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef))
  const { data: segmentGroups } = useCollectionSubscription(query(segmentGroupsRef, orderBy('createdAt', 'desc')))

  const favoritedSegments: FavoritedCustomerSegment[] = useMemo(() => {
    return (
      customerSegments
        ?.filter((s) => s.favorited)
        .map((s) => ({
          id: s.ref.id,
          name: s.name,
        })) ?? []
    )
  }, [customerSegments])

  const displayUpgrade = useMemo(() => !hasProAccess(shop) && customerSegments && customerSegments.length >= 4, [shop, customerSegments])

  const onError = (err: Error, info: { componentStack: string }) => {
    notifySentry(err, info.componentStack)
  }

  return (
    <Box display='flex'>
      <AppBar
        position='fixed'
        open={drawerOpen}
        sx={{
          boxShadow: 'none',
          '& .MuiToolbar-root': {
            backgroundColor: (theme) => theme.palette.background.default,
          },
        }}
      >
        <HeaderItems drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} displayUpgrade={displayUpgrade} />
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={drawerOpen}
      >
        <SidebarItems
          appBarHeight={appBarHeight}
          segmentGroups={segmentGroups}
          favoritedSegments={favoritedSegments}
          displayUpgrade={displayUpgrade}
          customerSegmentCount={customerSegments?.length}
        />
      </Drawer>

      <Main
        open={drawerOpen}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          padding: '0px',
        }}
      >
        <Box sx={{ marginTop: appBarHeight, padding: '16px 32px', minWidth: '600px' }}>
          <ErrorBoundary FallbackComponent={() => <FatalError />} onError={onError} resetKeys={[location.pathname]}>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Main>
    </Box>
  )
}
