import AddBoxIcon from '@mui/icons-material/AddBox'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { QuerySet } from 'features/customerSegments/querySetView/querySet'
import { getIdToken } from 'firebase/auth'
import { query } from 'firebase/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCurrency } from 'hooks/useCurrency'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { useRecipe } from '../hooks/useRecipe'
import { Recipe, RecipeKey, RecipeSegmentMetrics } from '../types/type'

export const Detail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const params = useParams()
  const recipeId = params.id

  const { account, shop } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollectionSubscription(query(customerSegmentsRef))

  const { data: recipe } = useRecipe(recipeId as RecipeKey)

  useEffect(() => {
    if (!recipe) {
      navigate(AUTHORIZED_ROUTE.RECIPES)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe])

  const filteredRecipe = useMemo(() => {
    if (!recipe || !recipe.segments) return undefined
    return recipe.segments.map((segment) => {
      segment.existed = customerSegments?.some((cs) => {
        return cs.name === segment.name || JSON.stringify(cs.querySet) === JSON.stringify(segment.querySet)
      })
      return segment
    })
  }, [recipe, customerSegments])

  const [openedSegmentIds, setOpenedSegmentIds] = useState<number[]>([])
  const [creatingSegmentId, setCreatingSegmentId] = useState<number | undefined>(undefined)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const handleToggleCollapse = (segmentIndex: number) => {
    setOpenedSegmentIds((prev) => (prev.includes(segmentIndex) ? prev.filter((index) => index !== segmentIndex) : [...prev, segmentIndex]))
  }

  const handleCreateSegment = async (index: number, name: string, querySet: QuerySetFormState) => {
    if (!customerSegments) return
    if (!hasProAccess(shop) && customerSegments.length >= shop.billingPlan.maxCustomerSegmentCount) {
      setUpgradeRecommendDialogOpen(true)
      return
    }

    try {
      setCreatingSegmentId(index)
      const token = await getIdToken(authUser!)
      await customerSegmentService.create(
        { name: name, description: '', querySet: JSON.stringify(querySet) },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.create.createDialog.messageSaved'), { severity: 'success' })
      navigate(AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS)
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.create.createDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setCreatingSegmentId(undefined)
    }
  }

  const [metrics, setMetrics] = useState<{ [key: number]: RecipeSegmentMetrics }>({})
  const [metricsLoading, setMetricsLoading] = useState(false)
  const [metricsFetched, setMetricsFetched] = useState(false)

  const handleFetchMetrics = async (recipe: Recipe) => {
    if (!recipe || !recipe.segments) return
    if (metricsFetched) return

    const fetchMetrics = async (segment: any, index: number) => {
      setMetricsLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.estimateCustomerMetrics(
          { querySet: JSON.stringify(convertFormToQuerySet(segment.querySet)) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setMetrics((prevMetrics) => ({
          ...prevMetrics,
          [index]: {
            count: Number(resp.result?.count),
            repeaterRate: resp.result?.repeaterRate ?? 0,
            ltv: resp.result?.ltv ?? 0,
          },
        }))
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setMetricsLoading(false)
      }
    }

    recipe.segments.forEach((segment, index) => {
      fetchMetrics(segment, index)
    })
    setMetricsFetched(true)
  }

  return (
    <>
      <Box marginBottom='16px'>
        <Box
          display='flex'
          alignItems='center'
          marginBottom='12px'
          sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary, cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          onClick={() => navigate(AUTHORIZED_ROUTE.RECIPES)}
          width='fit-content'
        >
          <ArrowBackIcon sx={{ fontSize: '14px', marginRight: '4px' }} />
          <Typography fontSize='12px'>{t('features.recipes.detail.back')}</Typography>
        </Box>

        <Box>
          <Typography variant='h2' fontSize='18px' fontWeight='500' marginBottom='8px'>
            {recipe?.title}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {recipe?.description}
          </Typography>
        </Box>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: '650px' }} stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                '& .MuiTableCell-head': {
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: '13px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            >
              <TableCell />
              <TableCell>{t('features.recipes.detail.segment')}</TableCell>
              <TableCell align='center'>{t('common.customerMetrics.title_count')}</TableCell>
              <TableCell align='center'>{t('common.customerMetrics.title_repeaterRate')}</TableCell>
              <TableCell align='center'>{t('common.customerMetrics.title_ltv')}</TableCell>
              <TableCell align='right'>
                {recipe && (
                  <Tooltip title={!metricsFetched && t('features.recipes.detail.showMetrics')} placement='top'>
                    <span>
                      <IconButton
                        size='small'
                        color='primary'
                        disabled={metricsFetched || recipe.segments?.some((segment) => segment.loading)}
                        onClick={() => handleFetchMetrics(recipe)}
                      >
                        {metricsLoading ? <CircularProgress size={20} /> : <VisibilityIcon fontSize='small' />}
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecipe &&
              filteredRecipe.map((segment, index) => (
                <React.Fragment key={index}>
                  <TableRow key={segment.name} sx={{ '& .MuiTableCell-root': { borderBottom: 'unset' } }}>
                    <TableCell width='12px'>
                      <Tooltip title={!openedSegmentIds.includes(index) && t('features.recipes.detail.showQuerySet')} placement='top'>
                        <IconButton size='small' onClick={() => handleToggleCollapse(index)}>
                          {openedSegmentIds.includes(index) ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '50%' }}>
                      <Box display='flex' alignItems='center' gap='12px'>
                        <Typography fontSize='20px'>{extractIconFromTextWithIcon(segment.name)}</Typography>
                        <Typography fontSize='14px'>{extractTextFromTextWithIcon(segment.name)}</Typography>
                      </Box>
                      {segment.existed && (
                        <Typography fontSize='12px' color='text.secondary' marginTop='4px'>
                          {t('features.recipes.detail.existed')}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {metricsLoading ? <Skeleton /> : metrics[index]?.count === undefined ? '-' : metrics[index].count.toLocaleString()}
                    </TableCell>
                    <TableCell align='center'>
                      {metricsLoading ? (
                        <Skeleton />
                      ) : metrics[index]?.repeaterRate === undefined ? (
                        '-'
                      ) : (
                        `${(metrics[index].repeaterRate * 100).toFixed(1)}%`
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {metricsLoading ? <Skeleton /> : metrics[index]?.ltv === undefined ? '-' : formatCurrency(metrics[index].ltv)}
                    </TableCell>
                    <TableCell align='right'>
                      <Box display='flex' justifyContent='flex-end' gap='12px'>
                        <Tooltip title={t('features.recipes.detail.useQuery')} placement='top'>
                          <span>
                            <IconButton
                              size='small'
                              disabled={creatingSegmentId !== undefined}
                              onClick={() => {
                                const querySetJsonString = JSON.stringify(convertFormToQuerySet(segment.querySet as QuerySetFormState))
                                navigate({
                                  pathname: AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE,
                                  search: createSearchParams({
                                    querySet: encodeURIComponent(querySetJsonString),
                                  }).toString(),
                                })
                              }}
                            >
                              <DriveFileRenameOutlineRoundedIcon fontSize='small' />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={t('features.recipes.detail.createSegment')} placement='top'>
                          <span>
                            <IconButton
                              size='small'
                              color='primary'
                              disabled={(creatingSegmentId !== undefined && creatingSegmentId !== index) || segment.existed}
                              onClick={() => handleCreateSegment(index, segment.name, segment.querySet)}
                            >
                              {creatingSegmentId === index ? <CircularProgress size={20} /> : <AddBoxIcon fontSize='small' />}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1} />
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                      <Collapse in={openedSegmentIds.includes(index)} timeout='auto' unmountOnExit>
                        <Box sx={{ paddingBottom: '24px' }}>
                          {segment.loading ? (
                            <Box display='flex' gap='8px'>
                              {[...Array(5)].map((_, i) => (
                                <Skeleton key={i} height='16px' width='100px' />
                              ))}
                            </Box>
                          ) : (
                            <QuerySet querySet={segment.querySet} />
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='recipe' />
      )}
    </>
  )
}
