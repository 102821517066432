import { Box, Divider, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { QuerySubsetFormState } from '../../querySetForm/schema/querySetFormSchema'
import { QueryItem } from '../queryItem/queryItem'

type Props = {
  querySubset: QuerySubsetFormState
}

export const QuerySubset: FC<Props> = ({ querySubset }) => {
  const { t } = useTranslation()

  return (
    <>
      {querySubset.query_items.map((queryItem, queryItemIndex) => (
        <div key={queryItemIndex}>
          {queryItemIndex > 0 && (
            <Box display='flex' alignItems='center' marginY='16px'>
              <Typography fontSize='14px' fontWeight='bold' color={(theme) => theme.palette.text.secondary}>
                {t('features.customerSegments.querySet.joinOperator', { context: querySubset.join_operator })}
              </Typography>
              <Divider sx={{ width: '540px', height: '100%' }} />
            </Box>
          )}
          <QueryItem queryItem={queryItem} />
        </div>
      ))}
    </>
  )
}
