import { Theme } from '@mui/material'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'

export const getImagePath = (filePath: string) => {
  return `${process.env.PUBLIC_URL}/images/${filePath}`
}

// memo: move the following codes for dimension icon paths to somewhere out of this file

export const dimensionToFileName = {
  product: 'product.svg',
  product_variant: 'product.svg',
  product_type: 'product.svg',
  product_tag: 'tag.svg',
  product_vendor: 'product_vendor.svg',
  order_tag: 'tag.svg',
  customer_tag: 'tag.svg',
  utm_source: 'utm.svg',
  utm_medium: 'utm.svg',
  utm_campaign: 'utm.svg',
  order_date: 'order_date.svg',
  coupon: 'coupon.svg',
  order_value: 'order_value.svg',
  referrer: 'referrer.svg',
  channel: 'referrer.svg',
  landing_page: 'landing_page.svg',
  country: 'country.svg',
  prefecture: 'province.svg',
  recency: 'recency.svg',
  frequency: 'frequency.svg',
  monetary: 'monetary.svg',
  aov: 'monetary.svg',
  purchase_interval: 'purchase_interval.svg',
}

type DimensionKey = keyof typeof dimensionToFileName

export const getDimensionIconImagePath = (dimension: DimensionKey, theme: Theme): string => {
  const fileName = dimensionToFileName[dimension] || dimensionToFileName['product']
  const filePath = theme.palette.mode === 'dark' ? `dimension/dark/${fileName}` : `dimension/${fileName}`
  return getImagePath(filePath)
}

export const getDimensionIconsImagePaths = (querySet: QuerySetFormState, theme: Theme): string[] => {
  return querySet.query_subsets.flatMap((querySubset) => {
    return querySubset.query_items.map((queryItem) => {
      return getDimensionIconImagePath(queryItem.dimension, theme)
    })
  })
}
