import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'
import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_FIRST } from 'features/customerSegments/querySetForm/schema/const'

export const useProductVendorSegments = (productVendors: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_productVendor', { value: productVendors[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [productVendors[0]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productVendor', { value: productVendors[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [productVendors[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productVendor', { value: productVendors[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [productVendors[2]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productVendor_sequenceFirst', { value: productVendors[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [productVendors[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productVendor_sequenceFirst', { value: productVendors[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [productVendors[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productVendor_sequenceFirst', { value: productVendors[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_vendor',
          operator: OP_CONTAIN_ANY,
          value: [productVendors[2]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
  ]
}
