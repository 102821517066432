// contain operator
export const OP_CONTAIN_ALL = 'contain_all'
export const OP_CONTAIN_ANY = 'contain_any'
export const OP_NOT_CONTAIN = 'not_contain'
// range operator
export const OP_DATE_RANGE = 'date_range'
export const OP_RELATIVE_DATE_RANGE = 'relative_date_range'
export const OP_INT_RANGE = 'int_range'
// numeric operator
export const OP_EQUAL = 'eq'
export const OP_NOT_EQUAL = 'not_eq'
// other operator
export const OP_CUSTOM = 'custom'

/* only query set form(UI) use this */
export const OP_NONE = 'none' // Operator for "Undefined", is not being sent to api-server

export const OP_ORDER_SEQUENCE_FIRST = 'first_order'
export const OP_ORDER_SEQUENCE_SECOND = 'second_order'
export const OP_ORDER_SEQUENCE_LAST = 'last_order'
export const OP_ORDER_SEQUENCE_SPECIFY = 'specify_sequence'
