// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/segment_transition/segment_transition.proto (package segment_transition, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {AggregateCustomerCountsRequest, AggregateCustomerCountsResponse, AggregateCustomerMetricsRequest, AggregateCustomerMetricsResponse} from "./segment_transition_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service segment_transition.SegmentTransitionService
 */
export const SegmentTransitionService = {
  typeName: "segment_transition.SegmentTransitionService",
  methods: {
    /**
     * @generated from rpc segment_transition.SegmentTransitionService.AggregateCustomerCounts
     */
    aggregateCustomerCounts: {
      name: "AggregateCustomerCounts",
      I: AggregateCustomerCountsRequest,
      O: AggregateCustomerCountsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_transition.SegmentTransitionService.AggregateCustomerMetrics
     */
    aggregateCustomerMetrics: {
      name: "AggregateCustomerMetrics",
      I: AggregateCustomerMetricsRequest,
      O: AggregateCustomerMetricsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

