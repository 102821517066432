import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { env } from 'config/env'
import { useAccount } from 'hooks/useAccount'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
}

// AppUninstalledDialog is shown when the user has an account but has not installed the Shopify App.
export const AppUninstalledDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation()
  const { account } = useAccount()

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontSize: '18px' }}>{t('common.appUninstalledDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '14px' }}>{t('common.appUninstalledDialog.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            let url = env.SHOPIFY_APP_STORE_URL
            if (account.language === 'ja') url += '?locale=ja'
            window.open(url, '_blank')
          }}
          variant='contained'
        >
          {t('common.appUninstalledDialog.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
