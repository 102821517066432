import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined'
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, ToggleButton, Toolbar, Tooltip } from '@mui/material'
import { auth } from 'config/firebaseClient'
import { guideMap } from 'config/guide'
import { signOut } from 'firebase/auth'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  drawerOpen: boolean
  setDrawerOpen: (value: boolean) => void
  displayUpgrade: boolean | undefined
}

export const HeaderItems: FC<Props> = ({ drawerOpen, setDrawerOpen, displayUpgrade }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()

  const [helpMenuOpen, setHelpMenuOpen] = useState(false)
  const helpMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false)
  const settingsMenuAnchorRef = useRef<HTMLButtonElement | null>(null)

  const handleLogout = async () => {
    try {
      await signOut(auth)
    } catch (err) {
      enqueueSnackbar(t('layouts.dashboardLayout.messageLogoutError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <IconButton
            size='small'
            onClick={() => {
              setDrawerOpen(!drawerOpen)
            }}
          >
            <MenuIcon fontSize='small' />
          </IconButton>
        </Box>
        <Box>
          {displayUpgrade && (
            <Tooltip title={t('layouts.dashboardLayout.upgrade')}>
              <ToggleButton
                size='small'
                value='check'
                ref={settingsMenuAnchorRef}
                onChange={() => {
                  dispatch('ClickCheckPlan', { referrer: 'header' })
                  navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
                }}
                sx={{ borderRadius: '12px', marginRight: '8px' }}
              >
                <AutoAwesomeOutlinedIcon fontSize='small' />
              </ToggleButton>
            </Tooltip>
          )}

          <Tooltip title={t('layouts.dashboardLayout.help')}>
            <ToggleButton
              size='small'
              value='check'
              ref={helpMenuAnchorRef}
              onClick={() => setHelpMenuOpen(true)}
              sx={{ borderRadius: '12px', marginRight: '8px' }}
            >
              <InfoOutlinedIcon fontSize='small' />
            </ToggleButton>
          </Tooltip>

          <Menu anchorEl={helpMenuAnchorRef.current} open={helpMenuOpen} onClose={() => setHelpMenuOpen(false)}>
            <MenuList dense sx={{ padding: '0px' }}>
              <MenuItem
                onClick={() => {
                  const currentLang = i18n.language
                  const guideType = 'DashboardHeader'
                  const guideURL = guideMap[currentLang][guideType]
                  dispatch('ViewGuide', { guideType: guideType, url: guideURL })
                  window.open(guideURL, '_blank')
                  setHelpMenuOpen(false)
                }}
              >
                <ListItemIcon>
                  <MenuBookIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.documentation')}</ListItemText>
              </MenuItem>

              <Divider />

              <MenuItem
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.INTEGRATIONS)
                  setHelpMenuOpen(false)
                }}
              >
                <ListItemIcon>
                  <DashboardCustomizeIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.integrations')}</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>

          <Tooltip title={t('layouts.dashboardLayout.settings')}>
            <ToggleButton
              size='small'
              value='check'
              ref={settingsMenuAnchorRef}
              onChange={() => setSettingsMenuOpen(true)}
              sx={{ borderRadius: '12px' }}
            >
              <SettingsOutlinedIcon fontSize='small' />
            </ToggleButton>
          </Tooltip>

          <Menu anchorEl={settingsMenuAnchorRef.current} open={settingsMenuOpen} onClose={() => setSettingsMenuOpen(false)}>
            <MenuList dense sx={{ padding: '0px' }}>
              <MenuItem
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.SETTINGS_WORKSPACE)
                  setSettingsMenuOpen(false)
                }}
              >
                <ListItemIcon>
                  <AppSettingsAltOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.workspaceSettings')}</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.SETTINGS_ACCOUNT)
                  setSettingsMenuOpen(false)
                }}
              >
                <ListItemIcon>
                  <ManageAccountsOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.accountSettings')}</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.SETTINGS_MEMBERS)
                  setSettingsMenuOpen(false)
                }}
              >
                <ListItemIcon>
                  <GroupsOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.memberSettings')}</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
                  setSettingsMenuOpen(false)
                }}
              >
                <ListItemIcon>
                  <AirplaneTicketOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.planSettings')}</ListItemText>
              </MenuItem>

              <Divider />

              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutOutlinedIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>{t('layouts.dashboardLayout.logout')}</ListItemText>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Toolbar>
    </>
  )
}
