import { Code, ConnectError } from '@bufbuild/connect-web'
import Picker from '@emoji-mart/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Popover, TextField } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  id: string
  name: string
  description: string
  favorited: boolean
  handleClose: () => void
}

export const UpdateDialog: FC<Props> = ({ id, name, description, favorited, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const input = z.object({
    name: z
      .string()
      .min(1, { message: t('features.customerSegments.components.updateDialog.messageValidationNameMin') })
      .max(64, { message: t('features.customerSegments.components.updateDialog.messageValidationNameMax') }),
    description: z.string().max(128, { message: t('features.customerSegments.components.updateDialog.messageValidationDescriptionMax') }),
  })

  type InputSchema = z.infer<typeof input>

  // Divide customer list name into icon and name
  const defaultEmoji = '😀'
  let icon: string
  icon = name.match(/(?:[\p{Emoji}]|\u{FE0F}|\u{200D})+/gu)?.[0] || defaultEmoji
  icon = icon.match(/^\d+$/) ? defaultEmoji : icon
  const nameWithoutIcon = name.replace(`${icon} `, '')

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(input),
    defaultValues: {
      name: nameWithoutIcon,
      description: description,
    },
  })

  // EmojiPicker
  const [emojiIcon, setEmojiIcon] = useState<string>(icon)
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)
  const emojiAnchorRef = useRef<HTMLButtonElement | null>(null)

  const updateSegment = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.update(
        {
          customerSegmentId: id,
          name: `${emojiIcon} ${input.name}`,
          description: input.description,
          favorited: favorited,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.components.updateDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('name', { message: t('features.customerSegments.components.updateDialog.messageAlreadyExists') })
        return
      }
      enqueueSnackbar(t('features.customerSegments.components.updateDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '18px', paddingBottom: '8px' }}>{t('features.customerSegments.components.updateDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '14px', marginBottom: '24px' }}>
          {t('features.customerSegments.components.updateDialog.description')}
        </DialogContentText>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Button
            ref={emojiAnchorRef}
            variant='outlined'
            onClick={(e) => {
              setOpenEmojiPicker(true)
            }}
            sx={{ borderColor: (theme) => theme.palette.action.selected, fontSize: '22px', padding: '0px', marginRight: '10px' }}
          >
            {emojiIcon}
          </Button>
          <Popover
            open={openEmojiPicker}
            anchorEl={emojiAnchorRef.current}
            onClose={() => setOpenEmojiPicker(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Picker
              locale='en'
              onEmojiSelect={(emoji: any) => {
                setEmojiIcon(emoji.native)
                setOpenEmojiPicker(false)
              }}
            />
          </Popover>

          <Controller
            control={control}
            name='name'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.customerSegments.components.updateDialog.fieldName')}
                    InputLabelProps={{ shrink: true }}
                    size='small'
                  />
                </FormControl>
              )
            }}
          />
        </Box>

        <Box>
          <Controller
            control={control}
            name='description'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    label={t('features.customerSegments.components.updateDialog.fieldDescription')}
                    InputLabelProps={{ shrink: true }}
                    size='small'
                  />
                </FormControl>
              )
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose} variant='text' color='inherit'>
          {t('features.customerSegments.components.updateDialog.cancel')}
        </Button>
        <LoadingButton type='submit' loading={isSubmitting} loadingPosition='center' onClick={handleSubmit(updateSegment)} variant='contained'>
          {t('features.customerSegments.components.updateDialog.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
