import { Box, Skeleton, Tooltip } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const FreePlanSkeletonText: FC = () => {
  const { t } = useTranslation()

  return (
    <Box display='flex' justifyContent='center'>
      <Tooltip title={t('features.insights.detail.freeDesciption')} placement='bottom-start'>
        <Skeleton animation={false} height='16px' width='60px' />
      </Tooltip>
    </Box>
  )
}
