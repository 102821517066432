import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_INT_RANGE, OP_NONE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { FilterItemProps } from './type'

export const OrderCountFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t } = useTranslation()
  const { register, control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  return (
    <Box display='flex'>
      <Box fontSize='14px' marginRight='12px' paddingTop='10px'>
        {t('features.customerSegments.querySet.filterType', { context: 'order_count' })}
      </Box>
      <input type='hidden' value='order_count' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px', marginRight: '12px' }}>
              <Select defaultValue={OP_NONE} error={Boolean(error)} value={field.value} onChange={field.onChange} size='small'>
                <MenuItem key={OP_NONE} value={OP_NONE}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_count', { context: OP_NONE })}
                </MenuItem>
                <MenuItem key={OP_INT_RANGE} value={OP_INT_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_INT_RANGE })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />

      {operator === OP_INT_RANGE && (
        <IntRangeValue
          minValuePath={`${filterPath}.value.min`}
          maxValuePath={`${filterPath}.value.max`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixCount')}
          inputPropsMaxValue={99}
        />
      )}
    </Box>
  )
}
