import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useMemo } from 'react'
import { timestampToDayjs } from 'utils/timeUtil'
import { Overview as CustomerSegmentsOverview } from './overview/overview'

export const CustomerSegments = () => {
  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegmentData } = useCollectionSubscription(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const customerSegments = useMemo(() => {
    return (
      customerSegmentData &&
      customerSegmentData.map((d) => {
        return {
          id: d.ref.id,
          name: d.name,
          description: d.description,
          querySet: d.querySet,
          favorited: d.favorited,
          metrics: d.metrics,
          tagOperationSetting: d.tagOperationSetting,
          createdAt: timestampToDayjs(d.createdAt),
        }
      })
    )
  }, [customerSegmentData])

  return (
    <>
      <CustomerSegmentsOverview rows={customerSegments} />
    </>
  )
}
