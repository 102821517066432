import dayjs from 'dayjs'
import { formatYearMonth } from 'utils/timeUtil'

export type CohortFilterState = {
  startYearMonth: string
  endYearMonth: string
  customerSegmentId?: string
}

export const defaultStartYearMonth = formatYearMonth(dayjs().subtract(1, 'year')) // default to last year
export const defaultEndYearMonth = formatYearMonth(dayjs()) // default to current month

export const isValidYearMonthFormat = (value: string) => {
  const regex = /^\d{4}-\d{2}$/
  return regex.test(value)
}
