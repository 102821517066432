import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { LoadingButton } from '@mui/lab'
import { Box, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { planConfig } from 'config/plan'
import { BillingPlanIdentifier } from 'gen/firestore'

type Props = {
  plan: BillingPlanIdentifier
  selected: boolean
  selectable: boolean
  onChangePlan: () => Promise<void>
  submitting: boolean
}

export const PlanItem: FC<Props> = ({ plan, selected, selectable, onChangePlan, submitting }) => {
  const { t } = useTranslation()

  return (
    <Paper
      variant='outlined'
      sx={{
        padding: '24px',
        position: 'relative',
        borderRadius: '12px',
        height: '100%',
        ...(selected && { borderColor: 'primary.main' }),
      }}
    >
      {selected && (
        <CheckCircleIcon
          color='primary'
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px',
          }}
        />
      )}
      <Box marginBottom='24px'>
        <Typography fontWeight='bold'>{t('features.settings.plan.name', { context: plan })}</Typography>
        <Box marginBottom='4px'>
          <Typography fontSize='20px' fontWeight='bold' display='inline'>
            ${planConfig[plan].price}
          </Typography>
          <Typography fontSize='14px' fontWeight='bold' display='inline'>
            {t('features.settings.plan.perMonth')}
          </Typography>
        </Box>
        <Typography fontSize='12px' color={(theme) => theme.palette.text.secondary}>
          {t('features.settings.plan.description', { context: plan })}
        </Typography>
      </Box>
      <Box marginBottom='32px'>
        {[
          t('features.settings.plan.featuresSegment', { context: plan }),
          t('features.settings.plan.featuresHistory', { context: plan }),
          t('features.settings.plan.featuresAnalytics', { context: plan }),
          t('features.settings.plan.featuresDownload', { context: plan }),
        ].map((feature) => (
          <Typography key={feature} fontSize='14px' marginBottom='8px'>
            {feature}
          </Typography>
        ))}
      </Box>
      <LoadingButton
        fullWidth
        loading={submitting}
        disabled={selected || !selectable}
        variant={selected || plan === BillingPlanIdentifier.free ? 'outlined' : 'contained'}
        onClick={onChangePlan}
      >
        {selected ? t('features.settings.plan.currentPlan') : t('features.settings.plan.choosePlan', { context: plan })}
      </LoadingButton>
    </Paper>
  )
}
