import { OP_INT_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useEffect, useState } from 'react'

export const useHighValueSegments = (): RecipeSegment[] => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { notifySentry } = useSentryNotifier()
  const service = useGrpcClient(CustomerSegmentService)

  const [loading, setLoading] = useState(false)
  const [ltv, setLtv] = useState(100) // default: random value
  const [aov, setAov] = useState(30) // default: random value

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const token = await getIdToken(authUser!)
        const resp = await service.estimateCustomerMetrics({ querySet: '' }, { headers: { Authorization: `Bearer ${token}` } })
        if (resp.result) {
          setLtv(Math.floor(resp.result.ltv))
          setAov(Math.floor(resp.result.avgOrderValue))
        }
      } catch (err) {
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [
    {
      name: t('features.recipes.segments.name_highValue_ltv'),
      querySet: generateQuerySet([
        {
          dimension: 'monetary',
          operator: OP_INT_RANGE,
          value: { min: ltv * 2 },
          filters: [],
        },
      ]),
      loading: loading,
    },
    {
      name: t('features.recipes.segments.name_highValue_aov'),
      querySet: generateQuerySet([
        {
          dimension: 'aov',
          operator: OP_INT_RANGE,
          value: { min: aov * 2 },
          filters: [],
        },
      ]),
      loading: loading,
    },
    {
      name: t('features.recipes.segments.name_highValue_order'),
      querySet: generateQuerySet([
        {
          dimension: 'order_value',
          operator: OP_INT_RANGE,
          value: { min: aov * 4 },
          filters: [],
        },
      ]),
      loading: loading,
    },
  ]
}
