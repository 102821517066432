import { zodResolver } from '@hookform/resolvers/zod'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { LoadingButton } from '@mui/lab'
import { Box, Container, FormControl, Grid, LinearProgress, MenuItem, Paper, Select, Typography } from '@mui/material'
import { InteractiveGuideDialog } from 'components/interactiveGuideDialog'
import { guideMap } from 'config/guide'
import { getIdToken } from 'firebase/auth'
import { ShopService } from 'gen/proto/shop/shop_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'
import { z } from 'zod'

export const DataSyncProcessing: FC = () => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useActionTracker()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const shopService = useGrpcClient(ShopService)

  const [openInteractiveGuideDialog, setOpenInteractiveGuideDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const discoveryItems = {
    tutorial: {
      title: t('features.dataSyncProcessing.interactiveGuide'),
      image: getImagePath('dataSync/tutorial.svg'),
      onClick: () => {
        setOpenInteractiveGuideDialog(true)
      },
    },
    guide: {
      title: t('features.dataSyncProcessing.guide'),
      image: getImagePath('dataSync/guide.svg'),
      onClick: () => {
        const currentLang = i18n.language
        const guideType = 'DashboardHeader'
        const guideURL = guideMap[currentLang][guideType]
        dispatch('ViewGuide', { guideType: guideType, url: guideURL })
        window.open(guideURL, '_blank')
      },
    },
    chat: {
      title: t('features.dataSyncProcessing.chat'),
      image: getImagePath('dataSync/chat.svg'),
      onClick: () => {
        if (window.ChannelIO) {
          window.ChannelIO('show') // Open ChannelTalk
        }
      },
    },
    demo: {
      title: t('features.dataSyncProcessing.demo'),
      image: getImagePath('dataSync/demo.svg'),
      onClick: () => {
        const to = t('features.dataSyncProcessing.demoURL')
        window.open(to, '_blank')
      },
    },
  }

  const referrerOptions = ['store', 'ecpower', 'partner', 'google', 'social', 'article', 'ad'].map((context) =>
    t('features.dataSyncProcessing.referrer', { context })
  )

  const roleOptions = ['owner', 'analyst', 'marketing', 'support', 'product', 'admin', 'partner', 'others'].map((context) =>
    t('features.dataSyncProcessing.role', { context })
  )

  const surveyInput = z.object({
    referrer: z.string().min(1),
    role: z.string().min(1),
  })

  type InputSchema = z.infer<typeof surveyInput>

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(surveyInput),
    defaultValues: {
      referrer: '',
      role: '',
    },
  })

  const handleSurvey = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await shopService.submitSurveyAnswers(
        {
          referrer: input.referrer,
          role: input.role,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.dataSyncProcessing.messageSaved'), { severity: 'success' })
      setSubmitted(true)
    } catch (err) {
      enqueueSnackbar(t('features.dataSyncProcessing.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <>
      <Container maxWidth='lg' fixed sx={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography fontSize='32px' fontWeight='bold' textAlign='center'>
              {t('features.dataSyncProcessing.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress sx={{ height: '8px', borderRadius: '12px' }} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7}>
                <Paper variant='outlined' sx={{ padding: '32px', borderRadius: '12px' }}>
                  <Typography fontSize='20px' fontWeight='bold' marginBottom='18px'>
                    {t('features.dataSyncProcessing.guideTitle')}
                  </Typography>
                  <Typography fontSize='14px' marginBottom='32px'>
                    <Trans i18nKey='features.dataSyncProcessing.guideDesc' />
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.values(discoveryItems).map((item, index) => (
                      <Grid key={index} item xs={6}>
                        <Box
                          onClick={item.onClick}
                          sx={{
                            cursor: 'pointer',
                            '&:hover img': {
                              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
                            },
                          }}
                        >
                          <img
                            src={item.image}
                            alt={item.title}
                            width='100%'
                            style={{
                              borderRadius: '4px',
                            }}
                          />
                          <Typography variant='body2' textAlign='center'>
                            {item.title}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <Paper variant='outlined' sx={{ padding: '32px', borderRadius: '12px' }}>
                  <Typography fontSize='20px' fontWeight='bold' marginBottom='18px'>
                    {t('features.dataSyncProcessing.surveyTitle')}
                  </Typography>
                  <Typography fontSize='14px' marginBottom='32px'>
                    {t('features.dataSyncProcessing.surveyDesc')}
                  </Typography>

                  <Box>
                    <Controller
                      control={control}
                      name='referrer'
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                          <Typography variant='body2' fontWeight='bold' color='text.secondary' marginBottom='8px'>
                            {t('features.dataSyncProcessing.referrer')}
                          </Typography>
                          <Select
                            size='small'
                            value={field.value}
                            onChange={(e) => setValue(field.name, e.target.value)}
                            error={Boolean(error)}
                            disabled={submitted}
                          >
                            {referrerOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={control}
                      name='role'
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                          <Typography variant='body2' fontWeight='bold' color='text.secondary' marginBottom='8px'>
                            {t('features.dataSyncProcessing.role')}
                          </Typography>
                          <Select
                            size='small'
                            value={field.value}
                            onChange={(e) => setValue(field.name, e.target.value)}
                            error={Boolean(error)}
                            disabled={submitted}
                          >
                            {roleOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {!submitted ? (
                      <LoadingButton type='submit' fullWidth onClick={handleSubmit(handleSurvey)} variant='contained' loading={isSubmitting}>
                        {t('features.dataSyncProcessing.submit')}
                      </LoadingButton>
                    ) : (
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <CheckCircleIcon color='success' fontSize='small' sx={{ marginRight: '4px' }} />
                        <Typography fontSize='14px'>{t('features.dataSyncProcessing.messageSubmitted')}</Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {openInteractiveGuideDialog && (
        <InteractiveGuideDialog
          open={openInteractiveGuideDialog}
          handleClose={() => setOpenInteractiveGuideDialog(false)}
          referrer='dataSyncProcessing'
        />
      )}
    </>
  )
}
