import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'
import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_FIRST } from 'features/customerSegments/querySetForm/schema/const'

export const useCouponFirstSegments = (coupons: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_coupon_sequenceFirst', { value: coupons[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'coupon',
          operator: OP_CONTAIN_ANY,
          value: [coupons[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_coupon_sequenceFirst', { value: coupons[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'coupon',
          operator: OP_CONTAIN_ANY,
          value: [coupons[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_coupon_sequenceFirst', { value: coupons[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'coupon',
          operator: OP_CONTAIN_ANY,
          value: [coupons[2]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
  ]
}
