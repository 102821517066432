// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/order/order.proto (package order, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3} from "@bufbuild/protobuf";

/**
 * Get
 *
 * @generated from message order.GetRequest
 */
export class GetRequest extends Message<GetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "order.GetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRequest {
    return new GetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRequest | PlainMessage<GetRequest> | undefined, b: GetRequest | PlainMessage<GetRequest> | undefined): boolean {
    return proto3.util.equals(GetRequest, a, b);
  }
}

/**
 * @generated from message order.GetResponse
 */
export class GetResponse extends Message<GetResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: double orderValue = 3;
   */
  orderValue = 0;

  /**
   * format: "2006-01-02 15:04:05"
   *
   * @generated from field: string orderedAt = 4;
   */
  orderedAt = "";

  /**
   * @generated from field: repeated string products = 5;
   */
  products: string[] = [];

  /**
   * @generated from field: repeated string tags = 6;
   */
  tags: string[] = [];

  /**
   * @generated from field: string referrer = 7;
   */
  referrer = "";

  /**
   * @generated from field: string landingPage = 8;
   */
  landingPage = "";

  /**
   * @generated from field: string channel = 9;
   */
  channel = "";

  /**
   * @generated from field: string coupon = 10;
   */
  coupon = "";

  /**
   * @generated from field: string utmSource = 11;
   */
  utmSource = "";

  /**
   * @generated from field: string utmMedium = 12;
   */
  utmMedium = "";

  /**
   * @generated from field: string utmCampaign = 13;
   */
  utmCampaign = "";

  constructor(data?: PartialMessage<GetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "order.GetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "orderValue", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "orderedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "products", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "referrer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "landingPage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "channel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "coupon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "utmSource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "utmMedium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "utmCampaign", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponse {
    return new GetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponse | PlainMessage<GetResponse> | undefined, b: GetResponse | PlainMessage<GetResponse> | undefined): boolean {
    return proto3.util.equals(GetResponse, a, b);
  }
}

