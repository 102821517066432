import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { SkeletonTable } from 'components/skeletonTable'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import dayjs from 'dayjs'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { useCollection } from 'hooks/useFirestoreData'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { formatDate, formatYearMonth } from 'utils/timeUtil'
import { useMonthlyCohort } from '../hooks/useMonthlyCohort'
import { CohortFilterState, defaultEndYearMonth, defaultStartYearMonth, isValidYearMonthFormat } from '../types/cohortFilter'
import { CohortFilter } from './components/cohortFilter'

type MetricsIndexType = 'ltv' | 'f2RepeatRate' | 'f3RepeatRate' | 'f4RepeatRate' | 'f5RepeatRate'

export const MonthlyCohort = () => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()
  const navigate = useNavigate()
  const theme = useTheme()
  const { account, shop } = useAccount()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const [filterOpen, setFilterOpen] = useState(false)
  const [cohortFilter, setCohortFilter] = useState<CohortFilterState>()
  const [metricsIndex, setMetricsIndex] = useState<MetricsIndexType>('ltv')
  const { monthlyCohortSummary, loading } = useMonthlyCohort(cohortFilter)
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  useEffect(() => {
    const startYearMonth = searchParams.get('start_year_month')
    const endYearMonth = searchParams.get('end_year_month')

    const isStartYearMonthValid = startYearMonth && isValidYearMonthFormat(startYearMonth)
    const isEndYearMonthValid = endYearMonth && isValidYearMonthFormat(endYearMonth)

    if (!isStartYearMonthValid || !isEndYearMonthValid) {
      const searchParams = `?start_year_month=${defaultStartYearMonth}&end_year_month=${defaultEndYearMonth}`
      const to = `${AUTHORIZED_ROUTE.COHORTS_MONTHLY}${searchParams}`
      navigate(to)
      return
    }

    setCohortFilter({
      startYearMonth: startYearMonth || defaultStartYearMonth,
      endYearMonth: endYearMonth || defaultEndYearMonth,
      customerSegmentId: searchParams.get('customer_segment_id') || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const getStepValue = (step: number): string => {
    return step === -1 ? t('features.cohorts.monthlyCohort.stepFirst') : t('features.cohorts.monthlyCohort.stepNth', { step: step })
  }

  const getMetricsValue = (step: number, metrics: number): string => {
    switch (metricsIndex) {
      case 'ltv':
        return formatCurrency(metrics) || ''
      case 'f2RepeatRate':
      case 'f3RepeatRate':
      case 'f4RepeatRate':
      case 'f5RepeatRate':
        // Do not display the Fn repeat rate of the first purchase because it is always 0%
        if (step === -1) return '-'
        return `${(metrics * 100).toFixed(1)}%`
    }
  }

  const maxMetricValue = monthlyCohortSummary
    ? Math.max(...monthlyCohortSummary.monthlyResult.flatMap((mr) => mr.stepAndMetricsList.map((sm) => sm[metricsIndex])))
    : 0

  const getBackgroundColor = (step: number, value: number): string => {
    if (step === -1 && metricsIndex !== 'ltv') return 'none'
    const scale = value / maxMetricValue
    if (scale >= 0.8) return `${theme.palette.primary.main}99` // 60% opacity
    if (scale >= 0.6) return `${theme.palette.primary.main}66` // 40% opacity
    if (scale >= 0.4) return `${theme.palette.primary.main}4D` // 30% opacity
    if (scale >= 0.2) return `${theme.palette.primary.main}33` // 20% opacity
    return `${theme.palette.primary.main}1A` // 10% opacity
  }

  const getCustomerSegmentName = useMemo(
    () => (id: string) => {
      const customerSegment = customerSegments?.find((cs) => cs.ref.id === id)
      if (!customerSegment) return ''
      return customerSegment.name.length > 32 ? `${customerSegment.name.substring(0, 29)}...` : customerSegment.name
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerSegments, cohortFilter?.customerSegmentId]
  )

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data:
      monthlyCohortSummary &&
      monthlyCohortSummary.monthlyResult.flatMap((mr) => {
        return mr.stepAndMetricsList.map((sm) => {
          return {
            firstOrderYearMonth: mr.firstOrderYearMonth,
            step: getStepValue(sm.step),
            ltv: sm.ltv,
            orderValue: sm.orderValue,
            customerCount: sm.customerCount,
            f2CustomerCount: sm.f2CustomerCount,
            f3CustomerCount: sm.f3CustomerCount,
            f4CustomerCount: sm.f4CustomerCount,
            f5CustomerCount: sm.f5CustomerCount,
          }
        })
      }),
    headers: [
      t('features.cohorts.monthlyCohort.firstOrderYearMonth'),
      t('features.cohorts.monthlyCohort.step'),
      t('features.cohorts.monthlyCohort.ltv'),
      t('features.cohorts.monthlyCohort.sales'),
      t('features.cohorts.monthlyCohort.firstCustomerCount'),
      t('features.cohorts.monthlyCohort.f2CustomerCount'),
      t('features.cohorts.monthlyCohort.f3CustomerCount'),
      t('features.cohorts.monthlyCohort.f4CustomerCount'),
      t('features.cohorts.monthlyCohort.f5CustomerCount'),
    ],
    fileName: t('features.cohorts.monthlyCohort.downloadFileName', { date: formatDate(dayjs(), i18n.language) }),
    page: 'cohort_monthly',
  })

  return (
    <>
      <PageHeader title={t('features.cohorts.monthlyCohort.title')} marginBottom='32px' guideType='MonthlyCohort' />

      <Box display='flex' justifyContent='space-between' marginBottom='20px'>
        <Box display='flex' alignItems='center'>
          {cohortFilter && (
            <Button
              variant='outlined'
              size='small'
              startIcon={<CalendarMonthOutlinedIcon fontSize='small' />}
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {formatYearMonth(dayjs(cohortFilter.startYearMonth), i18n.language)} -{' '}
              {formatYearMonth(dayjs(cohortFilter.endYearMonth), i18n.language)}
            </Button>
          )}

          {cohortFilter && (
            <Button
              variant='outlined'
              size='small'
              onClick={() => setFilterOpen(!filterOpen)}
              sx={{ marginLeft: '12px' }}
              startIcon={!cohortFilter.customerSegmentId ? <PeopleOutlinedIcon fontSize='small' /> : null}
            >
              {cohortFilter.customerSegmentId
                ? getCustomerSegmentName(cohortFilter.customerSegmentId)
                : t('features.cohorts.monthlyCohort.allCustomers')}
            </Button>
          )}

          <ToggleButtonGroup
            value={metricsIndex}
            color='primary'
            exclusive
            size='small'
            onChange={(_, v) => {
              if (v === null) return
              setMetricsIndex(v as MetricsIndexType)
            }}
            sx={{
              marginLeft: '20px',
              '& .MuiToggleButtonGroup-grouped': {
                padding: '4px 12px',
              },
            }}
          >
            <ToggleButton value='ltv'>{t('features.cohorts.monthlyCohort.ltv')}</ToggleButton>
            <ToggleButton value='f2RepeatRate'>{t('features.cohorts.monthlyCohort.f2RepeatRate')}</ToggleButton>
            <ToggleButton value='f3RepeatRate'>{t('features.cohorts.monthlyCohort.f3RepeatRate')}</ToggleButton>
            <ToggleButton value='f4RepeatRate'>{t('features.cohorts.monthlyCohort.f4RepeatRate')}</ToggleButton>
            <ToggleButton value='f5RepeatRate'>{t('features.cohorts.monthlyCohort.f5RepeatRate')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Tooltip title={t('features.cohorts.monthlyCohort.download')} placement='top'>
          <span>
            <IconButton
              color='inherit'
              disabled={downloadLoading || !monthlyCohortSummary || monthlyCohortSummary.avgResult.length === 0}
              onClick={() => {
                if (hasProAccess(shop)) {
                  handleDownload()
                  return
                }
                setUpgradeRecommendDialogOpen(true)
              }}
              sx={{ marginRight: '18px' }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <Collapse in={filterOpen}>
        {cohortFilter && (
          <Box marginBottom='20px'>
            <CohortFilter
              cohortFilter={cohortFilter}
              setCohortFilter={(filter) => {
                setCohortFilter(filter)
                setFilterOpen(false)
              }}
              pageURL={AUTHORIZED_ROUTE.COHORTS_MONTHLY}
            />
          </Box>
        )}
      </Collapse>

      {loading && <SkeletonTable rowCount={15} columnCount={14} />}
      {!loading && monthlyCohortSummary && monthlyCohortSummary.avgResult.length === 0 ? (
        <Box fontSize='14px'>{t('features.cohorts.monthlyCohort.lengthZero')}️</Box>
      ) : (
        !loading &&
        monthlyCohortSummary && (
          <TableContainer>
            <Table
              size='small'
              sx={{
                '& .MuiTableCell-sizeSmall': {
                  padding: '14px',
                },
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    '& .MuiTableCell-head': {
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 600,
                    },
                  }}
                >
                  <TableCell sx={{ minWidth: '140px' }}>{t('features.cohorts.monthlyCohort.firstOrderYearMonth')}</TableCell>
                  <TableCell align='center' sx={{ minWidth: '80px' }}>
                    {t('features.cohorts.monthlyCohort.customerCount')}
                  </TableCell>
                  {monthlyCohortSummary.avgResult.map((ar) => (
                    <TableCell align='center' key={`monthlyCohort-${ar.step}`} sx={{ minWidth: '80px' }}>
                      {getStepValue(ar.step)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {monthlyCohortSummary.monthlyResult.map((mr) => (
                  <TableRow key={`monthlyCohort-${mr.firstOrderYearMonth}`}>
                    <TableCell
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        fontWeight: 600,
                      }}
                    >
                      {mr.firstOrderYearMonth}
                    </TableCell>
                    <TableCell align='center'>{mr.stepAndMetricsList[0].customerCount}</TableCell>
                    {mr.stepAndMetricsList.map((sm) => (
                      <TableCell
                        align='center'
                        key={`monthlyCohort-${mr.firstOrderYearMonth}-${sm.step}`}
                        sx={{
                          backgroundColor: getBackgroundColor(sm.step, sm[metricsIndex]),
                        }}
                      >
                        {getMetricsValue(sm.step, sm[metricsIndex])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow sx={{ backgroundColor: (theme) => theme.palette.action.hover }}>
                  <TableCell
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontWeight: 600,
                    }}
                  >
                    {t('features.cohorts.monthlyCohort.all')}
                  </TableCell>
                  <TableCell align='center'>{monthlyCohortSummary.avgResult[0].customerCount}</TableCell>
                  {monthlyCohortSummary.avgResult.map((ar) => (
                    <TableCell
                      align='center'
                      key={`monthlyCohort-avg-${ar.step}`}
                      sx={{
                        backgroundColor: getBackgroundColor(ar.step, ar[metricsIndex]),
                      }}
                    >
                      {getMetricsValue(ar.step, ar[metricsIndex])}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='monthlyCohort' />
      )}
    </>
  )
}
