import { QueryItemFormState, QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollection } from 'hooks/useFirestoreData'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Recipe, RecipeKey, RecipeSegment } from '../types/type'
import { useAcqFirstSegments } from './segments/acqFirst'
import { useAcqRepeatSegments } from './segments/acqRepeat'
import { useBuyingCycleSegments } from './segments/buyingCycle'
import { useCouponFirstSegments } from './segments/couponFirst'
import { useCouponSecondSegments } from './segments/couponSecond'
import { useEntrySegments } from './segments/entry'
import { useEventSegments } from './segments/event'
import { useGiftSegments } from './segments/gift'
import { useHighValueSegments } from './segments/highValue'
import { useHolidaySegments } from './segments/holiday'
import { useLoyaltySegments } from './segments/loyalty'
import { useOmnichannelSegments } from './segments/omnichannel'
import { usePreferenceSegments } from './segments/preference'
import { useProductSegments } from './segments/product'
import { useProductCrossSegments } from './segments/productCross'
import { useProductTypeSegments } from './segments/productType'
import { useProductVendorSegments } from './segments/productVendor'
import { useRegionSegments } from './segments/region'
import { useRFMSegments } from './segments/rfm'
import { useSubscriptionSegments } from './segments/subscription'
import { useTrialSegments } from './segments/trial'
import { useRecipes } from './useRecipes'

const TOP_VALUES_COUNT = 6

export const useRecipe = (key: RecipeKey): { data: Recipe | undefined } => {
  const { t } = useTranslation()
  const { data: recipes } = useRecipes()

  const { account } = useAccount()
  const { dimensionOptionsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useCollection(query(dimensionOptionsRef))

  const getTopValues = useCallback(
    (dimension: string): string[] => {
      const options = data?.find((d) => d.dimension === dimension)?.options?.slice(0, TOP_VALUES_COUNT) ?? []
      // Add sample values to make the array length TOP_VALUES_COUNT
      while (options.length < TOP_VALUES_COUNT) {
        options.push(t('features.recipes.detail.sample', { index: options.length }))
      }
      return options
    },
    [data, t]
  )

  const products = getTopValues('product')
  const productTypes = getTopValues('product_type')
  const productVendors = getTopValues('product_vendor')
  const productTags = getTopValues('product_tag')
  const referrers = getTopValues('referrer')
  const landingPages = getTopValues('landing_page')
  const utmSources = getTopValues('utm_source')
  const utmMediums = getTopValues('utm_medium')
  const utmCampaigns = getTopValues('utm_campaign')
  const channels = getTopValues('channel')
  const coupons = getTopValues('coupon')
  const countries = getTopValues('country')

  const recipeSegmentsMap: { [key in RecipeKey]: RecipeSegment[] } = {
    product: useProductSegments(products),
    productType: useProductTypeSegments(productTypes),
    loyalty: useLoyaltySegments(),
    acqFirst: useAcqFirstSegments(utmSources, utmMediums, utmCampaigns),
    acqRepeat: useAcqRepeatSegments(utmSources, referrers, landingPages),
    couponFirst: useCouponFirstSegments(coupons),
    couponSecond: useCouponSecondSegments(coupons),
    productCross: useProductCrossSegments(products),
    productVendor: useProductVendorSegments(productVendors),
    preference: usePreferenceSegments(productTags),
    buyingCycle: useBuyingCycleSegments(products),
    event: useEventSegments(),
    gift: useGiftSegments(),
    highValue: useHighValueSegments(),
    entry: useEntrySegments(products, utmSources, landingPages),
    omnichannel: useOmnichannelSegments(channels),
    subscription: useSubscriptionSegments(),
    trial: useTrialSegments(products),
    region: useRegionSegments(countries, products),
    holiday: useHolidaySegments(),
    rfm: useRFMSegments(),
  }

  const segments = recipeSegmentsMap[key]

  const recipe = recipes?.find((recipe) => recipe.key === key)
  if (recipe) {
    recipe.segments = segments
  }

  return { data: recipe }
}

export const generateQuerySet = (queryItems: QueryItemFormState[]): QuerySetFormState => {
  return {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: queryItems,
      },
    ],
  }
}

export const generateQuerySetOr = (queryItems: QueryItemFormState[]): QuerySetFormState => {
  return {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'or',
        query_items: queryItems,
      },
    ],
  }
}
