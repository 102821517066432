import { Box } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { OP_INT_RANGE } from '../../querySetForm/schema/const'
import { QueryItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { DimensionChip } from '../dimensionChip'
import { OperatorChip } from '../operatorChip'
import { ValueChip } from '../valueChip'

type Props = {
  queryItem: QueryItemFormState
  icon: JSX.Element
}

export const RecencyQuery: FC<Props> = ({ queryItem, icon }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box component='span' marginRight='6px' fontSize='14px'>
        {t('features.customerSegments.querySet.querySetView.prefixForCustomer')}
      </Box>

      <DimensionChip label={t('features.customerSegments.querySet.dimension', { context: queryItem.dimension })} icon={icon} />
      <OperatorChip label={t('features.customerSegments.querySet.querySetView.op_customer', { context: queryItem.operator })} />

      {queryItem.operator === OP_INT_RANGE && (
        <>
          {queryItem.value.max && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixDateRange', { count: queryItem.value.max })} />
          )}
          {!queryItem.value.min && queryItem.value.max && <OperatorChip label={t('features.customerSegments.querySet.querySetView.orAfter')} />}
          {queryItem.value.min && queryItem.value.max && <OperatorChip label='~' />}
          {queryItem.value.min && (
            <ValueChip label={t('features.customerSegments.querySet.querySetView.suffixDateRange', { count: queryItem.value.min })} />
          )}
          {queryItem.value.min && !queryItem.value.max && <OperatorChip label={t('features.customerSegments.querySet.querySetView.orBefore')} />}
        </>
      )}
    </>
  )
}
