import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'
import {
  OP_CONTAIN_ANY,
  OP_INT_RANGE,
  OP_NOT_CONTAIN,
  OP_ORDER_SEQUENCE_FIRST,
  OP_ORDER_SEQUENCE_SPECIFY,
} from 'features/customerSegments/querySetForm/schema/const'

export const useSubscriptionSegments = (): RecipeSegment[] => {
  const { t } = useTranslation()

  const subscriptionAppValue = t('features.recipes.segments.value_subscriptionApp')

  return [
    {
      name: t('features.recipes.segments.name_subscription'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [subscriptionAppValue],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_subscription_sequenceFirst'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [subscriptionAppValue],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_subscription_sequenceRepeat'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_NOT_CONTAIN,
          value: [subscriptionAppValue],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [subscriptionAppValue],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_subscription_count5plus'),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [subscriptionAppValue],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 5 } }],
        },
      ]),
    },
  ]
}
