/* eslint-disable jsx-a11y/anchor-has-content */
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, Container, FormControlLabel, Link, MenuItem, TextField, Typography } from '@mui/material'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { Code, ConnectError } from '@bufbuild/connect-web'
import { env } from 'config/env'
import { auth } from 'config/firebaseClient'
import { AuthService } from 'gen/proto/auth/auth_connectweb'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Trans, useTranslation } from 'react-i18next'
import { PUBLIC_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'

const getEmbeddedAppURL = (shop: string) => {
  return `https://${shop}.myshopify.com/admin/apps/${env.EMBEDDED_SHOPIFY_APP_NAME}`
}

const verifySessionTokenIntervalMs = 10 * 1000

export const Signup = () => {
  const { t, i18n } = useTranslation()
  const [shopName, setShopName] = useState('')
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const authService = useGrpcClient(AuthService)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const embeddedAppSessionToken = queryParams.get('sessionToken') || undefined

  const signupInputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.signup.messageValidationEmailIsRequired') })
      .email(t('features.signup.messageValidationEmailIsInvalid')),
    password: z
      .string()
      .min(6, { message: t('features.signup.messageValidationPasswordMin') })
      .max(30, { message: t('features.signup.messageValidationPasswordMax') }),
    language: z.string(),
    agreeToTerms: z.boolean().refine((value) => value === true, {
      params: { required: true },
    }),
  })
  type InputSchema = z.infer<typeof signupInputSchema>

  const verifySessionToken = useCallback(async () => {
    try {
      const res = await authService.getShopByToken({ sessionToken: embeddedAppSessionToken })
      if (!res.shopName) {
        throw new Error('shopName is not found')
      }
      setShopName(res.shopName)
      if (res.shopName && !res.tokenVerified) {
        alert(t('features.signup.messageUrlIsExpired'))
        window.location.replace(getEmbeddedAppURL(res.shopName))
      }
    } catch (err) {
      notifySentry(err, { sessionToken: embeddedAppSessionToken })
      alert(t('features.signup.messageUrlIsInvalid'))
      let url = env.SHOPIFY_APP_STORE_URL
      if (i18n.language === 'ja') url += '?locale=ja'
      window.location.replace(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authService, embeddedAppSessionToken, notifySentry])

  useEffect(() => {
    // first verify session token
    verifySessionToken()

    // interval verify session token
    const intervalId = setInterval(verifySessionToken, verifySessionTokenIntervalMs)
    return () => {
      clearInterval(intervalId)
    }
  }, [verifySessionToken])

  const {
    setValue,
    setError,
    register,
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(signupInputSchema),
  })

  const [termChecked, setTermChecked] = useState(false)

  const handleSignup: SubmitHandler<InputSchema> = async (input) => {
    try {
      await authService.signup({ email: input.email, password: input.password, sessionToken: embeddedAppSessionToken, language: input.language })
      await signInWithEmailAndPassword(auth, input.email, input.password)
    } catch (err) {
      if (err instanceof ConnectError && err.code === Code.AlreadyExists) {
        setError('email', { message: t('features.signup.messageEmailExists') })
        return
      }
      enqueueSnackbar(t('features.signup.messageUnknownError'), { severity: 'error' })
      notifySentry(err, { inputEmail: input.email, inputPassword: input.password })
    }
  }

  return (
    <Container maxWidth='xl' fixed sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ width: { xs: '100%', sm: '100%', md: '45%' } }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 600, marginBottom: '8px' }}>{t('features.signup.title')}</Typography>
        <Typography sx={{ marginBottom: '32px' }}>{t('features.signup.description')}</Typography>
        <Box sx={{ marginBottom: '32px' }}>
          <TextField
            autoComplete='email'
            label={t('features.signup.store')}
            disabled
            size='small'
            fullWidth
            autoFocus
            value={shopName ? `${shopName}.myshopify.com` : ''}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
          />
          <TextField
            autoComplete='email'
            label={t('features.signup.email')}
            size='small'
            fullWidth
            autoFocus
            error={!!formErrors.email?.message}
            helperText={formErrors.email?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            {...register('email')}
          />
          <TextField
            type='password'
            label={t('features.signup.password')}
            size='small'
            fullWidth
            autoComplete='current-password'
            error={!!formErrors.password?.message}
            helperText={formErrors.password?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            {...register('password')}
          />
          <TextField
            {...register('language')}
            select
            label={t('features.signup.language')}
            size='small'
            fullWidth
            defaultValue={i18n.language || 'en'}
            error={!!formErrors.language?.message}
            sx={{ marginTop: '0px', marginBottom: '4px' }}
            onChange={(e) => {
              const lang = e.target.value
              i18n.changeLanguage(lang)
              setValue('language', lang)
            }}
          >
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='ja'>日本語</MenuItem>
          </TextField>
          <FormControlLabel
            control={<Checkbox {...register('agreeToTerms')} onClick={() => setTermChecked(!termChecked)} />}
            label={
              <Typography fontSize='14px' color={formErrors.agreeToTerms && 'primary'}>
                <Trans
                  i18nKey='features.signup.agreeToTermsAndPrivacyPolicy'
                  components={{
                    lt: <Link href={t('features.signup.link_terms')} target='_blank' rel='noopener noreferrer' color='inherit' />,
                    ld: <Link href={t('features.signup.link_dpa')} target='_blank' rel='noopener noreferrer' color='inherit' />,
                    lp: <Link href={t('features.signup.link_privacyPolicy')} target='_blank' rel='noopener noreferrer' color='inherit' />,
                  }}
                />
              </Typography>
            }
            sx={{ marginBottom: '4px' }}
          />
          <LoadingButton
            type='submit'
            size='medium'
            fullWidth
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(handleSignup)}
            disabled={!termChecked || !shopName}
            variant='contained'
          >
            {t('features.signup.submit')}
          </LoadingButton>
        </Box>
        <Typography sx={{ fontSize: '14px' }}>
          {t('features.signup.login')}
          <Link
            onClick={() => {
              navigate(PUBLIC_ROUTE.LOGIN)
            }}
            sx={{ fontWeight: 600, textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('features.signup.loginLink')}
          </Link>
        </Typography>
      </Box>

      <Box sx={{ width: '45%', display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <img src={getImagePath('signup/signup.png')} width='100%' alt='signup' />
      </Box>
    </Container>
  )
}
