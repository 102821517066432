import { OP_CONTAIN_ALL, OP_CONTAIN_ANY, OP_NOT_CONTAIN } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useProductCrossSegments = (products: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_product_bundle', { value1: products[0], value2: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ALL,
          value: [products[0], products[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_multi', { value1: products[0], value2: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_neigher', { value1: products[0], value2: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_NOT_CONTAIN,
          value: [products[0], products[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_only', { value: products[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_NOT_CONTAIN,
          value: [products[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_only', { value: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_NOT_CONTAIN,
          value: [products[0]],
          filters: [],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [],
        },
      ]),
    },
  ]
}
