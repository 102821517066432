import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import { InsightReportType } from 'gen/firestore'
import { FC } from 'react'

type Props = {
  reportType: InsightReportType
  color?: 'inherit' | 'action' | 'disabled' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  sx?: object
}

export const ReportTypeIcon: FC<Props> = ({ reportType, color = 'inherit', sx }) => {
  switch (reportType) {
    case InsightReportType.popularity:
      return <DonutLargeRoundedIcon color={color} sx={sx} />
    case InsightReportType.monthly:
      return <CalendarMonthRoundedIcon color={color} sx={sx} />
    case InsightReportType.nthOrder:
      return <FormatListNumberedRoundedIcon color={color} sx={sx} />
    case InsightReportType.orderCount:
      return <RepeatRoundedIcon color={color} sx={sx} />
    case InsightReportType.histogram:
      return <EqualizerIcon color={color} sx={sx} />
    default:
      return <></>
  }
}
