import { FC, useCallback } from 'react'
import { FormProvider, UseFormProps, UseFormReturn, useForm } from 'react-hook-form'

import { QuerySetForm } from './querySetForm'
import { QuerySetFormState } from './schema/querySetFormSchema'

type UseQuerySetFormReturn = {
  QuerySetForm: FC
  querySetFormMethods: UseFormReturn<QuerySetFormState>
}

export const useQuerySetForm = (useFormProps: UseFormProps<QuerySetFormState>): UseQuerySetFormReturn => {
  const formMethods = useForm<QuerySetFormState>({
    ...useFormProps,
  })

  const Form: FC = useCallback(() => {
    return (
      <FormProvider {...formMethods}>
        <QuerySetForm />
      </FormProvider>
    )
  }, [formMethods])

  return { QuerySetForm: Form, querySetFormMethods: formMethods }
}
