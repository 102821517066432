import { AppUninstalledDialog } from 'components/appUninstalledDialog'
import { UpgradeRequiredDialog } from 'components/upgradeRequiredDialog'
import { hasDeveloperCookie, isMaintenanceMode } from 'config/maintenanceMode'
import { isUpgradeRequired } from 'config/plan'
import { MonthlyCohort } from 'features/cohorts/pages/monthlyCohort'
import { NthOrderCohort } from 'features/cohorts/pages/nthOrderCohort'
import { Create as CustomerSegmentCreate } from 'features/customerSegments/pages/create/create'
import { CustomerSegments } from 'features/customerSegments/pages/customerSegments'
import { Detail as CustomerSegmentDetail } from 'features/customerSegments/pages/detail/detail'
import { CustomerSegmentGroup } from 'features/customerSegments/pages/group/group'
import { DataSyncNotStarted } from 'features/dataSync/dataSyncNotStarted'
import { DataSyncProcessing } from 'features/dataSync/dataSyncProcessing'
import { Home } from 'features/home/home'
import { Detail as InsightDetail } from 'features/insights/detail/detail'
import { Insights } from 'features/insights/insights'
import { Integrations } from 'features/integrations/integrations'
import { Login } from 'features/login/login'
import { Maintenance } from 'features/maintenance/maintenance'
import { Detail as RecipeDetail } from 'features/recipes/detail/detail'
import { Recipes } from 'features/recipes/recipes'
import { ResetPassword } from 'features/reset/resetPassword'
import { Settings } from 'features/settings/pages/settings'
import { MemberSignup } from 'features/signup/memberSignup'
import { Signup } from 'features/signup/signup'
import { getIdToken } from 'firebase/auth'
import { ShopifyDataSyncStatus } from 'gen/firestore'
import { AuthService } from 'gen/proto/auth/auth_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useInitChannelTalk } from 'hooks/useChannelTalk'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { WithPageViewTracker, trackIdentifyEvent } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { DashboardLayout } from 'layouts/dashboardLayout/dashboardLayout'
import { DataSyncLayout } from 'layouts/dataSyncLayout/dataSyncLayout'
import { PublicLayout } from 'layouts/publicLayout/publicLayout'
import { AccountProvider } from 'provider/accountContext'
import { useThemeContext } from 'provider/themeContext'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AUTHORIZED_ROUTE, PUBLIC_ROUTE } from 'routing'

export const AppRoutes: FC = () => {
  const authUser = useAuthUser()
  const isAuthorized = !!authUser

  if (isMaintenanceMode && !hasDeveloperCookie) {
    return (
      <Routes>
        <Route path='/' element={<PublicLayout />}>
          <Route path='/' element={<Maintenance />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Routes>
    )
  }

  return isAuthorized ? (
    // Roots for LOGIN user
    <AccountProvider>
      <InitChannelTalk>
        <AuthorizedRoutes />
      </InitChannelTalk>
    </AccountProvider>
  ) : (
    // Roots for LOGOUT user
    <UnAuthorizedRoutes />
  )
}

const AuthorizedRoutes: FC = () => {
  const { i18n } = useTranslation()
  const { loading, account, shop } = useAccount()
  const location = useLocation()
  const authUser = useAuthUser()
  const authService = useGrpcClient(AuthService)
  const { notifySentry } = useSentryNotifier()

  const { setThemeMode } = useThemeContext()

  const [appUninstalledDialogOpen, setAppUninstalledDialogOpen] = useState(false)

  useEffect(() => {
    if (account.language !== i18n.language) {
      i18n.changeLanguage(account.language)
    }
  }, [account, i18n])

  useEffect(() => {
    trackIdentifyEvent(shop.shopName)
  }, [shop])

  useEffect(() => {
    if (shop.shopifyDataSyncStatus === ShopifyDataSyncStatus.completed) {
      setThemeMode(account.displayDarkMode ? 'dark' : 'light')
    } else {
      setThemeMode('light')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, shop])

  useEffect(() => {
    const verifyAppInstallation = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await authService.verifyAppInstallation({}, { headers: { Authorization: `Bearer ${token}` } })
        setAppUninstalledDialogOpen(!resp.installed) // If app is not installed, set true.
      } catch (err) {
        notifySentry(err)
      }
    }
    if (shop.isDemo) return

    verifyAppInstallation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop])

  const upgradeRequiredDialogOpen = useMemo(() => {
    if (shop.isDemo) return false
    if (location.pathname.includes('settings')) return false

    return isUpgradeRequired(shop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shop, location])

  if (loading) return <></>

  if (shop.shopifyDataSyncStatus === ShopifyDataSyncStatus.not_started) {
    return (
      <>
        <Routes>
          <Route path='/' element={<DataSyncLayout />}>
            <Route path='/' element={<DataSyncNotStarted />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        </Routes>
        {appUninstalledDialogOpen && <AppUninstalledDialog open={appUninstalledDialogOpen} />}
      </>
    )
  }

  if (shop.shopifyDataSyncStatus === ShopifyDataSyncStatus.processing) {
    return (
      <>
        <Routes>
          <Route path='/' element={<DataSyncLayout />}>
            <Route path='/' element={<DataSyncProcessing />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Route>
        </Routes>
        {appUninstalledDialogOpen && <AppUninstalledDialog open={appUninstalledDialogOpen} />}
      </>
    )
  }

  if (shop.shopifyDataSyncStatus === ShopifyDataSyncStatus.completed) {
    return (
      <>
        <Routes>
          <Route path='/' element={<DashboardLayout />}>
            {/* Segments */}
            <Route path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS} element={<WithPageViewTracker page={CustomerSegments} />} />
            <Route path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_GROUP} element={<WithPageViewTracker page={CustomerSegmentGroup} />} />
            <Route
              path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL}
              element={<WithPageViewTracker page={CustomerSegmentDetail} pageIdentifier={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL} />}
            />
            <Route path={AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE} element={<WithPageViewTracker page={CustomerSegmentCreate} />} />

            {/* Insights */}
            <Route path={AUTHORIZED_ROUTE.INSIGHTS} element={<WithPageViewTracker page={Insights} />} />
            <Route
              path={AUTHORIZED_ROUTE.INSIGHT_DETAIL}
              element={<WithPageViewTracker page={InsightDetail} pageIdentifier={AUTHORIZED_ROUTE.INSIGHT_DETAIL} />}
            />
            <Route path={AUTHORIZED_ROUTE.COHORTS_MONTHLY} element={<WithPageViewTracker page={MonthlyCohort} />} />
            <Route path={AUTHORIZED_ROUTE.COHORTS_NTH_ORDER} element={<WithPageViewTracker page={NthOrderCohort} />} />

            {/* Recipes */}
            <Route path={AUTHORIZED_ROUTE.RECIPES} element={<WithPageViewTracker page={Recipes} />} />
            <Route path={AUTHORIZED_ROUTE.RECIPE_DETAIL} element={<WithPageViewTracker page={RecipeDetail} />} />

            {/* Integrations */}
            <Route path={AUTHORIZED_ROUTE.INTEGRATIONS} element={<WithPageViewTracker page={Integrations} />} />

            {/* Setting */}
            <Route path={AUTHORIZED_ROUTE.SETTINGS_WORKSPACE} element={<WithPageViewTracker page={Settings} />} />
            <Route path={AUTHORIZED_ROUTE.SETTINGS_ACCOUNT} element={<WithPageViewTracker page={Settings} />} />
            <Route path={AUTHORIZED_ROUTE.SETTINGS_MEMBERS} element={<WithPageViewTracker page={Settings} />} />
            <Route path={AUTHORIZED_ROUTE.SETTINGS_PLAN} element={<WithPageViewTracker page={Settings} />} />

            {/* Others */}
            <Route path='/' element={<WithPageViewTracker page={Home} />} />
          </Route>
          <Route path='*' element={<Navigate to={AUTHORIZED_ROUTE.HOME} replace />} />
        </Routes>

        {upgradeRequiredDialogOpen && <UpgradeRequiredDialog open={upgradeRequiredDialogOpen} />}
        {appUninstalledDialogOpen && <AppUninstalledDialog open={appUninstalledDialogOpen} />}
      </>
    )
  }

  throw new Error(`shopifyDataSyncStatus is invalid in shop: ${shop.shopName}`)
}

const UnAuthorizedRoutes: FC = () => {
  const { setThemeMode } = useThemeContext()

  useEffect(() => {
    setThemeMode('light')
  }, [setThemeMode])

  return (
    <Routes>
      <Route path='/' element={<PublicLayout />}>
        <Route path={PUBLIC_ROUTE.SIGNUP} element={<Signup />} />
        <Route path={PUBLIC_ROUTE.LOGIN} element={<Login />} />
        <Route path={PUBLIC_ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={PUBLIC_ROUTE.MEMBER_SIGNUP} element={<MemberSignup />} />
        <Route path='/' element={<Navigate to={PUBLIC_ROUTE.LOGIN} replace />} />
        <Route path='*' element={<Navigate to={PUBLIC_ROUTE.LOGIN} replace />} />
      </Route>
    </Routes>
  )
}

const InitChannelTalk: FC<{ children: ReactNode }> = ({ children }) => {
  useInitChannelTalk()
  return <>{children}</>
}
