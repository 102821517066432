import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_FIRST } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useAcqFirstSegments = (utmSources: string[], utmMediums: string[], utmCampaigns: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_utmSource_sequenceFirst', { value: utmSources[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: [utmSources[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmSource_sequenceFirst', { value: utmSources[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: [utmSources[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmMedium_sequenceFirst', { value: utmMediums[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_medium',
          operator: OP_CONTAIN_ANY,
          value: [utmMediums[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmMedium_sequenceFirst', { value: utmMediums[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_medium',
          operator: OP_CONTAIN_ANY,
          value: [utmMediums[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmCampaign_sequenceFirst', { value: utmCampaigns[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_campaign',
          operator: OP_CONTAIN_ANY,
          value: [utmCampaigns[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmCampaign_sequenceFirst', { value: utmCampaigns[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_campaign',
          operator: OP_CONTAIN_ANY,
          value: [utmCampaigns[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
  ]
}
