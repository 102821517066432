import { CollectionReference, DocumentData, DocumentReference, Query, getDoc, getDocs, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomSnackbar } from './useCustomSnackbar'
import { useSentryNotifier } from './useSentryNotifier'

type LoadingResult<T> = {
  loading: boolean
  error?: Error
  data?: T
}

export const useDocument = <T = DocumentData>(docRef: DocumentReference<T>): LoadingResult<T> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [result, setResult] = useState<LoadingResult<T>>({
    loading: true,
  })
  useEffect(() => {
    const fetch = async () => {
      try {
        const docSnap = await getDoc<T>(docRef)
        const data = docSnap.data()
        setResult({
          loading: false,
          data: data,
        })
      } catch (err) {
        setResult({ loading: false, error: err as Error })
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return result
}

export const useDocumentSubscription = <T = DocumentData>(docRef: DocumentReference<T>): LoadingResult<T> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [result, setResult] = useState<LoadingResult<T>>({
    loading: true,
  })
  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, {
      next: (docSnap) => {
        setResult({
          loading: false,
          data: docSnap.data(),
        })
      },
      error: (err) => {
        setResult({
          loading: false,
          error: err,
        })
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      },
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docRef.id])

  return result
}

export const useCollection = <T = DocumentData>(query: Query<T> | CollectionReference<T>): LoadingResult<T[]> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [result, setResult] = useState<LoadingResult<T[]>>({
    loading: true,
  })
  useEffect(() => {
    const fetch = async () => {
      try {
        const docSnaps = await getDocs<T>(query)
        const data = docSnaps.docs.map((snap) => snap.data())
        setResult({
          loading: false,
          data: data,
        })
      } catch (err) {
        setResult({ loading: false, error: err as Error })
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return result
}

export const useCollectionSubscription = <T = DocumentData>(query: Query<T> | CollectionReference<T>): LoadingResult<T[]> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [result, setResult] = useState<LoadingResult<T[]>>({
    loading: true,
  })
  useEffect(() => {
    const unsubscribe = onSnapshot(query, {
      next: (querySnap) => {
        const data = querySnap.docs.map((d) => d.data())
        setResult({
          loading: false,
          data: data,
        })
      },
      error: (err) => {
        setResult({
          loading: false,
          error: err,
        })
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      },
    })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return result
}
