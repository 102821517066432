// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/data_loader/data_loader.proto (package gdpr, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {StartLoadFromShopifyRequest, StartLoadFromShopifyResponse} from "./data_loader_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service gdpr.DataLoaderService
 */
export const DataLoaderService = {
  typeName: "gdpr.DataLoaderService",
  methods: {
    /**
     * @generated from rpc gdpr.DataLoaderService.StartLoadFromShopify
     */
    startLoadFromShopify: {
      name: "StartLoadFromShopify",
      I: StartLoadFromShopifyRequest,
      O: StartLoadFromShopifyResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

