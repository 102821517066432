import { OP_CONTAIN_ANY, OP_INT_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const usePreferenceSegments = (productTags: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_productTag_twicePlus', { value: productTags[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [productTags[0]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productTag_twicePlus', { value: productTags[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [productTags[1]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productTag_twicePlus', { value: productTags[2] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [productTags[2]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productTag_twicePlus', { value: productTags[3] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [productTags[3]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productTag_twicePlus', { value: productTags[4] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [productTags[4]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_productTag_twicePlus', { value: productTags[5] }),
      querySet: generateQuerySet([
        {
          dimension: 'product_tag',
          operator: OP_CONTAIN_ANY,
          value: [productTags[5]],
          filters: [{ filter_type: 'order_count', operator: OP_INT_RANGE, value: { min: 2 } }],
        },
      ]),
    },
  ]
}
