import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
}

// UpgradeRequiredDialog is used when the user's active customer count exceeds the maximum limit of their current plan.
export const UpgradeRequiredDialog: FC<Props> = ({ open }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ fontSize: '18px' }}>{t('common.upgradeRequiredDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '14px' }}>{t('common.upgradeRequiredDialog.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)} variant='contained'>
          {t('common.upgradeRequiredDialog.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
