import { FormControl, MenuItem, Select } from '@mui/material'
import { FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { DimensionOptionsContext } from 'features/customerSegments/context/dimensionOptionsContext'
import { useTranslation } from 'react-i18next'
import { OP_CONTAIN_ALL, OP_CONTAIN_ANY, OP_NOT_CONTAIN } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { AutoCompleteValue } from '../value/autoCompleteValue'
import { QueryItemProps } from './type'

export const CountryQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const { data } = useContext(DimensionOptionsContext)
  const options = data.country

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '140px', marginRight: '12px' }}>
              <Select size='small' value={field.value} error={Boolean(error)} onChange={field.onChange}>
                <MenuItem key={OP_CONTAIN_ANY} value={OP_CONTAIN_ANY}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_CONTAIN_ANY })}
                </MenuItem>
                <MenuItem key={OP_CONTAIN_ALL} value={OP_CONTAIN_ALL}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_CONTAIN_ALL })}
                </MenuItem>
                <MenuItem key={OP_NOT_CONTAIN} value={OP_NOT_CONTAIN}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_NOT_CONTAIN })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />

      <AutoCompleteValue queryItemValuePath={`${queryItemPath}.value`} options={options} />
    </>
  )
}
