import { Alert } from '@mui/material'
import { useSnackbar, SnackbarKey, SnackbarMessage } from 'notistack'

const defaultAutoHideDuration = 2000 // default duration for displaying snackbar

type CustomSnackbarOption = {
  severity: 'error' | 'warning' | 'success' | 'info'
  autoHideDuration?: number // duration for displaying snackbar
}

type useCustomSnackbarResult = {
  enqueueSnackbar: (message: string, option: CustomSnackbarOption) => SnackbarKey
  closeSnackbar: (key?: SnackbarKey | undefined) => void
}

// Wrapper for useSnackbar in the notistack package
export const useCustomSnackbar = (): useCustomSnackbarResult => {
  const { enqueueSnackbar: _enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueueSnackbar = (message: SnackbarMessage, option: CustomSnackbarOption): SnackbarKey => {
    return _enqueueSnackbar(message, {
      autoHideDuration: option.autoHideDuration || defaultAutoHideDuration,
      content: (snackbarKey) => (
        <Alert
          onClose={() => {
            closeSnackbar(snackbarKey)
          }}
          severity={option.severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      ),
    })
  }

  return { enqueueSnackbar, closeSnackbar }
}
