import { firestore } from 'config/firebaseClient'
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  collection,
} from 'firebase/firestore'

import { Account, CollectionName, CustomerSegment, DimensionOptions, SegmentGroup, Shop, TagOperation } from 'gen/firestore'

export const generateConverter = <T>(): FirestoreDataConverter<T> => {
  return {
    toFirestore(arg: WithFieldValue<T>): DocumentData {
      // Not implemented because writing from the frontend is not accepted.
      return {}
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
      // Convert Firestore snapshot data to type T
      return { ...snapshot.data(options), ref: snapshot.ref } as unknown as T
    },
  }
}

type RootCollectionRef = {
  accountsRef: CollectionReference<Account>
  shopsRef: CollectionReference<Shop>
  // add here
}

type SubCollectionRef = {
  customerSegmentsRef: CollectionReference<CustomerSegment>
  segmentGroupsRef: CollectionReference<SegmentGroup>
  tagOperationsRef: CollectionReference<TagOperation>
  dimensionOptionsRef: CollectionReference<DimensionOptions>
  // add here
}

export const useRootCollectionRef = (): RootCollectionRef => {
  return {
    accountsRef: collection(firestore, CollectionName.accounts).withConverter(generateConverter<Account>()),
    shopsRef: collection(firestore, CollectionName.shops).withConverter(generateConverter<Shop>()),
  }
}

export const useSubCollectionRef = (shopId: string): SubCollectionRef => {
  const subCollectionRef = (subCollectionName: string) => collection(firestore, CollectionName.shops, shopId, subCollectionName)

  return {
    customerSegmentsRef: subCollectionRef(CollectionName.customerSegments).withConverter(generateConverter<CustomerSegment>()),
    segmentGroupsRef: subCollectionRef(CollectionName.segmentGroups).withConverter(generateConverter<SegmentGroup>()),
    tagOperationsRef: subCollectionRef(CollectionName.tagOperations).withConverter(generateConverter<TagOperation>()),
    dimensionOptionsRef: subCollectionRef(CollectionName.dimensionOptions).withConverter(generateConverter<DimensionOptions>()),
  }
}
