// useCustomerSegment.ts
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { doc } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useDocumentSubscription } from 'hooks/useFirestoreData'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'

export const useCustomerSegment = (id: string | undefined) => {
  const { i18n } = useTranslation()

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data } = useDocumentSubscription(doc(customerSegmentsRef, id))

  return useMemo(() => {
    if (!data) return
    const res: CustomerSegmentState = {
      id: data.ref.id,
      name: data.name,
      description: data.description,
      querySet: data.querySet,
      favorited: data.favorited,
      metrics: data.metrics,
      metricsHistory: data.metricsHistory.map((mh) => {
        return {
          date: formatDate(timestampToDayjs(mh.createdAt), i18n.language),
          metrics: {
            count: mh.metrics.count,
            repeaterRate: Math.round(mh.metrics.repeaterRate * 100) / 100,
            ltv: Math.round(mh.metrics.ltv * 100) / 100,
            avgOrderValue: Math.round(mh.metrics.avgOrderValue * 100) / 100,
            avgOrderCount: Math.round(mh.metrics.avgOrderCount * 100) / 100,
            avgDaysBetweenOrders: Math.round(mh.metrics.avgDaysBetweenOrders * 100) / 100,
          },
        }
      }),
      tagOperationSetting: data.tagOperationSetting,
      createdAt: timestampToDayjs(data.createdAt),
    }
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
