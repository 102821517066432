import ClearIcon from '@mui/icons-material/Clear'
import { Checkbox, CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Tooltip } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Item = {
  name: string
  isVisible: boolean
}

type Props = {
  loading: boolean
  items: Item[]
  selectedNames: string[]
  setSelectedNames: (names: string[]) => void
  height: string // px
}

export const NameCheckBoxList: FC<Props> = ({ loading, items, selectedNames, setSelectedNames, height }) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const filteredItems = useMemo(() => {
    const lowerCaseSearchText = searchText.toLowerCase()
    return items.filter((item) => item.name.toLowerCase().includes(lowerCaseSearchText))
  }, [items, searchText])

  const handleCheckboxChange = (checked: boolean, name: string) => {
    if (checked) {
      if (selectedNames.length <= 10) {
        setSelectedNames([...selectedNames, name])
      }
    } else {
      if (selectedNames.length > 1) {
        setSelectedNames(selectedNames.filter((_name) => _name !== name))
      }
    }
  }

  return !loading ? (
    <Paper variant='outlined' sx={{ padding: '16px', borderRadius: '12px', height: { height }, overflowY: 'auto' }}>
      <TextField
        margin='dense'
        size='small'
        fullWidth
        placeholder={t('features.insights.detail.search')}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          endAdornment: searchText && (
            <IconButton size='small' onClick={() => setSearchText('')}>
              <ClearIcon fontSize='small' />
            </IconButton>
          ),
        }}
      />
      <List dense>
        {filteredItems.map((item, index) => (
          <Tooltip key={index} title={!item.isVisible ? t('features.insights.detail.freeDesciption') : ''} placement='left'>
            <ListItem
              onClick={() => item.isVisible && handleCheckboxChange(!selectedNames.includes(item.name), item.name)}
              sx={{ cursor: item.isVisible ? 'pointer' : 'default' }}
            >
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <Checkbox size='small' edge='start' checked={selectedNames.includes(item.name)} disabled={!item.isVisible} />
              </ListItemIcon>
              <ListItemText primary={item.name} primaryTypographyProps={{ noWrap: true }} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Paper>
  ) : (
    <Paper variant='outlined' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', height: { height } }}>
      <CircularProgress />
    </Paper>
  )
}
