import { OP_CONTAIN_ANY, OP_INT_RANGE, OP_ORDER_SEQUENCE_FIRST, OP_ORDER_SEQUENCE_SECOND } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useTrialSegments = (products: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  const trialProductValue = t('features.recipes.segments.value_trialProduct')

  return [
    {
      name: t('features.recipes.segments.name_trial_new'),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [trialProductValue],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
        {
          dimension: 'frequency',
          operator: OP_INT_RANGE,
          value: { min: 1, max: 1 },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_trial_second', { value: products[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [trialProductValue],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SECOND }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_trial_second', { value: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [trialProductValue],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SECOND }],
        },
      ]),
    },
  ]
}
