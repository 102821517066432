import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Box, Container, Link, TextField, Typography } from '@mui/material'
import { env } from 'config/env'
import { auth } from 'config/firebaseClient'
import { AuthError, signInWithEmailAndPassword } from 'firebase/auth'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from 'routing'
import { getImagePath } from 'utils/imageUtil'
import { z } from 'zod'

export const Login = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const loginInputSchema = z.object({
    email: z
      .string()
      .min(1, { message: t('features.login.messageValidationEmailIsRequired') })
      .email(t('features.login.messageValidationEmailIsInvalid')),
    password: z.string().min(3, { message: t('features.login.messageValidationPasswordIsRequired') }),
  })

  type InputSchema = z.infer<typeof loginInputSchema>

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, submitCount, isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(loginInputSchema),
  })

  const handleLogin: SubmitHandler<InputSchema> = async (input) => {
    try {
      await signInWithEmailAndPassword(auth, input.email, input.password)
    } catch (err) {
      const { message, errorType } = getLoginErrorMessage(err as AuthError)
      enqueueSnackbar(message, { severity: 'error' })
      if (errorType === 'unknown') {
        // 予想外のエラーのみsentryに通知する
        notifySentry(err, { inputEmail: input.email, inputPassword: input.password })
      }
    }
  }

  const getLoginErrorMessage = (err: any): { message: string; errorType: 'badRequest' | 'unknown' } => {
    if (!err.code) return { message: t('features.login.messageError'), errorType: 'unknown' }
    switch (err.code) {
      case 'auth/invalid-email':
      case 'auth/user-not-found':
      case 'auth/user-mismatch':
      case 'auth/wrong-password':
        return { message: t('features.login.messageValidationEmailOrPasswordIsInvalid'), errorType: 'badRequest' }
      default:
        return { message: t('features.login.messageError'), errorType: 'unknown' }
    }
  }

  return (
    <Container maxWidth='xl' fixed sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ width: { xs: '100%', sm: '100%', md: '45%' } }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 600, marginBottom: '8px' }}>{t('features.login.title')}</Typography>
        <Typography sx={{ marginBottom: '32px' }}>{t('features.login.description')}</Typography>
        <Box sx={{ marginBottom: '32px' }}>
          <TextField
            autoComplete='email'
            label={t('features.login.email')}
            size='small'
            fullWidth
            autoFocus
            error={!!formErrors.email?.message}
            helperText={formErrors.email?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            {...register('email')}
          />
          <TextField
            type='password'
            label={t('features.login.password')}
            size='small'
            fullWidth
            autoComplete='current-password'
            error={!!formErrors.password?.message}
            helperText={formErrors.password?.message}
            sx={{ marginTop: '0px', marginBottom: '16px' }}
            {...register('password')}
          />
          <LoadingButton
            type='submit'
            size='medium'
            fullWidth
            loading={isSubmitting}
            loadingPosition='center'
            onClick={handleSubmit(handleLogin)}
            disabled={submitCount > 0 && !isValid}
            variant='contained'
          >
            {t('features.login.submit')}
          </LoadingButton>
        </Box>

        <Typography sx={{ fontSize: '14px', marginBottom: '8px' }}>
          {t('features.login.new')}
          <Link
            onClick={() => {
              let url = env.SHOPIFY_APP_STORE_URL
              if (i18n.language === 'ja') url += '?locale=ja'
              window.open(url, '_blank')
            }}
            sx={{ fontWeight: 600, textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('features.login.newLink')}
          </Link>
        </Typography>

        <Typography sx={{ fontSize: '14px' }}>
          {t('features.login.forget')}
          <Link
            onClick={() => {
              navigate(PUBLIC_ROUTE.RESET_PASSWORD)
            }}
            sx={{ fontWeight: 600, textDecoration: 'none', cursor: 'pointer' }}
          >
            {t('features.login.forgetLink')}
          </Link>
        </Typography>
      </Box>

      <Box sx={{ width: '45%', display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <img src={getImagePath('login.png')} width='100%' alt='reset' />
      </Box>
    </Container>
  )
}
