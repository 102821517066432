import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { QuerySetFormState } from '../querySetForm/schema/querySetFormSchema'
import { QuerySubset } from './querySubset/querySubset'

type Props = {
  querySet: QuerySetFormState
}

export const QuerySet: FC<Props> = ({ querySet }) => {
  const { t } = useTranslation()

  return (
    <>
      {querySet.query_subsets.map((querySubset, querySubsetIndex) => (
        <Box key={querySubsetIndex}>
          {querySubsetIndex > 0 && (
            <Typography marginY='12px' marginLeft='-9px' fontSize='14px' fontWeight='bold'>
              {t('features.customerSegments.querySet.joinOperator', { context: querySet.join_operator })}
            </Typography>
          )}
          <Box
            paddingY='8px'
            paddingX={querySet.query_subsets.length === 1 ? '' : '16px'}
            borderLeft={querySet.query_subsets.length === 1 ? '' : '3px solid lightgray'}
          >
            <QuerySubset querySubset={querySubset} />
          </Box>
        </Box>
      ))}
    </>
  )
}
