import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'
import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_FIRST } from 'features/customerSegments/querySetForm/schema/const'

export const useEntrySegments = (products: string[], utmSources: string[], landingPages: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_product_sequenceFirst', { value: products[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_sequenceFirst', { value: products[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmSource_sequenceFirst', { value: utmSources[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: [utmSources[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmSource_sequenceFirst', { value: utmSources[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: [utmSources[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_landingPage_sequenceFirst', { value: landingPages[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'landing_page',
          operator: OP_CONTAIN_ANY,
          value: [landingPages[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_landingPage_sequenceFirst', { value: landingPages[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'landing_page',
          operator: OP_CONTAIN_ANY,
          value: [landingPages[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
  ]
}
