import { FormControl, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type Props = {
  minDateValuePath:
    | `query_subsets.${number}.query_items.${number}.value.min_date`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.min_date`
  maxDateValuePath:
    | `query_subsets.${number}.query_items.${number}.value.max_date`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.max_date`
}

export const DateRangeValue: FC<Props> = ({ minDateValuePath, maxDateValuePath }) => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<QuerySetFormState>()

  return (
    <>
      <Controller
        control={control}
        name={minDateValuePath}
        rules={{
          validate: (v) => {
            const maxDate = watch(maxDateValuePath)
            if (!v && !maxDate) {
              return t('features.customerSegments.querySet.querySetForm.messageValidationEitherMinOrMax')
            }
            if (v && maxDate && v > maxDate) {
              return t('features.customerSegments.querySet.querySetForm.messageValidationMinMustSmallerThanMax')
            }
          },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px', marginRight: '6px' }}>
              <TextField
                type='date'
                // memo: undefined data is being converted to an empty string
                value={field.value === undefined ? '' : field.value}
                onChange={(e) => {
                  field.onChange(e.target.value !== '' ? e.target.value : undefined)
                }}
                error={Boolean(error)}
                helperText={error?.message}
                size='small'
              />
            </FormControl>
          )
        }}
      />
      <Typography sx={{ marginRight: '6px', alignSelf: 'center' }}>〜</Typography>
      <Controller
        control={control}
        name={maxDateValuePath}
        rules={{
          validate: (v) => {
            const minDate = watch(minDateValuePath)
            if (v && minDate && v < minDate) {
              return t('features.customerSegments.querySet.querySetForm.messageValidationMaxMustBiggerThanMin')
            }
          },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px' }}>
              <TextField
                type='date'
                // memo: undefined data is being converted to an empty string
                value={field.value === undefined ? '' : field.value}
                onChange={(e) => {
                  field.onChange(e.target.value !== '' ? e.target.value : undefined)
                }}
                error={Boolean(error)}
                helperText={error?.message}
                size='small'
              />
            </FormControl>
          )
        }}
      />
    </>
  )
}
