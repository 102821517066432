import { orderBy, query } from 'firebase/firestore'
import { TagOperationStatus } from 'gen/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCollectionSubscription } from 'hooks/useFirestoreData'
import { useMemo } from 'react'

export const useTagsRunningStatus = (customerSegmentId: string | undefined) => {
  const { account } = useAccount()
  const { tagOperationsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: tagOperations } = useCollectionSubscription(query(tagOperationsRef, orderBy('createdAt', 'desc')))

  const currentTagRunning: boolean = useMemo(() => {
    if (!tagOperations || tagOperations.length === 0) return false
    return tagOperations.some((op) => op.customerSegmentRef?.id === customerSegmentId && op.status !== TagOperationStatus.completed)
  }, [tagOperations, customerSegmentId])

  const otherTagsRunning: boolean = useMemo(() => {
    if (!tagOperations || tagOperations.length === 0) return false
    return tagOperations.some((op) => op.customerSegmentRef?.id !== customerSegmentId && op.status === TagOperationStatus.processing)
  }, [tagOperations, customerSegmentId])

  return { currentTagRunning, otherTagsRunning }
}
