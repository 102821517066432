import { Typography } from '@mui/material'

type Props<T> = {
  index: T
  label: string
  selected: boolean
  setMetricsIndex: (index: T) => void
}

export const MetricsIndexToggleButton = <T,>({ index, label, selected, setMetricsIndex }: Props<T>) => {
  return (
    <Typography
      fontSize='14px'
      marginRight='20px'
      fontWeight='bold'
      color={selected ? 'primary' : 'darkgray'}
      borderBottom={selected ? '2px solid' : 'none'}
      onClick={() => setMetricsIndex(index)}
      sx={{ cursor: 'pointer' }}
    >
      {label}
    </Typography>
  )
}
