import { OP_CONTAIN_ANY, OP_RELATIVE_DATE_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useBuyingCycleSegments = (products: string[]): RecipeSegment[] => {
  const { t } = useTranslation()
  const [min, max] = [30, 60]

  return [
    {
      name: t('features.recipes.segments.name_product_date', { value: products[0], PI1: max, PI2: min }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[0]],
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { min, max } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_date', { value: products[1], PI1: max, PI2: min }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[1]],
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { min, max } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_product_date', { value: products[2], PI1: max, PI2: min }),
      querySet: generateQuerySet([
        {
          dimension: 'product',
          operator: OP_CONTAIN_ANY,
          value: [products[2]],
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { min, max } }],
        },
      ]),
    },
  ]
}
