import { FormControl, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useAccount } from 'hooks/useAccount'
import { useTranslation } from 'react-i18next'
import { OP_INT_RANGE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { QueryItemProps } from './type'

export const MonetaryQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const { control } = useFormContext<QuerySetFormState>()

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '140px', marginRight: '12px' }}>
              <Select size='small' value={field.value} error={Boolean(error)} onChange={field.onChange}>
                <MenuItem key={OP_INT_RANGE} value={OP_INT_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_INT_RANGE })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />

      <IntRangeValue
        minValuePath={`${queryItemPath}.value.min`}
        maxValuePath={`${queryItemPath}.value.max`}
        inputAdornmentString={shop ? shop.currency : ''}
        inputPropsMaxValue={9999999}
      />
    </>
  )
}
