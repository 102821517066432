import { LoadingButton } from '@mui/lab'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
  handleClose: () => void
  id: string
  name: string
  tagOperationSettingIsActive: boolean
}

export const DeleteDialog: FC<Props> = ({ open, handleClose, id, name, tagOperationSettingIsActive }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const navigate = useNavigate()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await customerSegmentService.delete({ customerSegmentId: id }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.customerSegments.components.deleteDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.components.deleteDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '18px' }}>{t('features.customerSegments.components.deleteDialog.title')}</DialogTitle>
      <DialogContent>
        {tagOperationSettingIsActive && (
          <Alert severity='warning' sx={{ marginBottom: '16px' }}>
            {t('features.customerSegments.components.deleteDialog.alert')}
            <Link
              onClick={() => {
                const to = generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: id })
                navigate(`${to}#sync`)
              }}
              sx={{ cursor: 'pointer', marginLeft: '4px' }}
            >
              {t('features.customerSegments.components.deleteDialog.alertLinkText')}
            </Link>
          </Alert>
        )}
        <DialogContentText sx={{ fontSize: '14px' }}>
          {t('features.customerSegments.components.deleteDialog.description', { name: name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose} variant='text' color='inherit'>
          {t('features.customerSegments.components.deleteDialog.cancel')}
        </Button>
        <LoadingButton
          type='submit'
          disabled={tagOperationSettingIsActive}
          loading={isLoading}
          loadingPosition='center'
          onClick={handleSubmit}
          variant='contained'
        >
          {t('features.customerSegments.components.deleteDialog.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
