import { useTheme } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'
import { getDimensionIconImagePath } from 'utils/imageUtil'
import { QueryItemFormState } from '../../querySetForm/schema/querySetFormSchema'
import { FilterItems } from '../filterItem/filterItems'
import { ContainTypedCustomerQuery } from './containTypedCustomerQuery'
import { ContainTypedOrderQuery } from './containTypedOrderQuery'
import { FrequencyQuery } from './frequencyQuery'
import { OrderDateQuery } from './orderDateQuery'
import { PriceCustomerQuery } from './priceCustomerQuery'
import { PriceOrderQuery } from './priceOrderQuery'
import { PurchaseIntervalQuery } from './purchaseIntervalQuery'
import { RecencyQuery } from './recencyQuery'

type Props = {
  queryItem: QueryItemFormState
}

export const QueryItem: FC<Props> = ({ queryItem }) => {
  const theme = useTheme()

  const targetQueryItemForm = useMemo((): ReactNode => {
    const iconImgElement = <img src={getDimensionIconImagePath(queryItem.dimension, theme)} alt={queryItem.dimension} height='75%' />

    switch (queryItem.dimension) {
      case 'product':
      case 'product_variant':
      case 'product_type':
      case 'product_vendor':
      case 'coupon':
      case 'channel':
      case 'referrer':
      case 'landing_page':
      case 'utm_source':
      case 'utm_medium':
      case 'utm_campaign':
      case 'product_tag':
      case 'order_tag':
        return <ContainTypedOrderQuery queryItem={queryItem} icon={iconImgElement} />
      case 'country':
      case 'prefecture':
      case 'customer_tag':
        return <ContainTypedCustomerQuery queryItem={queryItem} icon={iconImgElement} />
      case 'order_date':
        return <OrderDateQuery queryItem={queryItem} icon={iconImgElement} />
      case 'order_value':
        return <PriceOrderQuery queryItem={queryItem} icon={iconImgElement} />
      case 'recency':
        return <RecencyQuery queryItem={queryItem} icon={iconImgElement} />
      case 'frequency':
        return <FrequencyQuery queryItem={queryItem} icon={iconImgElement} />
      case 'monetary':
      case 'aov':
        return <PriceCustomerQuery queryItem={queryItem} icon={iconImgElement} />
      case 'purchase_interval':
        return <PurchaseIntervalQuery queryItem={queryItem} icon={iconImgElement} />
      default:
        throw new Error(`invalid dimension: ${queryItem}`)
    }
  }, [queryItem, theme])

  return (
    <>
      {targetQueryItemForm}
      <FilterItems filterItems={queryItem.filters} />
    </>
  )
}
