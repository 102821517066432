import { Box, FormControl, ListSubheader, MenuItem, Select } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { defaultFiltersByDimension, defaultQueryItemByDimension } from '../defalutValues'
import { QueryItemFormState, QuerySetFormState } from '../schema/querySetFormSchema'
import { ChannelQuery } from './channelQuery'
import { CountryQuery } from './countryQuery'
import { CouponQuery } from './couponQuery'
import { CustomerTagQuery } from './customerTagQuery'
import { FrequencyQuery } from './frequencyQuery'
import { LandingPageQuery } from './landingPageQuery'
import { MonetaryQuery } from './monetaryQuery'
import { OrderDateQuery } from './orderDateQuery'
import { OrderTagQuery } from './orderTagQuery'
import { PrefectureQuery } from './prefectureQuery'
import { ProductQuery } from './productQuery'
import { ProductTagQuery } from './productTagQuery'
import { ProductTypeQuery } from './productTypeQuery'
import { ProductVariantQuery } from './productVariantQuery'
import { RecencyQuery } from './recencyQuery'
import { ReferrerQuery } from './referrerQuery'
import { QueryItemProps } from './type'
import { UtmCampaignQuery } from './utmCampaignQuery'
import { UtmMediumQuery } from './utmMediumQuery'
import { UtmSourceQuery } from './utmSourceQuery'
import { AOVQuery } from './aovQuery'
import { ProductVendorQuery } from './productVendorQuery'
import { OrderValueQuery } from './orderValueQuery'
import { PurchaseIntervalQuery } from './purchaseIntervalQuery'

export const QueryItem: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { watch, control, setValue } = useFormContext<QuerySetFormState>()
  const dimension = watch(`${queryItemPath}.dimension`)

  const resetOperatorAndValue = (_dimension: string) => {
    const dimension = _dimension as QueryItemFormState['dimension']
    const queryItem = defaultQueryItemByDimension[dimension]
    if (!queryItem) {
      throw new Error(`invalid dimension: ${dimension}`)
    }
    setValue(`${queryItemPath}.operator`, queryItem.operator)
    setValue(`${queryItemPath}.value`, queryItem.value)
    setValue(`${queryItemPath}.filters`, defaultFiltersByDimension[dimension])
  }

  const targetQueryItemForm = useMemo((): ReactNode => {
    switch (dimension) {
      case 'product':
        return <ProductQuery queryItemPath={queryItemPath} />
      case 'product_variant':
        return <ProductVariantQuery queryItemPath={queryItemPath} />
      case 'product_type':
        return <ProductTypeQuery queryItemPath={queryItemPath} />
      case 'product_tag':
        return <ProductTagQuery queryItemPath={queryItemPath} />
      case 'product_vendor':
        return <ProductVendorQuery queryItemPath={queryItemPath} />
      case 'order_tag':
        return <OrderTagQuery queryItemPath={queryItemPath} />
      case 'order_date':
        return <OrderDateQuery queryItemPath={queryItemPath} />
      case 'coupon':
        return <CouponQuery queryItemPath={queryItemPath} />
      case 'channel':
        return <ChannelQuery queryItemPath={queryItemPath} />
      case 'order_value':
        return <OrderValueQuery queryItemPath={queryItemPath} />
      case 'referrer':
        return <ReferrerQuery queryItemPath={queryItemPath} />
      case 'landing_page':
        return <LandingPageQuery queryItemPath={queryItemPath} />
      case 'utm_source':
        return <UtmSourceQuery queryItemPath={queryItemPath} />
      case 'utm_medium':
        return <UtmMediumQuery queryItemPath={queryItemPath} />
      case 'utm_campaign':
        return <UtmCampaignQuery queryItemPath={queryItemPath} />
      case 'country':
        return <CountryQuery queryItemPath={queryItemPath} />
      case 'prefecture':
        return <PrefectureQuery queryItemPath={queryItemPath} />
      case 'customer_tag':
        return <CustomerTagQuery queryItemPath={queryItemPath} />
      case 'recency':
        return <RecencyQuery queryItemPath={queryItemPath} />
      case 'frequency':
        return <FrequencyQuery queryItemPath={queryItemPath} />
      case 'monetary':
        return <MonetaryQuery queryItemPath={queryItemPath} />
      case 'aov':
        return <AOVQuery queryItemPath={queryItemPath} />
      case 'purchase_interval':
        return <PurchaseIntervalQuery queryItemPath={queryItemPath} />
      default:
        throw new Error(`invalid dimension: ${dimension}`)
    }
  }, [dimension, queryItemPath])

  return (
    <Box display='flex'>
      <Controller
        control={control}
        name={`${queryItemPath}.dimension`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px', marginRight: '12px' }}>
              <Select
                key='dimension'
                error={Boolean(error)}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  resetOperatorAndValue(e.target.value)
                }}
                size='small'
              >
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  {t('features.customerSegments.querySet.dimensionGroup', { context: 'product' })}
                </ListSubheader>
                {['product', 'product_type', 'product_tag', 'product_vendor', 'product_variant'].map((d) => (
                  <MenuItem key={d} value={d} dense>
                    {t('features.customerSegments.querySet.dimension', { context: d })}
                  </MenuItem>
                ))}
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  {t('features.customerSegments.querySet.dimensionGroup', { context: 'order' })}
                </ListSubheader>
                {['order_tag', 'channel', 'coupon', 'order_date', 'order_value'].map((d) => (
                  <MenuItem key={d} value={d} dense>
                    {t('features.customerSegments.querySet.dimension', { context: d })}
                  </MenuItem>
                ))}
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  {t('features.customerSegments.querySet.dimensionGroup', { context: 'ad' })}
                </ListSubheader>
                {['referrer', 'landing_page', 'utm_source', 'utm_medium', 'utm_campaign'].map((d) => (
                  <MenuItem key={d} value={d} dense>
                    {t('features.customerSegments.querySet.dimension', { context: d })}
                  </MenuItem>
                ))}
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  {t('features.customerSegments.querySet.dimensionGroup', { context: 'customer' })}
                </ListSubheader>
                {['customer_tag', 'country', 'prefecture'].map((d) => (
                  <MenuItem key={d} value={d} dense>
                    {t('features.customerSegments.querySet.dimension', { context: d })}
                  </MenuItem>
                ))}
                <ListSubheader sx={{ backgroundColor: 'transparent' }}>
                  {t('features.customerSegments.querySet.dimensionGroup', { context: 'rfm' })}
                </ListSubheader>
                {['recency', 'frequency', 'monetary', 'aov', 'purchase_interval'].map((d) => (
                  <MenuItem key={d} value={d} dense>
                    {t('features.customerSegments.querySet.dimension', { context: d })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }}
      />
      {targetQueryItemForm}
    </Box>
  )
}
