import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { BillingPlanIdentifier } from 'gen/firestore'
import { BillingService } from 'gen/proto/billing/billing_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
}

export const DowngradeToFreeDialog: FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const authUser = useAuthUser()
  const billingService = useGrpcClient(BillingService)

  const [loading, setLoading] = useState(false)

  const downgradeToFree = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await billingService.startChangePlanFlow({ plan: BillingPlanIdentifier.free }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.plan.downgradeToFreeDialog.messageSuccess'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.settings.plan.downgradeToFreeDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '18px' }}>{t('features.settings.plan.downgradeToFreeDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '14px' }}>{t('features.settings.plan.downgradeToFreeDialog.description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} variant='text' color='inherit'>
          {t('features.settings.plan.downgradeToFreeDialog.cancel')}
        </Button>
        <LoadingButton type='submit' loading={loading} loadingPosition='center' onClick={downgradeToFree}>
          {t('features.settings.plan.downgradeToFreeDialog.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
