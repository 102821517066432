import { OP_CONTAIN_ANY, OP_INT_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { FilterItemFormState, QueryItemFormState, QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { InsightDimension } from 'gen/firestore'

export const generateQuerySet = (
  insightDimension: InsightDimension,
  value: string,
  filters: FilterItemFormState[],
  additionalQuerySet?: QuerySetFormState
): QuerySetFormState | undefined => {
  let queryItem: QueryItemFormState

  switch (insightDimension) {
    case InsightDimension.product:
      queryItem = {
        dimension: 'product',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.productType:
      queryItem = {
        dimension: 'product_type',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.productVendor:
      queryItem = {
        dimension: 'product_vendor',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.productTag:
      queryItem = {
        dimension: 'product_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.productVariant:
      queryItem = {
        dimension: 'product_variant',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.referrer:
      queryItem = {
        dimension: 'referrer',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.landingPage:
      queryItem = {
        dimension: 'landing_page',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.utmSource:
      queryItem = {
        dimension: 'utm_source',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.utmMedium:
      queryItem = {
        dimension: 'utm_medium',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.utmCampaign:
      queryItem = {
        dimension: 'utm_campaign',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.channel:
      queryItem = {
        dimension: 'channel',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.orderTag:
      queryItem = {
        dimension: 'order_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.coupon:
      queryItem = {
        dimension: 'coupon',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: filters,
      }
      break
    case InsightDimension.country:
      queryItem = {
        dimension: 'country',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.province:
      queryItem = {
        dimension: 'prefecture',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    case InsightDimension.customerTag:
      queryItem = {
        dimension: 'customer_tag',
        operator: OP_CONTAIN_ANY,
        value: [value],
        filters: [],
      }
      break
    default:
      return undefined
  }

  const querySet: QuerySetFormState = {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: [queryItem],
      },
    ],
  }

  if (additionalQuerySet) {
    querySet.query_subsets.push(...additionalQuerySet.query_subsets)
  }

  return querySet
}

export const generateNumericQuerySet = (
  insightDimension: InsightDimension,
  min: number,
  max: number,
  filter: FilterItemFormState,
  additionalQuerySet?: QuerySetFormState
): QuerySetFormState | undefined => {
  let queryItem: QueryItemFormState

  switch (insightDimension) {
    case InsightDimension.frequency:
      queryItem = {
        dimension: 'frequency',
        operator: OP_INT_RANGE,
        value: { min: min, max: max },
        filters: [filter],
      }
      break
    case InsightDimension.recency:
      queryItem = {
        dimension: 'recency',
        operator: OP_INT_RANGE,
        value: { min: min, max: max },
        filters: [],
      }
      break
    case InsightDimension.monetary:
      queryItem = {
        dimension: 'monetary',
        operator: OP_INT_RANGE,
        value: { min: min, max: max },
        filters: [filter],
      }
      break
    case InsightDimension.aov:
      queryItem = {
        dimension: 'aov',
        operator: OP_INT_RANGE,
        value: { min: min, max: max },
        filters: [filter],
      }
      break
    // case InsightDimension.intervalDays:
    //   queryItem = {
    //     dimension: 'purchase_interval',
    //     operator: OP_INT_RANGE,
    //     value: { min: min, max: max },
    //     filters: [],
    //   }
    //   break
    default:
      return undefined
  }

  const querySet: QuerySetFormState = {
    join_operator: 'and',
    query_subsets: [
      {
        join_operator: 'and',
        query_items: [queryItem],
      },
    ],
  }

  if (additionalQuerySet) {
    querySet.query_subsets.push(...additionalQuerySet.query_subsets)
  }

  return querySet
}
