// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file proto/insight/insight.proto (package insight, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage} from "@bufbuild/protobuf";
import {Message, proto3, protoInt64} from "@bufbuild/protobuf";

/**
 * GetPopularityReport
 *
 * @generated from message insight.GetPopularityReportRequest
 */
export class GetPopularityReportRequest extends Message<GetPopularityReportRequest> {
  /**
   * Insight dimension
   *
   * @generated from field: string dimension = 1;
   */
  dimension = "";

  /**
   * @generated from field: string customerSegmentId = 2;
   */
  customerSegmentId = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate = "";

  constructor(data?: PartialMessage<GetPopularityReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetPopularityReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPopularityReportRequest {
    return new GetPopularityReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPopularityReportRequest {
    return new GetPopularityReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPopularityReportRequest {
    return new GetPopularityReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPopularityReportRequest | PlainMessage<GetPopularityReportRequest> | undefined, b: GetPopularityReportRequest | PlainMessage<GetPopularityReportRequest> | undefined): boolean {
    return proto3.util.equals(GetPopularityReportRequest, a, b);
  }
}

/**
 * @generated from message insight.GetPopularityReportResponse
 */
export class GetPopularityReportResponse extends Message<GetPopularityReportResponse> {
  /**
   * @generated from field: repeated insight.GetPopularityReportResponse.InsightValue values = 1;
   */
  values: GetPopularityReportResponse_InsightValue[] = [];

  constructor(data?: PartialMessage<GetPopularityReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetPopularityReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: GetPopularityReportResponse_InsightValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPopularityReportResponse {
    return new GetPopularityReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPopularityReportResponse {
    return new GetPopularityReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPopularityReportResponse {
    return new GetPopularityReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPopularityReportResponse | PlainMessage<GetPopularityReportResponse> | undefined, b: GetPopularityReportResponse | PlainMessage<GetPopularityReportResponse> | undefined): boolean {
    return proto3.util.equals(GetPopularityReportResponse, a, b);
  }
}

/**
 * @generated from message insight.GetPopularityReportResponse.InsightValue
 */
export class GetPopularityReportResponse_InsightValue extends Message<GetPopularityReportResponse_InsightValue> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 customerCount = 2;
   */
  customerCount = protoInt64.zero;

  /**
   * @generated from field: float ratio = 3;
   */
  ratio = 0;

  constructor(data?: PartialMessage<GetPopularityReportResponse_InsightValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetPopularityReportResponse.InsightValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "ratio", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPopularityReportResponse_InsightValue {
    return new GetPopularityReportResponse_InsightValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPopularityReportResponse_InsightValue {
    return new GetPopularityReportResponse_InsightValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPopularityReportResponse_InsightValue {
    return new GetPopularityReportResponse_InsightValue().fromJsonString(jsonString, options);
  }

  static equals(a: GetPopularityReportResponse_InsightValue | PlainMessage<GetPopularityReportResponse_InsightValue> | undefined, b: GetPopularityReportResponse_InsightValue | PlainMessage<GetPopularityReportResponse_InsightValue> | undefined): boolean {
    return proto3.util.equals(GetPopularityReportResponse_InsightValue, a, b);
  }
}

/**
 * GetMonthlyReport
 *
 * @generated from message insight.GetMonthlyReportRequest
 */
export class GetMonthlyReportRequest extends Message<GetMonthlyReportRequest> {
  /**
   * Insight dimension
   *
   * @generated from field: string dimension = 1;
   */
  dimension = "";

  /**
   * @generated from field: string customerSegmentId = 2;
   */
  customerSegmentId = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate = "";

  constructor(data?: PartialMessage<GetMonthlyReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetMonthlyReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMonthlyReportRequest {
    return new GetMonthlyReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMonthlyReportRequest {
    return new GetMonthlyReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMonthlyReportRequest {
    return new GetMonthlyReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMonthlyReportRequest | PlainMessage<GetMonthlyReportRequest> | undefined, b: GetMonthlyReportRequest | PlainMessage<GetMonthlyReportRequest> | undefined): boolean {
    return proto3.util.equals(GetMonthlyReportRequest, a, b);
  }
}

/**
 * @generated from message insight.GetMonthlyReportResponse
 */
export class GetMonthlyReportResponse extends Message<GetMonthlyReportResponse> {
  /**
   * @generated from field: repeated insight.GetMonthlyReportResponse.InsightValue values = 1;
   */
  values: GetMonthlyReportResponse_InsightValue[] = [];

  constructor(data?: PartialMessage<GetMonthlyReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetMonthlyReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: GetMonthlyReportResponse_InsightValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMonthlyReportResponse {
    return new GetMonthlyReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMonthlyReportResponse {
    return new GetMonthlyReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMonthlyReportResponse {
    return new GetMonthlyReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMonthlyReportResponse | PlainMessage<GetMonthlyReportResponse> | undefined, b: GetMonthlyReportResponse | PlainMessage<GetMonthlyReportResponse> | undefined): boolean {
    return proto3.util.equals(GetMonthlyReportResponse, a, b);
  }
}

/**
 * @generated from message insight.GetMonthlyReportResponse.MonthlyValue
 */
export class GetMonthlyReportResponse_MonthlyValue extends Message<GetMonthlyReportResponse_MonthlyValue> {
  /**
   * YYYY-MM
   *
   * @generated from field: string yearMonth = 1;
   */
  yearMonth = "";

  /**
   * @generated from field: int64 customerCount = 2;
   */
  customerCount = protoInt64.zero;

  constructor(data?: PartialMessage<GetMonthlyReportResponse_MonthlyValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetMonthlyReportResponse.MonthlyValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "yearMonth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMonthlyReportResponse_MonthlyValue {
    return new GetMonthlyReportResponse_MonthlyValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMonthlyReportResponse_MonthlyValue {
    return new GetMonthlyReportResponse_MonthlyValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMonthlyReportResponse_MonthlyValue {
    return new GetMonthlyReportResponse_MonthlyValue().fromJsonString(jsonString, options);
  }

  static equals(a: GetMonthlyReportResponse_MonthlyValue | PlainMessage<GetMonthlyReportResponse_MonthlyValue> | undefined, b: GetMonthlyReportResponse_MonthlyValue | PlainMessage<GetMonthlyReportResponse_MonthlyValue> | undefined): boolean {
    return proto3.util.equals(GetMonthlyReportResponse_MonthlyValue, a, b);
  }
}

/**
 * @generated from message insight.GetMonthlyReportResponse.InsightValue
 */
export class GetMonthlyReportResponse_InsightValue extends Message<GetMonthlyReportResponse_InsightValue> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated insight.GetMonthlyReportResponse.MonthlyValue monthlyValues = 2;
   */
  monthlyValues: GetMonthlyReportResponse_MonthlyValue[] = [];

  constructor(data?: PartialMessage<GetMonthlyReportResponse_InsightValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetMonthlyReportResponse.InsightValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "monthlyValues", kind: "message", T: GetMonthlyReportResponse_MonthlyValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMonthlyReportResponse_InsightValue {
    return new GetMonthlyReportResponse_InsightValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMonthlyReportResponse_InsightValue {
    return new GetMonthlyReportResponse_InsightValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMonthlyReportResponse_InsightValue {
    return new GetMonthlyReportResponse_InsightValue().fromJsonString(jsonString, options);
  }

  static equals(a: GetMonthlyReportResponse_InsightValue | PlainMessage<GetMonthlyReportResponse_InsightValue> | undefined, b: GetMonthlyReportResponse_InsightValue | PlainMessage<GetMonthlyReportResponse_InsightValue> | undefined): boolean {
    return proto3.util.equals(GetMonthlyReportResponse_InsightValue, a, b);
  }
}

/**
 * GetNthOrderReport
 *
 * @generated from message insight.GetNthOrderReportRequest
 */
export class GetNthOrderReportRequest extends Message<GetNthOrderReportRequest> {
  /**
   * Insight dimension
   *
   * @generated from field: string dimension = 1;
   */
  dimension = "";

  /**
   * @generated from field: string customerSegmentId = 2;
   */
  customerSegmentId = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate = "";

  constructor(data?: PartialMessage<GetNthOrderReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetNthOrderReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetNthOrderReportRequest {
    return new GetNthOrderReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetNthOrderReportRequest {
    return new GetNthOrderReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetNthOrderReportRequest {
    return new GetNthOrderReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetNthOrderReportRequest | PlainMessage<GetNthOrderReportRequest> | undefined, b: GetNthOrderReportRequest | PlainMessage<GetNthOrderReportRequest> | undefined): boolean {
    return proto3.util.equals(GetNthOrderReportRequest, a, b);
  }
}

/**
 * @generated from message insight.GetNthOrderReportResponse
 */
export class GetNthOrderReportResponse extends Message<GetNthOrderReportResponse> {
  /**
   * @generated from field: repeated insight.GetNthOrderReportResponse.InsightValue values = 1;
   */
  values: GetNthOrderReportResponse_InsightValue[] = [];

  constructor(data?: PartialMessage<GetNthOrderReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetNthOrderReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: GetNthOrderReportResponse_InsightValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetNthOrderReportResponse {
    return new GetNthOrderReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetNthOrderReportResponse {
    return new GetNthOrderReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetNthOrderReportResponse {
    return new GetNthOrderReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetNthOrderReportResponse | PlainMessage<GetNthOrderReportResponse> | undefined, b: GetNthOrderReportResponse | PlainMessage<GetNthOrderReportResponse> | undefined): boolean {
    return proto3.util.equals(GetNthOrderReportResponse, a, b);
  }
}

/**
 * @generated from message insight.GetNthOrderReportResponse.InsightValue
 */
export class GetNthOrderReportResponse_InsightValue extends Message<GetNthOrderReportResponse_InsightValue> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 first = 2;
   */
  first = protoInt64.zero;

  /**
   * @generated from field: int64 second = 3;
   */
  second = protoInt64.zero;

  /**
   * @generated from field: int64 secondPlus = 4;
   */
  secondPlus = protoInt64.zero;

  /**
   * @generated from field: int64 third = 5;
   */
  third = protoInt64.zero;

  /**
   * @generated from field: int64 fourth = 6;
   */
  fourth = protoInt64.zero;

  /**
   * @generated from field: int64 fifthPlus = 7;
   */
  fifthPlus = protoInt64.zero;

  /**
   * @generated from field: int64 total = 8;
   */
  total = protoInt64.zero;

  constructor(data?: PartialMessage<GetNthOrderReportResponse_InsightValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetNthOrderReportResponse.InsightValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "second", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "secondPlus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "third", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "fourth", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "fifthPlus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetNthOrderReportResponse_InsightValue {
    return new GetNthOrderReportResponse_InsightValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetNthOrderReportResponse_InsightValue {
    return new GetNthOrderReportResponse_InsightValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetNthOrderReportResponse_InsightValue {
    return new GetNthOrderReportResponse_InsightValue().fromJsonString(jsonString, options);
  }

  static equals(a: GetNthOrderReportResponse_InsightValue | PlainMessage<GetNthOrderReportResponse_InsightValue> | undefined, b: GetNthOrderReportResponse_InsightValue | PlainMessage<GetNthOrderReportResponse_InsightValue> | undefined): boolean {
    return proto3.util.equals(GetNthOrderReportResponse_InsightValue, a, b);
  }
}

/**
 * GetOrderCountReport
 *
 * @generated from message insight.GetOrderCountReportRequest
 */
export class GetOrderCountReportRequest extends Message<GetOrderCountReportRequest> {
  /**
   * Insight dimension
   *
   * @generated from field: string dimension = 1;
   */
  dimension = "";

  /**
   * @generated from field: string customerSegmentId = 2;
   */
  customerSegmentId = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate = "";

  constructor(data?: PartialMessage<GetOrderCountReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetOrderCountReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderCountReportRequest {
    return new GetOrderCountReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderCountReportRequest {
    return new GetOrderCountReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderCountReportRequest {
    return new GetOrderCountReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderCountReportRequest | PlainMessage<GetOrderCountReportRequest> | undefined, b: GetOrderCountReportRequest | PlainMessage<GetOrderCountReportRequest> | undefined): boolean {
    return proto3.util.equals(GetOrderCountReportRequest, a, b);
  }
}

/**
 * @generated from message insight.GetOrderCountReportResponse
 */
export class GetOrderCountReportResponse extends Message<GetOrderCountReportResponse> {
  /**
   * @generated from field: repeated insight.GetOrderCountReportResponse.InsightValue values = 1;
   */
  values: GetOrderCountReportResponse_InsightValue[] = [];

  constructor(data?: PartialMessage<GetOrderCountReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetOrderCountReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: GetOrderCountReportResponse_InsightValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderCountReportResponse {
    return new GetOrderCountReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderCountReportResponse {
    return new GetOrderCountReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderCountReportResponse {
    return new GetOrderCountReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderCountReportResponse | PlainMessage<GetOrderCountReportResponse> | undefined, b: GetOrderCountReportResponse | PlainMessage<GetOrderCountReportResponse> | undefined): boolean {
    return proto3.util.equals(GetOrderCountReportResponse, a, b);
  }
}

/**
 * @generated from message insight.GetOrderCountReportResponse.InsightValue
 */
export class GetOrderCountReportResponse_InsightValue extends Message<GetOrderCountReportResponse_InsightValue> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 once = 2;
   */
  once = protoInt64.zero;

  /**
   * @generated from field: int64 twice = 3;
   */
  twice = protoInt64.zero;

  /**
   * @generated from field: int64 twicePlus = 4;
   */
  twicePlus = protoInt64.zero;

  /**
   * @generated from field: int64 threeTimes = 5;
   */
  threeTimes = protoInt64.zero;

  /**
   * @generated from field: int64 fourTimes = 6;
   */
  fourTimes = protoInt64.zero;

  /**
   * @generated from field: int64 fiveTimesPlus = 7;
   */
  fiveTimesPlus = protoInt64.zero;

  /**
   * @generated from field: int64 total = 8;
   */
  total = protoInt64.zero;

  constructor(data?: PartialMessage<GetOrderCountReportResponse_InsightValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetOrderCountReportResponse.InsightValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "once", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "twice", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "twicePlus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "threeTimes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "fourTimes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "fiveTimesPlus", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrderCountReportResponse_InsightValue {
    return new GetOrderCountReportResponse_InsightValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrderCountReportResponse_InsightValue {
    return new GetOrderCountReportResponse_InsightValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrderCountReportResponse_InsightValue {
    return new GetOrderCountReportResponse_InsightValue().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrderCountReportResponse_InsightValue | PlainMessage<GetOrderCountReportResponse_InsightValue> | undefined, b: GetOrderCountReportResponse_InsightValue | PlainMessage<GetOrderCountReportResponse_InsightValue> | undefined): boolean {
    return proto3.util.equals(GetOrderCountReportResponse_InsightValue, a, b);
  }
}

/**
 * GetHistogramReport
 *
 * @generated from message insight.GetHistogramReportRequest
 */
export class GetHistogramReportRequest extends Message<GetHistogramReportRequest> {
  /**
   * Insight dimension
   *
   * @generated from field: string dimension = 1;
   */
  dimension = "";

  /**
   * @generated from field: string customerSegmentId = 2;
   */
  customerSegmentId = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string startDate = 3;
   */
  startDate = "";

  /**
   * YYYY-MM-DD
   *
   * @generated from field: string endDate = 4;
   */
  endDate = "";

  constructor(data?: PartialMessage<GetHistogramReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetHistogramReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerSegmentId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "startDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "endDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHistogramReportRequest {
    return new GetHistogramReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHistogramReportRequest {
    return new GetHistogramReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHistogramReportRequest {
    return new GetHistogramReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetHistogramReportRequest | PlainMessage<GetHistogramReportRequest> | undefined, b: GetHistogramReportRequest | PlainMessage<GetHistogramReportRequest> | undefined): boolean {
    return proto3.util.equals(GetHistogramReportRequest, a, b);
  }
}

/**
 * @generated from message insight.GetHistogramReportResponse
 */
export class GetHistogramReportResponse extends Message<GetHistogramReportResponse> {
  /**
   * @generated from field: repeated insight.GetHistogramReportResponse.InsightValue values = 1;
   */
  values: GetHistogramReportResponse_InsightValue[] = [];

  /**
   * @generated from field: int64 mean = 2;
   */
  mean = protoInt64.zero;

  /**
   * @generated from field: int64 median = 3;
   */
  median = protoInt64.zero;

  /**
   * @generated from field: int64 max = 4;
   */
  max = protoInt64.zero;

  constructor(data?: PartialMessage<GetHistogramReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetHistogramReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "message", T: GetHistogramReportResponse_InsightValue, repeated: true },
    { no: 2, name: "mean", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "median", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "max", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHistogramReportResponse {
    return new GetHistogramReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHistogramReportResponse {
    return new GetHistogramReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHistogramReportResponse {
    return new GetHistogramReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetHistogramReportResponse | PlainMessage<GetHistogramReportResponse> | undefined, b: GetHistogramReportResponse | PlainMessage<GetHistogramReportResponse> | undefined): boolean {
    return proto3.util.equals(GetHistogramReportResponse, a, b);
  }
}

/**
 * @generated from message insight.GetHistogramReportResponse.InsightValue
 */
export class GetHistogramReportResponse_InsightValue extends Message<GetHistogramReportResponse_InsightValue> {
  /**
   * @generated from field: int64 min = 1;
   */
  min = protoInt64.zero;

  /**
   * @generated from field: int64 max = 2;
   */
  max = protoInt64.zero;

  /**
   * @generated from field: int64 customerCount = 3;
   */
  customerCount = protoInt64.zero;

  constructor(data?: PartialMessage<GetHistogramReportResponse_InsightValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "insight.GetHistogramReportResponse.InsightValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "max", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "customerCount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHistogramReportResponse_InsightValue {
    return new GetHistogramReportResponse_InsightValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHistogramReportResponse_InsightValue {
    return new GetHistogramReportResponse_InsightValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHistogramReportResponse_InsightValue {
    return new GetHistogramReportResponse_InsightValue().fromJsonString(jsonString, options);
  }

  static equals(a: GetHistogramReportResponse_InsightValue | PlainMessage<GetHistogramReportResponse_InsightValue> | undefined, b: GetHistogramReportResponse_InsightValue | PlainMessage<GetHistogramReportResponse_InsightValue> | undefined): boolean {
    return proto3.util.equals(GetHistogramReportResponse_InsightValue, a, b);
  }
}

