import dayjs from 'dayjs'
import { EstimateMonthlyCohortResponse, EstimateMonthlyCohortResponse_StepAndMetrics } from 'gen/proto/customer_segment/customer_segment_pb'
import { formatYearMonth } from 'utils/timeUtil'

export type MonthlyCohortSummaryType = {
  monthlyResult: MonthlyResultType[]
  avgResult: MonthlyStepAndMetricsType[]
}

type MonthlyResultType = {
  firstOrderYearMonth: string
  stepAndMetricsList: MonthlyStepAndMetricsType[]
}

type MonthlyStepAndMetricsType = {
  step: number
  ltv: number
  orderValue: number
  customerCount: number
  f2CustomerCount: number
  f3CustomerCount: number
  f4CustomerCount: number
  f5CustomerCount: number
  f2RepeatRate: number
  f3RepeatRate: number
  f4RepeatRate: number
  f5RepeatRate: number
}

export const convertMonthlyCohortSummary = (monthlyCohortSummary: EstimateMonthlyCohortResponse, lang: string): MonthlyCohortSummaryType => {
  return {
    monthlyResult: monthlyCohortSummary.monthlyResult.map((mr) => {
      return {
        firstOrderYearMonth: formatYearMonth(dayjs(mr.firstOrderYearMonth), lang),
        stepAndMetricsList: convertMonthlyStepAndMetricsList(mr.stepAndMetricsList),
      }
    }),
    avgResult: convertMonthlyStepAndMetricsList(monthlyCohortSummary.avgResult),
  }
}

const convertMonthlyStepAndMetricsList = (smList: EstimateMonthlyCohortResponse_StepAndMetrics[]): MonthlyStepAndMetricsType[] => {
  return smList.map((sm) => {
    return {
      step: Number(sm.step),
      ltv: Number(sm.ltv),
      orderValue: Number(sm.orderValue),
      customerCount: Number(sm.customerCount),
      f2CustomerCount: Number(sm.f2CustomerCount),
      f3CustomerCount: Number(sm.f3CustomerCount),
      f4CustomerCount: Number(sm.f4CustomerCount),
      f5CustomerCount: Number(sm.f5CustomerCount),
      f2RepeatRate: sm.customerCount > 0 ? Number(sm.f2CustomerCount) / Number(sm.customerCount) : 0,
      f3RepeatRate: sm.customerCount > 0 ? Number(sm.f3CustomerCount) / Number(sm.customerCount) : 0,
      f4RepeatRate: sm.customerCount > 0 ? Number(sm.f4CustomerCount) / Number(sm.customerCount) : 0,
      f5RepeatRate: sm.customerCount > 0 ? Number(sm.f5CustomerCount) / Number(sm.customerCount) : 0,
    }
  })
}
