import { useTheme } from '@mui/material/styles'
import { CustomerMetrics } from 'gen/firestore'
import { useCurrency } from 'hooks/useCurrency'
import { FC } from 'react'
import Chart from 'react-apexcharts'
import { MetricsHistory } from '../../../../types/types'

type Props = {
  metricsHistory: MetricsHistory[]
  index: keyof CustomerMetrics
  indexLabel: string
}

export const MetricsHistoryChart: FC<Props> = ({ metricsHistory, index, indexLabel }) => {
  const theme = useTheme()
  const { formatCurrency } = useCurrency()

  return (
    <Chart
      type='line'
      height={400}
      series={[
        {
          name: indexLabel,
          data: metricsHistory.map((r) => ({ x: r.date, y: r.metrics[index] })),
        },
      ]}
      options={{
        xaxis: {
          labels: {
            style: {
              colors: theme.palette.text.primary,
            },
          },
          tickAmount: 20,
        },
        yaxis: {
          labels: {
            style: {
              colors: theme.palette.text.primary,
            },
            formatter: (value) => {
              if (index === 'count') {
                return value.toFixed(0)
              } else {
                return value.toFixed(2)
              }
            },
          },
        },
        tooltip: {
          theme: theme.palette.mode,
          y: {
            formatter: (y) => {
              if (index === 'ltv' || index === 'avgOrderValue') {
                return formatCurrency(y) || y.toLocaleString()
              } else {
                return y.toLocaleString()
              }
            },
          },
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        markers: {
          size: metricsHistory.length < 60 ? 4 : 0,
        },
        chart: {
          zoom: { enabled: true },
          toolbar: { show: false },
        },
        colors: [theme.palette.primary.main],
      }}
    />
  )
}
