import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Checkbox, Dialog, FormControl, TextField, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { SegmentGroup } from 'gen/firestore'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type Props = {
  open: boolean
  group: SegmentGroup
  handleClose: () => void
}

export const AddSegmentDialog: FC<Props> = ({ open, group, handleClose }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const segmentGroupService = useGrpcClient(SegmentGroupService)

  const { account } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data, loading } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const customerSegments = useMemo(() => {
    const segmentRefs = group.customerSegmentRefs || []
    return data?.filter((cs) => !segmentRefs.some((ref) => ref.id === cs.ref.id)) || []
  }, [data, group.customerSegmentRefs])

  const input = z.object({
    customerSegmentIds: z.array(z.string()).min(1, t('features.customerSegments.group.addSegmentDialog.messageValidationSegmentListMin')),
  })

  type InputSchema = z.infer<typeof input>

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<InputSchema>({
    resolver: zodResolver(input),
    defaultValues: {
      customerSegmentIds: [],
    },
  })

  const addSegments = async (input: InputSchema) => {
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.addSegments(
        {
          segmentGroupId: group.ref.id,
          customerSegmentIds: input.customerSegmentIds,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.group.addSegmentDialog.messageSaved'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.group.addSegmentDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box padding='20px'>
        <Box marginBottom='20px'>
          <Typography>{t('features.customerSegments.group.addSegmentDialog.title')}</Typography>
        </Box>

        <Box marginBottom='20px'>
          <Controller
            control={control}
            name='customerSegmentIds'
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl sx={{ width: '540px' }}>
                  <Autocomplete
                    {...field}
                    multiple
                    disableCloseOnSelect
                    loading={loading}
                    disabled={!customerSegments}
                    options={customerSegments || []}
                    getOptionLabel={(cl) => cl.name}
                    onChange={(_, newValue) => {
                      setValue(
                        'customerSegmentIds',
                        newValue.map((item) => item.ref.id)
                      )
                    }}
                    value={customerSegments?.filter((cl) => field.value.includes(cl.ref.id)) || []}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox size='small' style={{ marginRight: '12px' }} checked={selected} />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(error)}
                        helperText={error?.message}
                        label={t('features.customerSegments.group.addSegmentDialog.fieldCustomerSegments')}
                        size='small'
                      />
                    )}
                  />
                </FormControl>
              )
            }}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end'>
          <Button disabled={isSubmitting} onClick={handleClose} variant='text' color='inherit' sx={{ marginRight: '4px' }}>
            {t('features.customerSegments.group.addSegmentDialog.cancel')}
          </Button>
          <LoadingButton type='submit' loading={isSubmitting} loadingPosition='center' onClick={handleSubmit(addSegments)} variant='contained'>
            {t('features.customerSegments.group.addSegmentDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
