// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/account/account.proto (package account, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {UpdateDisplayModeRequest, UpdateDisplayModeResponse, UpdateEmailRequest, UpdateEmailResponse, UpdateLanguageRequest, UpdateLanguageResponse} from "./account_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service account.AccountService
 */
export const AccountService = {
  typeName: "account.AccountService",
  methods: {
    /**
     * @generated from rpc account.AccountService.UpdateEmail
     */
    updateEmail: {
      name: "UpdateEmail",
      I: UpdateEmailRequest,
      O: UpdateEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc account.AccountService.UpdateLanguage
     */
    updateLanguage: {
      name: "UpdateLanguage",
      I: UpdateLanguageRequest,
      O: UpdateLanguageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc account.AccountService.UpdateDisplayMode
     */
    updateDisplayMode: {
      name: "UpdateDisplayMode",
      I: UpdateDisplayModeRequest,
      O: UpdateDisplayModeResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

