import { FormControl, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { OP_EQUAL, OP_INT_RANGE, OP_NOT_EQUAL } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { IntValue } from '../value/intValue'
import { QueryItemProps } from './type'
import { useTranslation } from 'react-i18next'

export const FrequencyQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${queryItemPath}.operator`)

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '140px', marginRight: '12px' }}>
              <Select size='small' value={field.value} error={Boolean(error)} onChange={field.onChange}>
                <MenuItem key={OP_INT_RANGE} value={OP_INT_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_INT_RANGE })}
                </MenuItem>
                <MenuItem key={OP_EQUAL} value={OP_EQUAL}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_EQUAL })}
                </MenuItem>
                <MenuItem key={OP_NOT_EQUAL} value={OP_NOT_EQUAL}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_NOT_EQUAL })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />

      {(operator === OP_EQUAL || operator === OP_NOT_EQUAL) && (
        <IntValue
          queryItemValuePath={`${queryItemPath}.value`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixCount')}
          inputPropsMaxValue={99}
        />
      )}

      {operator === OP_INT_RANGE && (
        <IntRangeValue
          minValuePath={`${queryItemPath}.value.min`}
          maxValuePath={`${queryItemPath}.value.max`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixCount')}
          inputPropsMaxValue={99}
        />
      )}
    </>
  )
}
