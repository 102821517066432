import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, CircularProgress, Grid, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from 'features/customerSegments/querySetForm/schema/converter'
import { InsightDrawerState, InsightFilterState, chartColors, chartColorsDark } from 'features/insights/types/insight'
import { generateNumericQuerySet } from 'features/insights/types/querySet'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_connectweb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { getImagePath } from 'utils/imageUtil'
import { InsightDrawer } from './components/insightDrawer'

const VISUAL_PAPER_HEIGHT = '70vh'

type Props = {
  dimension: InsightDimension
  filter: InsightFilterState
}

type HistogramInsightValue = {
  values: {
    min: number
    max: number
    customerCount: number
  }[]
  mean: number
  median: number
  max: number
}

export const HistogramInsight: FC<Props> = ({ dimension, filter }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const authUser = useAuthUser()
  const { account, shop } = useAccount()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)

  const [loading, setLoading] = useState(false)
  const [insightValues, setInsightValues] = useState<HistogramInsightValue>()
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [insightDrawerState, setInsightDrawerState] = useState<InsightDrawerState>({ open: false })

  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  const handleChartDataPointClick = (min: number, max: number) => {
    const queryFilter = {
      filter_type: 'order_date' as const,
      operator: 'date_range' as const,
      value: { min_date: filter.startDate, max_date: filter.endDate },
    }
    const segmentQuerySet = customerSegments?.find((segment) => segment.ref.id === filter.customerSegmentId)?.querySet
    const additionalQuerySet = segmentQuerySet ? convertQuerySetToForm(convertStringToApiQuerySetState(segmentQuerySet)) : undefined
    const querySet = generateNumericQuerySet(dimension, min, max, queryFilter, additionalQuerySet)
    if (querySet) {
      setInsightDrawerState({ open: true, querySet })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await insightService.getHistogramReport(
          {
            customerSegmentId: filter.customerSegmentId || '',
            dimension: dimension,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setInsightValues({
          values: resp.values.map((v) => ({
            min: Number(v.min),
            max: Number(v.max),
            customerCount: Number(v.customerCount),
          })),
          mean: Number(resp.mean),
          median: Number(resp.median),
          max: Number(resp.max),
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, filter])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: insightValues?.values?.map(({ min, max, customerCount }) => ({
      range: `${min} - ${max}`,
      customerCount,
    })),
    headers: [t('features.insights.detail.histogramInsight.range'), t('features.insights.detail.customerCount')],
    fileName: t('features.insights.detail.histogramInsight.downloadFileName', { dimension: dimension }),
    page: 'insight_' + dimension,
  })

  return (
    <>
      <Box display='flex' justifyContent='flex-end' alignItems='center' marginTop='-52px' marginBottom='16px'>
        <Tooltip title={t('features.insights.detail.download')} placement='top'>
          <span>
            <IconButton
              disabled={!insightValues || insightValues.values.length === 0 || downloadLoading}
              onClick={() => {
                if (hasProAccess(shop)) {
                  handleDownload()
                  return
                }
                setUpgradeRecommendDialogOpen(true)
              }}
            >
              <FileDownloadOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {loading || !insightValues ? (
            <Paper
              variant='outlined'
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: VISUAL_PAPER_HEIGHT, borderRadius: '12px' }}
            >
              <CircularProgress />
            </Paper>
          ) : insightValues.values.length === 0 ? (
            <Paper
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: VISUAL_PAPER_HEIGHT,
                borderRadius: '12px',
              }}
            >
              <img src={theme.palette.mode === 'dark' ? getImagePath('empty_dark.svg') : getImagePath('empty.svg')} alt='Empty State' width='240px' />
              <Typography fontSize='16px' marginTop='12px'>
                {t('features.insights.detail.noData')}
              </Typography>
            </Paper>
          ) : (
            <Paper variant='outlined' sx={{ padding: '20px', borderRadius: '12px', height: VISUAL_PAPER_HEIGHT }}>
              <Chart
                type='bar'
                height='100%'
                series={[
                  {
                    name: t('features.insights.detail.customerCount'),
                    data: insightValues.values.map((v) => ({
                      x: `${v.min} - ${v.max}`,
                      y: v.customerCount,
                    })),
                  },
                ]}
                options={{
                  xaxis: {
                    type: 'category',
                    categories: insightValues.values.map((v) => `${v.min} - ${v.max}`),
                    title: {
                      text: t('features.insights.dimension', { context: dimension }),
                      style: { color: theme.palette.text.primary },
                    },
                    labels: { style: { colors: theme.palette.text.primary } },
                  },
                  yaxis: {
                    title: {
                      text: t('features.insights.detail.customerCount'),
                      style: { color: theme.palette.text.primary },
                    },
                    labels: { style: { colors: theme.palette.text.primary } },
                  },
                  chart: {
                    toolbar: { show: false },
                    events: {
                      dataPointSelection: (_, __, config) => {
                        const selectedValue = insightValues.values[config.dataPointIndex]
                        handleChartDataPointClick(selectedValue.min, selectedValue.max)
                        document.body.style.cursor = 'default'
                      },
                      dataPointMouseEnter: () => {
                        document.body.style.cursor = 'pointer'
                      },
                      dataPointMouseLeave: () => {
                        document.body.style.cursor = 'default'
                      },
                    },
                  },
                  tooltip: { theme: theme.palette.mode },
                  dataLabels: { enabled: false },
                  colors: theme.palette.mode === 'dark' ? chartColorsDark : chartColors,
                }}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2} sx={{ height: VISUAL_PAPER_HEIGHT }}>
            {['mean', 'median', 'max'].map((stat) => (
              <Grid item xs={12} key={stat}>
                <Paper
                  variant='outlined'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '20px',
                    borderRadius: '12px',
                    height: '100%',
                  }}
                >
                  <Box>{t(`features.insights.detail.histogramInsight.${stat}`)}</Box>
                  <Box fontSize='24px' fontWeight='bold' marginTop='8px' display='flex' justifyContent='center'>
                    {!loading && insightValues ? (
                      insightValues[stat as keyof HistogramInsightValue].toString()
                    ) : (
                      <Skeleton height='20px' width='50%' />
                    )}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog open={upgradeRecommendDialogOpen} handleClose={() => setUpgradeRecommendDialogOpen(false)} referrer='feature' />
      )}

      <InsightDrawer
        open={insightDrawerState.open}
        handleOpen={() => setInsightDrawerState({ open: true })}
        handleClose={() => setInsightDrawerState({ open: false })}
        querySet={insightDrawerState.querySet}
      />
    </>
  )
}
