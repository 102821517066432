import { env } from 'config/env'
import { useEffect } from 'react'
import ChannelTalkClient from 'utils/channelTalk'
import { useAccount } from './useAccount'

const CHANNEL_TALK_KEY = 'bc861b81-d3b8-4acf-bf24-ffc015ff7da8'

export const useInitChannelTalk = (): void => {
  const { account, shop } = useAccount()

  useEffect(() => {
    const initChannelTalk = async () => {
      if (!account || !shop) return

      const payload = {
        pluginKey: CHANNEL_TALK_KEY,
        memberId: account.ref.id,
        language: account.language,
        profile: {
          id: account.ref.id,
          email: account.email,
          shopName: shop.shopName,
          shopDisplayName: shop.displayName,
        },
      }
      ChannelTalkClient.boot(payload)
      if (env.DEBUG_LOG) {
        console.log('channel talk was initialized!', payload)
      }
    }

    initChannelTalk()
  }, [account, shop])
}
