import { OP_CONTAIN_ANY, OP_DATE_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useHolidaySegments = (): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_holiday_lunarNewYear'),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: ['China', 'Hong Kong', 'Taiwan', 'Malaysia', 'Singapore'],
          filters: [],
        },
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2024-02-09', max_date: '2024-02-17' },
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_holiday_easter'),
      querySet: generateQuerySet([
        {
          dimension: 'country',
          operator: OP_CONTAIN_ANY,
          value: ['United States', 'Canada'],
          filters: [],
        },
        {
          dimension: 'order_date',
          operator: OP_DATE_RANGE,
          value: { min_date: '2024-03-29', max_date: '2024-04-02' },
          filters: [],
        },
      ]),
    },
  ]
}
