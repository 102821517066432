// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/segment_group/segment_group.proto (package segment_group, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {AddSegmentsRequest, AddSegmentsResponse, CreateRequest, CreateResponse, DeleteRequest, DeleteResponse, RemoveSegmentsRequest, RemoveSegmentsResponse, UpdateCanvasStateRequest, UpdateCanvasStateResponse, UpdateRequest, UpdateResponse, UpdateSegmentsSequenceRequest, UpdateSegmentsSequenceResponse} from "./segment_group_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service segment_group.SegmentGroupService
 */
export const SegmentGroupService = {
  typeName: "segment_group.SegmentGroupService",
  methods: {
    /**
     * @generated from rpc segment_group.SegmentGroupService.Create
     */
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_group.SegmentGroupService.Update
     */
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_group.SegmentGroupService.Delete
     */
    delete: {
      name: "Delete",
      I: DeleteRequest,
      O: DeleteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_group.SegmentGroupService.AddSegments
     */
    addSegments: {
      name: "AddSegments",
      I: AddSegmentsRequest,
      O: AddSegmentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_group.SegmentGroupService.RemoveSegments
     */
    removeSegments: {
      name: "RemoveSegments",
      I: RemoveSegmentsRequest,
      O: RemoveSegmentsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_group.SegmentGroupService.UpdateSegmentsSequence
     */
    updateSegmentsSequence: {
      name: "UpdateSegmentsSequence",
      I: UpdateSegmentsSequenceRequest,
      O: UpdateSegmentsSequenceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc segment_group.SegmentGroupService.UpdateCanvasState
     */
    updateCanvasState: {
      name: "UpdateCanvasState",
      I: UpdateCanvasStateRequest,
      O: UpdateCanvasStateResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

