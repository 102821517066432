import { closestCenter, DndContext, DragEndEvent, DragStartEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { ListItemButton, ListItemText } from '@mui/material'
import useLocalStorage, { LocalStorageKey } from 'hooks/useLocalStrage'
import { MouseEventHandler, useMemo, useState } from 'react'
import { DraggableItem } from './sidebarItems'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

type Props = {
  draggableItems: DraggableItem[]
  localStorageKey: LocalStorageKey
}

export const SidebarDraggableItems = ({ draggableItems, localStorageKey }: Props) => {
  const [draggableItemIDSequence, setDraggableItemIDSequence] = useLocalStorage<string[]>(localStorageKey, [])
  const [draggingItemId, setDraggingItemId] = useState<string | undefined>(undefined)
  const [hoveringItemId, setHoveringItemId] = useState<string | undefined>(undefined)

  // Get the draggableItems sorted in order of the draggableItemIDSequence in useMemo at rendering time.
  const sortedDraggableItems = useMemo(() => {
    const idToIndexMap: Record<string, number> = draggableItemIDSequence.reduce((acc, id, index) => {
      acc[id] = index
      return acc
    }, {} as Record<string, number>)

    const notInSequence = draggableItems.filter((group) => !(group.id in idToIndexMap))
    const inSequence = draggableItems
      .filter((group) => group.id in idToIndexMap)
      .sort((a: DraggableItem, b: DraggableItem) => idToIndexMap[a.id] - idToIndexMap[b.id])

    return [...notInSequence, ...inSequence]
  }, [draggableItems, draggableItemIDSequence])

  const dndSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5, // Dragging more than 5px will initiate the drag operation.
      },
    })
  )

  const handleDragStart = (event: DragStartEvent) => {
    const activeId = event.active.id as string
    if (activeId !== undefined) {
      setDraggingItemId(activeId)
    }
  }
  const handleDragEnd = (event: DragEndEvent) => {
    setDraggingItemId(undefined)
    setHoveringItemId(undefined)

    const { active, over } = event
    if (over && active.id !== over.id) {
      const newSortedDraggableItems = arrayMove(
        sortedDraggableItems,
        sortedDraggableItems.findIndex((sortedDraggableItem) => sortedDraggableItem.id === active.id),
        sortedDraggableItems.findIndex((sortedDraggableItem) => sortedDraggableItem.id === over.id)
      )
      setDraggableItemIDSequence(newSortedDraggableItems.map((draggableItem) => draggableItem.id))
    }
  }

  return (
    <DndContext
      sensors={dndSensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={sortedDraggableItems.map((sortedDraggableItem) => sortedDraggableItem.id)} strategy={verticalListSortingStrategy}>
        {sortedDraggableItems.map((sortedDraggableItem) => (
          <SortableItem
            key={sortedDraggableItem.id}
            id={sortedDraggableItem.id}
            title={sortedDraggableItem.title}
            selected={sortedDraggableItem.selected}
            onClick={sortedDraggableItem.onClick}
            showIcon={(hoveringItemId === sortedDraggableItem.id && draggingItemId === undefined) || draggingItemId === sortedDraggableItem.id}
            onEnter={() => setHoveringItemId(sortedDraggableItem.id)}
            onLeave={() => setHoveringItemId(undefined)}
          />
        ))}
      </SortableContext>
    </DndContext>
  )
}

type SortableItemProps = {
  id: string
  title: string
  selected: boolean
  onClick: MouseEventHandler<HTMLDivElement> | undefined
  showIcon: boolean
  onEnter: () => void
  onLeave: () => void
}

const SortableItem = ({ id, title, selected, onClick, showIcon, onEnter, onLeave }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const dragAnimationStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    // Use z-index so that the cursor style of the item being dragged always takes precedence over the cursor style of the item being dragged,
    // since the cursor css will flicker when it intersects other items during dragging.
    zIndex: showIcon ? 1 : 0,
  }

  return (
    <ListItemButton
      ref={setNodeRef}
      style={dragAnimationStyle}
      {...attributes}
      {...listeners}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      selected={selected}
      onClick={onClick}
      sx={{
        padding: '2px 12px',
        marginBottom: '2px',
        borderRadius: '2px',
      }}
    >
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          paddingLeft: '3px',
          fontSize: '14px',
          noWrap: true,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      />
      {showIcon && <DragIndicatorIcon fontSize='small' sx={{ color: (theme) => theme.palette.text.secondary, cursor: 'grab' }} />}
    </ListItemButton>
  )
}
