import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, useTheme } from '@mui/material'
import { guideMap } from 'config/guide'
import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
}

export const PastDataReproductionDialog: FC<Props> = ({ open, handleClose }) => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { dispatch } = useActionTracker()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle display='flex' alignItems='center'>
        {t('features.customerSegments.detail.pastDataReproductionDialog.title')}
        <Chip size='small' label={t('common.beta.title')} variant='outlined' sx={{ marginLeft: '8px' }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText fontSize='14px'>
          {t('features.customerSegments.detail.pastDataReproductionDialog.description1')}
          <Link
            onClick={() => {
              const guideType = 'CustomerSegmentPastDataReproduction'
              const guideURL = guideMap[i18n.language][guideType]
              dispatch('ViewGuide', { guideType: guideType, url: guideURL })
              window.open(guideURL, '_blank')
            }}
            sx={{ cursor: 'pointer' }}
          >
            {t('features.customerSegments.detail.pastDataReproductionDialog.urlText')}
          </Link>
          {t('features.customerSegments.detail.pastDataReproductionDialog.description2')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose} sx={{ color: theme.palette.text.secondary }}>
          {t('features.customerSegments.detail.pastDataReproductionDialog.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
