import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type Props = {
  queryItemValuePath: `query_subsets.${number}.query_items.${number}.value`
  options: string[]
}

export const AutoCompleteValue: FC<Props> = ({ queryItemValuePath, options }) => {
  const { t } = useTranslation()
  const { setValue, control, watch } = useFormContext<QuerySetFormState>()

  return (
    <Controller
      control={control}
      name={queryItemValuePath}
      rules={{
        validate: () => {
          const v = watch(queryItemValuePath) as string[]
          if (v.length === 0) return t('features.customerSegments.querySet.querySetForm.messageValidationAutoCompleteEmpty')
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete<string, true>
            key={queryItemValuePath}
            size='small'
            sx={{ minWidth: '540px' }}
            multiple
            disableCloseOnSelect
            limitTags={5}
            value={field.value as string[]}
            defaultValue={field.value as string[]}
            onChange={(_, v) => {
              setValue(field.name, v)
            }}
            options={options}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox size='small' style={{ marginRight: '12px' }} checked={selected} />
                {option}
              </li>
            )}
            renderInput={(params) => <TextField {...params} value={field.value} error={Boolean(error)} helperText={error?.message} size='small' />}
          />
        )
      }}
    />
  )
}
