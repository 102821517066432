import { auth } from 'config/firebaseClient'
import { onAuthStateChanged, User } from 'firebase/auth'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

const authState = atom<User | undefined>({
  key: 'authState',
  default: undefined,
  dangerouslyAllowMutability: true,
})

export const useSubscribeAuth = (): { loading: boolean } => {
  const [isLoading, setIsLoading] = useState(true)
  const setUser = useSetRecoilState(authState)
  const { notifySentry } = useSentryNotifier()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          setUser(user)
        } else {
          setUser(undefined)
        }

        setIsLoading(false)
      },
      (err) => {
        setUser(undefined)
        setIsLoading(false)
        notifySentry(err)
      }
    )
    return unsubscribe
  }, [notifySentry, setUser])

  return { loading: isLoading }
}

export const useAuthUser = (): User | undefined => {
  return useRecoilValue(authState)
}
