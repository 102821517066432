import { FirebaseOptions, getApp, getApps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { env } from './env'

const firebaseConfig: FirebaseOptions = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
}

// Create an instance only when a Firebase instance does not exist
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp()

export const firestore = getFirestore(firebaseApp)

export const auth = getAuth(firebaseApp)

const bucketName = 'ecpower_us'
export const storage = getStorage(firebaseApp, `gs://${bucketName}`)
