import { Grid, Paper, Skeleton, Typography } from '@mui/material'
import { Shop } from 'gen/firestore'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  shop: Shop | undefined
}

export const Workspace: FC<Props> = ({ shop }) => {
  const { t } = useTranslation()

  return (
    <>
      <Paper sx={{ padding: '32px', marginBottom: '24px', borderRadius: '12px' }} variant='outlined'>
        <Typography fontWeight='bold' marginBottom='16px'>
          {t('features.settings.workspace.general')}
        </Typography>

        <Grid container spacing={1} paddingLeft='4px'>
          <Grid fontSize='14px' item xs={1.5}>
            {t('features.settings.workspace.name')}
          </Grid>
          <Grid fontSize='14px' item xs={10.5}>
            {shop ? shop.displayName : <Skeleton width={'120px'} />}
          </Grid>

          <Grid fontSize='14px' item xs={1.5}>
            {t('features.settings.workspace.shopName')}
          </Grid>
          <Grid fontSize='14px' item xs={10.5}>
            {shop ? shop.shopName : <Skeleton width={'120px'} />}
          </Grid>

          <Grid fontSize='14px' item xs={1.5}>
            {t('features.settings.workspace.currency')}
          </Grid>
          <Grid fontSize='14px' item xs={10.5}>
            {shop ? shop.currency : <Skeleton width={'120px'} />}
          </Grid>

          <Grid fontSize='14px' item xs={1.5}>
            {t('features.settings.workspace.timezone')}
          </Grid>
          <Grid fontSize='14px' item xs={10.5}>
            {shop ? shop.timezone : <Skeleton width={'120px'} />}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
