import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { PageHeader } from 'components/pageHeader'
import { useAccount } from 'hooks/useAccount'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { Members } from './members/members'
import { MyAccount } from './myAccount/myAccount'
import { Plan } from './plan/plan'
import { Workspace } from './workspace/workspace'

enum TabIndex {
  workspace = 'workspace',
  account = 'account',
  members = 'members',
  plan = 'plan',
}

export const Settings = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { account, shop } = useAccount()

  const getTabIndex = (pathname: string) => {
    if (pathname === AUTHORIZED_ROUTE.SETTINGS_ACCOUNT) return TabIndex.account
    if (pathname === AUTHORIZED_ROUTE.SETTINGS_MEMBERS) return TabIndex.members
    if (pathname === AUTHORIZED_ROUTE.SETTINGS_PLAN) return TabIndex.plan
    return TabIndex.workspace
  }

  const [tabIndex, setTabIndex] = useState<TabIndex>(getTabIndex(location.pathname))

  useEffect(() => {
    setTabIndex(getTabIndex(location.pathname))
  }, [location])

  return (
    <>
      <PageHeader title={t('features.settings.title')} marginBottom='16px' />
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(_, v) => setTabIndex(v)}>
            <Tab
              label={t('features.settings.tabIndexWorkspace')}
              component={Link}
              to={AUTHORIZED_ROUTE.SETTINGS_WORKSPACE}
              value={TabIndex.workspace}
            />
            <Tab label={t('features.settings.tabIndexAccount')} component={Link} to={AUTHORIZED_ROUTE.SETTINGS_ACCOUNT} value={TabIndex.account} />
            <Tab label={t('features.settings.tabIndexMembers')} component={Link} to={AUTHORIZED_ROUTE.SETTINGS_MEMBERS} value={TabIndex.members} />
            <Tab label={t('features.settings.tabIndexPlan')} component={Link} to={AUTHORIZED_ROUTE.SETTINGS_PLAN} value={TabIndex.plan} />
          </TabList>
        </Box>
        <TabPanel value={TabIndex.workspace} sx={{ padding: '24px 12px' }}>
          <Workspace shop={shop} />
        </TabPanel>
        <TabPanel value={TabIndex.account} sx={{ padding: '24px 12px' }}>
          <MyAccount account={account} />
        </TabPanel>
        <TabPanel value={TabIndex.members} sx={{ padding: '24px 12px' }}>
          <Members account={account} />
        </TabPanel>
        <TabPanel value={TabIndex.plan} sx={{ padding: '24px 12px' }}>
          <Plan shop={shop} />
        </TabPanel>
      </TabContext>
    </>
  )
}
