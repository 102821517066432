import { Chip } from '@mui/material'
import { FC } from 'react'

type Props = {
  label: string
}

export const OperatorChip: FC<Props> = ({ label }) => {
  return <Chip label={label} variant='filled' size='small' sx={{ borderRadius: '6px', marginRight: '6px', lineHeight: '1.2' }} />
}
