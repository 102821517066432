import { Box, FormControl, MenuItem, Select } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_DATE_RANGE, OP_NONE, OP_RELATIVE_DATE_RANGE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { DateRangeValue } from '../value/dateRangeValue'
import { RelativeDateRangeValue } from '../value/relativeDateRangeValue'
import { FilterItemProps } from './type'

export const OrderDateFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t, i18n } = useTranslation()
  const { register, watch, control } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  return (
    <Box display='flex'>
      <Box fontSize='14px' marginRight={i18n.language === 'en' ? '19px' : '12px'} paddingTop='10px'>
        {t('features.customerSegments.querySet.filterType', { context: 'order_date' })}
      </Box>
      <input type='hidden' value='order_date' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormControl sx={{ minWidth: '120px', marginRight: '12px' }}>
              <Select defaultValue={OP_NONE} error={Boolean(error)} value={field.value} onChange={field.onChange} size='small'>
                <MenuItem key={OP_NONE} value={OP_NONE}>
                  {t('features.customerSegments.querySet.querySetForm.op_order_date', { context: OP_NONE })}
                </MenuItem>
                <MenuItem key={OP_DATE_RANGE} value={OP_DATE_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_DATE_RANGE })}
                </MenuItem>
                <MenuItem key={OP_RELATIVE_DATE_RANGE} value={OP_RELATIVE_DATE_RANGE}>
                  {t('features.customerSegments.querySet.querySetForm.op', { context: OP_RELATIVE_DATE_RANGE })}
                </MenuItem>
              </Select>
            </FormControl>
          )
        }}
      />

      {operator === OP_DATE_RANGE && (
        <DateRangeValue minDateValuePath={`${filterPath}.value.min_date`} maxDateValuePath={`${filterPath}.value.max_date`} />
      )}

      {operator === OP_RELATIVE_DATE_RANGE && (
        <RelativeDateRangeValue
          minValuePath={`${filterPath}.value.min`}
          maxValuePath={`${filterPath}.value.max`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixDateRange')}
          inputPropsMaxValue={999}
        />
      )}
    </Box>
  )
}
