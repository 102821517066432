import { FormControl, InputAdornment, TextField } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { useTranslation } from 'react-i18next'

type Props = {
  queryItemValuePath: `query_subsets.${number}.query_items.${number}.value`
  inputAdornmentString: string
  inputPropsMaxValue: number
}

export const IntValue: FC<Props> = ({ queryItemValuePath, inputAdornmentString, inputPropsMaxValue }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()

  return (
    <Controller
      control={control}
      name={queryItemValuePath}
      rules={{
        validate: (v) => {
          if (v == null) {
            // (==) null or undefined
            return t('features.customerSegments.querySet.querySetForm.messageValidationMustNumber')
          }
          if (typeof v === 'number' && (v < 1 || v > inputPropsMaxValue)) {
            return t('features.customerSegments.querySet.querySetForm.messageValidationNumberRange', { min: 1, max: inputPropsMaxValue })
          }
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <FormControl sx={{ minWidth: '120px', marginRight: '6px' }}>
            <TextField
              type='number'
              value={field.value ?? ''}
              onChange={(e) => {
                field.onChange(e.target.value !== '' ? Number(e.target.value) : null)
              }}
              error={Boolean(error)}
              helperText={error?.message}
              size='small'
              InputProps={{
                endAdornment: <InputAdornment position='end'>{inputAdornmentString}</InputAdornment>,
                inputProps: {
                  min: 1,
                  max: inputPropsMaxValue,
                },
              }}
            />
          </FormControl>
        )
      }}
    />
  )
}
