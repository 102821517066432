import { useMemo } from 'react'
import { createPromiseClient, createConnectTransport, PromiseClient, Interceptor } from '@bufbuild/connect-web'
import { ServiceType } from '@bufbuild/protobuf'
import { env } from 'config/env'

// DEPRECATED
const sampleInterceptor: Interceptor = (next) => async (req) => {
  // TODO: implement
  return await next(req)
}

const transport = createConnectTransport({
  credentials: 'omit',
  baseUrl: env.ECPOWER_API_URL,
  interceptors: [sampleInterceptor],
})

// Get a promise client for the given service.
export const useGrpcClient = <T extends ServiceType>(service: T): PromiseClient<T> => {
  return useMemo(() => createPromiseClient(service, transport), [service])
}
