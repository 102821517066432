// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/shop/shop.proto (package shop, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetAccountsRequest, GetAccountsResponse, InviteAccountRequest, InviteAccountResponse, RemoveAccountRequest, RemoveAccountResponse, SubmitSurveyAnswersRequest, SubmitSurveyAnswersResponse} from "./shop_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service shop.ShopService
 */
export const ShopService = {
  typeName: "shop.ShopService",
  methods: {
    /**
     * @generated from rpc shop.ShopService.GetAccounts
     */
    getAccounts: {
      name: "GetAccounts",
      I: GetAccountsRequest,
      O: GetAccountsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shop.ShopService.InviteAccount
     */
    inviteAccount: {
      name: "InviteAccount",
      I: InviteAccountRequest,
      O: InviteAccountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shop.ShopService.RemoveAccount
     */
    removeAccount: {
      name: "RemoveAccount",
      I: RemoveAccountRequest,
      O: RemoveAccountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc shop.ShopService.SubmitSurveyAnswers
     */
    submitSurveyAnswers: {
      name: "SubmitSurveyAnswers",
      I: SubmitSurveyAnswersRequest,
      O: SubmitSurveyAnswersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

