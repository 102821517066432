import dayjs from 'dayjs'
import { EstimateNthOrderCohortResponse, EstimateNthOrderCohortResponse_StepAndMetrics } from 'gen/proto/customer_segment/customer_segment_pb'
import { formatYearMonth } from 'utils/timeUtil'

export type NthOrderCohortSummaryType = {
  nthResult: NthResultType[]
  avgResult: NthOrderStepAndMetricsType[]
}

type NthResultType = {
  firstOrderYearMonth: string
  stepAndMetricsList: NthOrderStepAndMetricsType[]
}

type NthOrderStepAndMetricsType = {
  step: number
  customerCount: number
  avgOrderValue: number
  repeatRate: number
  avgDaysBetweenOrders: number
}

export const convertNthOrderCohortSummary = (nthOrderCohortSummary: EstimateNthOrderCohortResponse, lang: string): NthOrderCohortSummaryType => {
  return {
    nthResult: nthOrderCohortSummary.nthResult.map((nr) => {
      return {
        firstOrderYearMonth: formatYearMonth(dayjs(nr.firstOrderYearMonth), lang),
        stepAndMetricsList: convertNthOrderStepAndMetricsList(nr.stepAndMetricsList),
      }
    }),
    avgResult: convertNthOrderStepAndMetricsList(nthOrderCohortSummary.avgResult),
  }
}

const convertNthOrderStepAndMetricsList = (smList: EstimateNthOrderCohortResponse_StepAndMetrics[]): NthOrderStepAndMetricsType[] => {
  return smList.map((sm) => {
    return {
      step: Number(sm.step),
      customerCount: Number(sm.customerCount),
      avgOrderValue: Number(sm.avgOrderValue),
      repeatRate: sm.repeatRate,
      avgDaysBetweenOrders: sm.avgDaysBetweenOrders,
    }
  })
}
