import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_SPECIFY } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useAcqRepeatSegments = (utmSources: string[], referrers: string[], landingPages: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_utmSource_sequenceRepeat', { value: utmSources[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: [utmSources[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_utmSource_sequenceRepeat', { value: utmSources[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'utm_source',
          operator: OP_CONTAIN_ANY,
          value: [utmSources[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_referrer_sequenceRepeat', { value: referrers[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'referrer',
          operator: OP_CONTAIN_ANY,
          value: [referrers[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_referrer_sequenceRepeat', { value: referrers[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'referrer',
          operator: OP_CONTAIN_ANY,
          value: [referrers[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_landingPage_sequenceRepeat', { value: landingPages[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'landing_page',
          operator: OP_CONTAIN_ANY,
          value: [landingPages[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_landingPage_sequenceRepeat', { value: landingPages[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'landing_page',
          operator: OP_CONTAIN_ANY,
          value: [landingPages[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
  ]
}
