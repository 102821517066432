import { AppBar, Box, ToggleButton, ToggleButtonGroup, Toolbar } from '@mui/material'
import { Language } from 'gen/firestore'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

const appBarHeight = '64px'
const logoImgPath = process.env.PUBLIC_URL + '/logo.png'

export const PublicLayout = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState<Language>(i18n.language as Language)

  const handleToggleLanguage = (value: string) => {
    const lang = value as Language
    setLanguage(lang)
    i18n.changeLanguage(lang)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <AppBar
          position='absolute'
          sx={{
            height: appBarHeight,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            boxShadow: 'none',
            backgroundColor: 'white',
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <img
              src={logoImgPath}
              alt='ecpower-logo'
              style={{
                height: 'auto',
                maxWidth: '128px',
              }}
            />

            <ToggleButtonGroup value={language} exclusive size='small' onChange={(_, v) => handleToggleLanguage(v)}>
              <ToggleButton value='en'>EN</ToggleButton>
              <ToggleButton value='ja'>JA</ToggleButton>
            </ToggleButtonGroup>
          </Toolbar>
        </AppBar>

        <Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
