import { OP_INT_RANGE, OP_RELATIVE_DATE_RANGE } from 'features/customerSegments/querySetForm/schema/const'
import { RecipeSegment } from 'features/recipes/types/type'
import { getIdToken } from 'firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_connectweb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'

export const useLoyaltySegments = (): RecipeSegment[] => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { notifySentry } = useSentryNotifier()
  const service = useGrpcClient(CustomerSegmentService)

  const [loading, setLoading] = useState(false)
  const [aov, setAov] = useState(100) // default: random value

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const token = await getIdToken(authUser!)
        const resp = await service.estimateCustomerMetrics({ querySet: '' }, { headers: { Authorization: `Bearer ${token}` } })
        if (resp.result) {
          setAov(Math.floor(resp.result.avgOrderValue))
        }
      } catch (err) {
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [
    {
      name: t('features.recipes.segments.name_loyalty_vip'),
      querySet: generateQuerySet([
        {
          dimension: 'monetary',
          operator: OP_INT_RANGE,
          value: { min: aov * 5 },
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { max: 365 } }],
        },
      ]),
      loading: loading,
    },
    {
      name: t('features.recipes.segments.name_loyalty_gold'),
      querySet: generateQuerySet([
        {
          dimension: 'monetary',
          operator: OP_INT_RANGE,
          value: { min: aov * 2, max: aov * 5 },
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { max: 365 } }],
        },
      ]),
      loading: loading,
    },
    {
      name: t('features.recipes.segments.name_loyalty_silver'),
      querySet: generateQuerySet([
        {
          dimension: 'monetary',
          operator: OP_INT_RANGE,
          value: { min: aov * 1, max: aov * 2 },
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { max: 365 } }],
        },
      ]),
      loading: loading,
    },
    {
      name: t('features.recipes.segments.name_loyalty_bronze'),
      querySet: generateQuerySet([
        {
          dimension: 'monetary',
          operator: OP_INT_RANGE,
          value: { max: aov * 1 },
          filters: [{ filter_type: 'order_date', operator: OP_RELATIVE_DATE_RANGE, value: { max: 365 } }],
        },
      ]),
      loading: loading,
    },
  ]
}
