import { BillingPlanIdentifier, Shop } from 'gen/firestore'

type PlanConfig = {
  [key in BillingPlanIdentifier]: {
    price?: number
    minActiveCustomerCount: number
    maxActiveCustomerCount: number
  }
}

export const planConfig: PlanConfig = {
  free: {
    price: 0,
    minActiveCustomerCount: 0,
    maxActiveCustomerCount: Infinity,
  },
  starter: {
    price: 80,
    minActiveCustomerCount: 0,
    maxActiveCustomerCount: 5000,
  },
  growth: {
    price: 240,
    minActiveCustomerCount: 5001,
    maxActiveCustomerCount: 20000,
  },
  scale: {
    price: 400,
    minActiveCustomerCount: 20001,
    maxActiveCustomerCount: 100000,
  },
  enterprise: {
    minActiveCustomerCount: 100001,
    maxActiveCustomerCount: Infinity,
  },
}

export const currentPlan = (shop: Shop): BillingPlanIdentifier => {
  return shop.billingPlan.identifier
}

export const isFree = (shop: Shop): boolean => {
  return currentPlan(shop) === BillingPlanIdentifier.free
}

export const hasProAccess = (shop: Shop): boolean => {
  return !isFree(shop) || shop.isDemo || shop.isTrial
}

export const isPlanSelectable = (shop: Shop, plan: BillingPlanIdentifier): boolean => {
  if (shop.isDemo) return false
  if (plan === currentPlan(shop)) return false
  if (plan === BillingPlanIdentifier.free) return true

  const min = planConfig[plan].minActiveCustomerCount
  const max = planConfig[plan].maxActiveCustomerCount
  return min <= shop.activeCustomerCount && shop.activeCustomerCount <= max
}

export const isUpgradeRequired = (shop: Shop): boolean => {
  return shop.billingPlan.maxActiveCustomerCount < shop.activeCustomerCount
}
