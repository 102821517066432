import { zodResolver } from '@hookform/resolvers/zod'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import RestoreIcon from '@mui/icons-material/Restore'
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined'
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { UpgradeRecommendDialog } from 'components/upgradeRecommendDialog'
import { hasProAccess } from 'config/plan'
import dayjs from 'dayjs'
import { CustomerSegmentState, OrderState } from 'features/customerSegments/types/types'
import { useAccount } from 'hooks/useAccount'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'
import { z } from 'zod'
import { OrdersGraph } from './components/ordersGraph'
import { OrdersTable } from './components/ordersTable'
import { PastDataReproductionDialog } from './components/pastDataReprocutionDialog'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  orders: OrderState[] | undefined
  loading: boolean
}

enum ViewType {
  graph = 'graph',
  table = 'table',
}

enum FilterValue {
  last30Days = 'last30Days',
  last60Days = 'last60Days',
  last90Days = 'last90Days',
  custom = 'custom',
}

export const Orders: FC<Props> = ({ customerSegment, orders, loading }) => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()
  const { shop } = useAccount()

  const minDate = '2019-10-01' // temp
  const maxDate = formatDate(dayjs().subtract(1, 'day')) // yesterday
  const defaultStartDate = dayjs(minDate).isAfter(dayjs().subtract(30, 'day')) ? minDate : formatDate(dayjs().subtract(30, 'day'))
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(maxDate)

  const [viewType, setViewType] = useState<ViewType>(ViewType.graph)
  const [filterValue, setFilterValue] = useState(FilterValue.last30Days)
  const [totalAmount, setTotalAmount] = useState<number | undefined>()
  const [totalCount, setTotalCount] = useState<number | undefined>()
  const [upgradeRecommendDialogOpen, setUpgradeRecommendDialogOpen] = useState(false)
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false)

  const dateInput = z.object({
    startDate: z
      .string()
      .min(1)
      .refine((value) => dayjs(value).isAfter(dayjs(minDate).subtract(1, 'day')) && dayjs(value).isBefore(dayjs(maxDate).add(1, 'day'))),
    endDate: z
      .string()
      .min(1)
      .refine((value) => dayjs(value).isAfter(dayjs(minDate).subtract(1, 'day')) && dayjs(value).isBefore(dayjs(maxDate).add(1, 'day'))),
  })

  type InputSchema = z.infer<typeof dateInput>

  const { control, handleSubmit } = useForm<InputSchema>({
    resolver: zodResolver(dateInput),
    defaultValues: {
      startDate: startDate,
      endDate: endDate,
    },
  })

  const handleDateInput = async (input: InputSchema) => {
    const { startDate, endDate } = input
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleFilterValue = (v: FilterValue) => {
    if (hasProAccess(shop)) {
      setFilterValue(v)
      if (v === FilterValue.custom) return
      const subtractDays = v === FilterValue.last30Days ? 30 : v === FilterValue.last60Days ? 60 : v === FilterValue.last90Days ? 90 : 0
      const newStartDate = dayjs().subtract(subtractDays, 'day')
      setStartDate(dayjs(minDate).isAfter(newStartDate) ? minDate : formatDate(newStartDate))
      setEndDate(maxDate)
      return
    }
    setUpgradeRecommendDialogOpen(true)
    setFilterValue(FilterValue.last30Days)
  }

  const filteredOrders = useMemo(() => {
    if (!orders) return
    return orders.filter((order) => {
      const orderDate = dayjs(order.orderedAt)
      return orderDate.isAfter(dayjs(startDate)) && orderDate.isBefore(dayjs(endDate))
    })
  }, [orders, startDate, endDate])

  useEffect(() => {
    if (filteredOrders) {
      const total = filteredOrders.reduce(
        (acc, order) => {
          return {
            amount: acc.amount + order.orderAmount,
            count: acc.count + 1,
          }
        },
        { amount: 0, count: 0 }
      )
      setTotalAmount(total.amount)
      setTotalCount(total.count)
    }
  }, [filteredOrders])

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: filteredOrders?.map((o) => ({
      name: o.name,
      customerName: o.customerName,
      nthOrder: o.nthOrder,
      orderAmount: o.orderAmount,
      orderedAt: o.orderedAt,
      referrer: o.referrer,
      landingPage: o.landingPage,
      channel: o.channel,
      coupon: o.coupon,
      utmSource: o.utmSource,
      utmMedium: o.utmMedium,
      utmCampaign: o.utmCampaign,
      tags: o.tags.join(','),
    })),
    headers: [
      t('features.customerSegments.detail.orders.name'),
      t('features.customerSegments.detail.orders.customerName'),
      t('features.customerSegments.detail.orders.nthOrder'),
      t('features.customerSegments.detail.orders.orderAmount'),
      t('features.customerSegments.detail.orders.orderedAt'),
      t('features.customerSegments.detail.orders.referrer'),
      t('features.customerSegments.detail.orders.landingPage'),
      t('features.customerSegments.detail.orders.channel'),
      t('features.customerSegments.detail.orders.coupon'),
      t('features.customerSegments.detail.orders.utmSource'),
      t('features.customerSegments.detail.orders.utmMedium'),
      t('features.customerSegments.detail.orders.utmCampaign'),
      t('features.customerSegments.detail.orders.tags'),
    ],
    fileName: `${customerSegment?.name}_orders_${formatDate(dayjs(), i18n.language)}`,
    page: 'customerSegment_orders',
  })

  return (
    <>
      <Paper variant='outlined' sx={{ padding: '32px', borderRadius: '12px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='24px'>
          <Box>
            <Box display='flex'>
              <Typography fontWeight='bold' marginBottom='4px'>
                {t('features.customerSegments.detail.orders.title')}
              </Typography>
              <Tooltip title={t('common.beta.description')} placement='right-end'>
                <Chip size='small' label={t('common.beta.title')} variant='outlined' sx={{ marginLeft: '8px' }} />
              </Tooltip>
            </Box>
            <Box display='flex'>
              <Box display='flex' marginRight='16px'>
                <Typography fontSize='14px' color='text.secondary' marginRight='4px'>
                  {t('features.customerSegments.detail.orders.totalRevenue')}
                </Typography>
                <Box fontSize='14px' color='text.secondary'>
                  {!loading && totalAmount !== undefined ? formatCurrency(totalAmount) || <Skeleton width='40px' /> : <Skeleton width='40px' />}
                </Box>
              </Box>
              <Box display='flex'>
                <Typography fontSize='14px' color='text.secondary' marginRight='4px'>
                  {t('features.customerSegments.detail.orders.totalCount')}
                </Typography>
                <Box fontSize='14px' color='text.secondary'>
                  {!loading && totalCount !== undefined ? totalCount.toLocaleString() : <Skeleton width='40px' />}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display='flex'>
            {filterValue === FilterValue.custom && (
              <Box display='flex' alignItems='center' marginRight='16px'>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl sx={{ marginRight: '4px' }}>
                        <TextField {...field} type='date' size='small' error={Boolean(error)} />
                      </FormControl>
                    )
                  }}
                />

                <Typography fontSize='14px' color='text.secondary' marginRight='4px'>
                  -
                </Typography>

                <Controller
                  control={control}
                  name='endDate'
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl sx={{ marginRight: '8px' }}>
                        <TextField {...field} type='date' size='small' error={Boolean(error)} />
                      </FormControl>
                    )
                  }}
                />

                <Button type='submit' onClick={handleSubmit(handleDateInput)} variant='contained'>
                  {t('features.cohorts.cohortFilter.applyFilter')}
                </Button>
              </Box>
            )}

            <FormControl>
              <Select
                size='small'
                disabled={!orders}
                value={filterValue}
                onChange={(event) => {
                  const newValue = event.target.value as FilterValue
                  handleFilterValue(newValue)
                }}
                sx={{ marginRight: '12px' }}
              >
                {Object.values(FilterValue).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t('features.customerSegments.detail.orders.filter', { context: value })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ToggleButtonGroup
              value={viewType}
              size='small'
              exclusive
              onChange={(_, v) => {
                if (v === null) return
                setViewType(v)
              }}
              sx={{ marginRight: '8px' }}
            >
              <ToggleButton value={ViewType.graph}>
                <TimelineOutlinedIcon fontSize='small' />
              </ToggleButton>
              <ToggleButton value={ViewType.table}>
                <FormatListBulletedOutlinedIcon fontSize='small' />
              </ToggleButton>
            </ToggleButtonGroup>

            <Tooltip title={t('features.customerSegments.detail.orders.restore')} placement='top'>
              <span>
                <IconButton
                  color='inherit'
                  onClick={() => {
                    if (hasProAccess(shop)) {
                      setRestoreDialogOpen(true)
                      return
                    }
                    setUpgradeRecommendDialogOpen(true)
                  }}
                >
                  <RestoreIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={t('features.customerSegments.detail.orders.download')} placement='top'>
              <span>
                <IconButton
                  color='inherit'
                  disabled={loading || !filteredOrders || filteredOrders.length === 0 || downloadLoading}
                  onClick={() => {
                    if (hasProAccess(shop)) {
                      handleDownload()
                      return
                    }
                    setUpgradeRecommendDialogOpen(true)
                  }}
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>

        {viewType === ViewType.graph && <OrdersGraph loading={loading} orders={filteredOrders} startDate={startDate} endDate={endDate} />}
        {viewType === ViewType.table && <OrdersTable loading={loading} orders={filteredOrders} />}
      </Paper>

      {upgradeRecommendDialogOpen && (
        <UpgradeRecommendDialog
          open={upgradeRecommendDialogOpen}
          handleClose={() => setUpgradeRecommendDialogOpen(false)}
          referrer='customerSegmentOrders'
        />
      )}

      {restoreDialogOpen && <PastDataReproductionDialog open={restoreDialogOpen} handleClose={() => setRestoreDialogOpen(false)} />}
    </>
  )
}
