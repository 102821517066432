import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose: () => void
  onSelect: () => void
  isLoading: boolean
  customerSegments: CustomerSegmentState[] | undefined
  nodes: any[]
  selectedTarget: string[]
  setSelectedTarget: (value: string[]) => void
}

export const AddNodeDialog: FC<Props> = ({ open, onClose, onSelect, isLoading, customerSegments, nodes, selectedTarget, setSelectedTarget }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('features.customerSegments.group.canvasView.selectSegment')}</DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <Autocomplete
            loading={isLoading}
            disabled={!customerSegments}
            options={customerSegments?.filter((segment) => !nodes.some((node) => node.id === segment.id)) || []}
            getOptionLabel={(cl) => cl.name || ''}
            onChange={(e, value) => setSelectedTarget(value ? [value.id] : [])}
            value={customerSegments?.find((cl: any) => cl.id === selectedTarget) || null}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField {...params} label={t('features.customerSegments.group.addSegmentDialog.fieldCustomerSegments')} size='small' />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('features.customerSegments.group.canvasView.cancel')}</Button>
        <Button onClick={onSelect} disabled={selectedTarget.length === 0}>
          {t('features.customerSegments.group.canvasView.add')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
