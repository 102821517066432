import { RecipeSegment } from 'features/recipes/types/type'
import { useTranslation } from 'react-i18next'
import { generateQuerySet } from '../useRecipe'
import { OP_CONTAIN_ANY, OP_ORDER_SEQUENCE_FIRST, OP_ORDER_SEQUENCE_SPECIFY } from 'features/customerSegments/querySetForm/schema/const'

export const useOmnichannelSegments = (channels: string[]): RecipeSegment[] => {
  const { t } = useTranslation()

  return [
    {
      name: t('features.recipes.segments.name_channel', { value: channels[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [channels[0]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_channel', { value: channels[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [channels[1]],
          filters: [],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_channel_sequenceFirst', { value: channels[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [channels[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_channel_sequenceFirst', { value: channels[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [channels[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_FIRST }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_channel_sequenceRepeat', { value: channels[0] }),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [channels[0]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
    {
      name: t('features.recipes.segments.name_channel_sequenceRepeat', { value: channels[1] }),
      querySet: generateQuerySet([
        {
          dimension: 'channel',
          operator: OP_CONTAIN_ANY,
          value: [channels[1]],
          filters: [{ filter_type: 'order_sequence', operator: OP_ORDER_SEQUENCE_SPECIFY, value: { min_sequence: 2 } }],
        },
      ]),
    },
  ]
}
