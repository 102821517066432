// @generated by protoc-gen-connect-web v0.2.1 with parameter "target=ts"
// @generated from file proto/insight/insight.proto (package insight, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {GetHistogramReportRequest, GetHistogramReportResponse, GetMonthlyReportRequest, GetMonthlyReportResponse, GetNthOrderReportRequest, GetNthOrderReportResponse, GetOrderCountReportRequest, GetOrderCountReportResponse, GetPopularityReportRequest, GetPopularityReportResponse} from "./insight_pb";
import {MethodKind} from "@bufbuild/protobuf";

/**
 * @generated from service insight.InsightService
 */
export const InsightService = {
  typeName: "insight.InsightService",
  methods: {
    /**
     * @generated from rpc insight.InsightService.GetPopularityReport
     */
    getPopularityReport: {
      name: "GetPopularityReport",
      I: GetPopularityReportRequest,
      O: GetPopularityReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc insight.InsightService.GetMonthlyReport
     */
    getMonthlyReport: {
      name: "GetMonthlyReport",
      I: GetMonthlyReportRequest,
      O: GetMonthlyReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc insight.InsightService.GetNthOrderReport
     */
    getNthOrderReport: {
      name: "GetNthOrderReport",
      I: GetNthOrderReportRequest,
      O: GetNthOrderReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc insight.InsightService.GetOrderCountReport
     */
    getOrderCountReport: {
      name: "GetOrderCountReport",
      I: GetOrderCountReportRequest,
      O: GetOrderCountReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc insight.InsightService.GetHistogramReport
     */
    getHistogramReport: {
      name: "GetHistogramReport",
      I: GetHistogramReportRequest,
      O: GetHistogramReportResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

