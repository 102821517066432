import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { auth } from 'config/firebaseClient'
import { sendPasswordResetEmail } from 'firebase/auth'
import { Account } from 'gen/firestore'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PUBLIC_ROUTE } from 'routing'

type Props = {
  open: boolean
  account: Account
  handleClose: () => void
}

export const ResetPasswordDialog: FC<Props> = ({ open, account, handleClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}${PUBLIC_ROUTE.LOGIN}`,
      }
      auth.languageCode = account.language
      await sendPasswordResetEmail(auth, account.email, actionCodeSettings)
      enqueueSnackbar(t('features.settings.myAccount.resetPasswordDialog.messageSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.settings.myAccount.resetPasswordDialog.messageError'), { severity: 'error' })
      notifySentry(err, { inputEmail: account.email })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ fontSize: '18px' }}>{t('features.settings.myAccount.resetPasswordDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '14px' }}>
          {t('features.settings.myAccount.resetPasswordDialog.description', { email: account.email })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} variant='text' color='inherit'>
          {t('features.settings.myAccount.resetPasswordDialog.cancel')}
        </Button>
        <LoadingButton type='submit' loading={loading} loadingPosition='center' onClick={handleSubmit} variant='contained'>
          {t('features.settings.myAccount.resetPasswordDialog.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
