import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
  handleSubmit: () => void
}

export const DeleteNodeDialog: FC<Props> = ({ open, handleClose, handleSubmit }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box padding='20px 20px 12px'>
        <Box marginBottom='20px'>
          <Typography>{t('features.customerSegments.group.deleteNodeDialog.title')}</Typography>
        </Box>
        <Box marginBottom='20px'>
          <Typography fontSize='14px' color='text.secondary'>
            {t('features.customerSegments.group.deleteNodeDialog.description')}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='flex-end'>
          <Button onClick={handleClose} variant='text' sx={{ color: 'text.secondary', marginRight: '4px' }}>
            {t('features.customerSegments.group.deleteNodeDialog.cancel')}
          </Button>
          <LoadingButton type='submit' sx={{ fontWeight: 'bold' }} loadingPosition='center' onClick={handleSubmit}>
            {t('features.customerSegments.group.deleteNodeDialog.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
